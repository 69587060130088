import React, { FC } from "react";

export interface LocationMarkerProps {
  lat: number;
  lng: number;
}
const LocationMarker: FC<LocationMarkerProps> = () => {
  return (
    <div className="text-primary-500 loction-home-marker">
      <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" className="h-11 w-11">
	      <path d="M8.602 1.147l.093.08 7.153 6.914-.696.718L14 7.745V14.5a.5.5 0 0 1-.41.492L13.5 15H10V9.5a.5.5 0 0 0-.41-.492L9.5 9h-3a.5.5 0 0 0-.492.41L6 9.5V15H2.5a.5.5 0 0 1-.492-.41L2 14.5V7.745L.847 8.86l-.696-.718 7.153-6.915a1 1 0 0 1 1.297-.08z"></path>
      </svg>
    </div>
  );
};
export default LocationMarker;
