import Label from "components/Label/Label";
import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import ProgressBar from 'react-customizable-progressbar'

const Transection = () => {
  const value = 0.66;
  const   progress  = 37; 
  return (
    <div>
      <CommonLayout>
      <div className="space-y-4 sm:space-y-4">
          {/* HEADING */} 
          <div className="card-title-account p-3 pl-6">
              <h2 className="text-3xl font-semibold pb-0 mb-0">Rewards Transactions</h2> 
           </div> 
          <div className="card-box-account">
            <div className="w-full">
              <div className="table-main mt-5">
                <table>
                <tr>
                <th>Date</th>
                <th>Category</th>
                <th>Type</th>
                <th>Tiny Away Points</th>
                <th>Balance Points</th>
                </tr>
                <tbody>
                  <tr>
                    <td>20 Aug 2023</td>
                    <td>Redemption</td>
                    <td>Booking (zxhdhyc)</td>
                    <td>-50</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>20 Aug 2023</td>
                    <td>Earning</td>
                    <td>Welcome Points</td>
                    <td>+50</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>20 Aug 2023</td>
                    <td>Earning</td>
                    <td>Write review</td>
                    <td>+50</td>
                    <td>50</td>
                  </tr>
                </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};
export default Transection;
