import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";

import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "../PageAbout/SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface PageOneTreePlantedProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter",
  },
  {
    title: "💌 EMAIL",
    desc: "nc.example@example.com",
  },
  {
    title: "☎ PHONE",
    desc: "000-123-456-7890",
  },
];
const PageOneTreePlanted: FC<PageOneTreePlantedProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About Us - Tiny Away | Tiny House | Tiny Homes | Weekend Getaways | Getaway Aus </title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-0 lg:py-0 space-y-0 lg:space-y-0 m-0">
        <div className="heroBenner">
      <img src="https://tinyaway.com/wp-content/uploads/2021/03/00018-TINYAWAY-SERENTY-ABODE-SJL-Photography-scaled.jpg"></img>
      <div className="mid-banner">
      <h2>ONE TREE PLANTED</h2>
      <p>tiny initiative</p>
      </div>
      </div>


      <div className="our-tiny  mt-200 mb-100">

          <h3>ONE TREE PLANTED</h3>
          <p>​This is our tiny initiative together with our guests to support global reforestation. Your every booking counts. Thank you for contributing to every single tree with Tiny Away.</p>
          <h4 className="mt-50">​With bookings on Tiny Away, we donate to plant trees somewhere in the world.</h4>
          
          
          
          <p>​
          This initiative is powered by our friends at <a href="https://onetreeplanted.org" target="_blank" rel="noopener noreferrer">One Tree Planted</a>, a non-profit organisation.
      </p>
          
          ​<p>We believe that all good things should be shared. To share this with everyone, we have integrated the concept of tiny houses with eco-tourism. Our tiny houses are designed to be eco-friendly and are built with sustainable materials. By putting our tiny houses on booking platforms like Airbnb and Booking.com, we have made it possible for anyone to book a stay in a tiny house. This gives people, especially those stressed out city folks, a chance to experience the tiny house lifestyle in Nature;</p>
         
         
<p>
One simple act. We plant trees. Together planting a forest. We hope our tiny contribution together with all our guests, can help our planet rejuvenate and have more nature for mankind to enjoy. Discover Nature. Stay in Comfort.
</p>

<p>From everyone at Tiny Away</p>
          
     </div>

     {/*<SectionFounder />*/}

        {/*<SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
  />*/}

        {/*<SectionFounder />*/}

        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic />

        <SectionSubscribe2 />*/}
      </div>
    </div>
  );
};

export default PageOneTreePlanted;
