import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";

import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "../PageAbout/SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface PageprivacyProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter",
  },
  {
    title: "💌 EMAIL",
    desc: "nc.example@example.com",
  },
  {
    title: "☎ PHONE",
    desc: "000-123-456-7890",
  },
];
const Pageprivacy: FC<PageprivacyProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className} bg-gray`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Privacy - Tiny Away | Tiny House | Tiny Homes | Weekend Getaways | Getaway Aus </title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
       

      <div className="container py-0 lg:py-0 space-y-0 lg:space-y-0 m-0 container-bg-max-width">
         

      <div className="text-left  text-innder-pages">
        <h4>Last Updated: September 14, 2018</h4>
          <p>Thank you for using Tiny Away!</p>
          <p>This Privacy Policy describes how we collect, use, process, and disclose your information, including personal information, in conjunction with your access to and use of the Tiny Away Platform and the Payment Services.</p>
          <p>If you see an undefined term in this Privacy Policy (such as “Listing” or “Tiny Away Platform”), it has the same definition as in our Terms of Service.</p>
          <p>When this policy mentions “Tiny Away,” “we,” “us,” or “our,” it refers to the Tiny Away company that is responsible for your information under this Privacy Policy (the “Data Controller”).</p>
          <p>This Privacy Policy also applies to the Payment Services provided to you by Tiny Away Payments. When using the Payment Services, you may be also providing your information, including personal information, to one or more Tiny Away Payments entities, which will also be the Data Controller (the “Payments Data Controller”) of your information related to the Payment Services, generally depending on your Country of Residence.</p>
          <p>If you change your Country of Residence, the Data Controller and/or Payments Data Controller will be determined by your new Country of Residence as specified above, from the date on which your Country of Residence changes. To this end the Data Controller and/or Payment Data Controller that originally collected your personal information will need to transfer such personal information to the new applicable Data Controller and/or Payments Data Controller due to the fact that such transfer is necessary for the performance of the contractual relationship with you.</p>
     <h4>1. INFORMATION WE COLLECT</h4>
     <p>There are three general categories of information we collect.</p>

     <p>1.1 Information You Give to Us.</p>

     <p>1.1.1 Information that is necessary for the use of the Tiny Away Platform.</p>

     <p>We ask for and collect the following personal information about you when you use the Tiny Away Platform. This information is necessary for the adequate performance of the contract between you and us and to allow us to comply with our legal obligations. Without it, we may not be able to provide you with all the requested services.</p>
     
     <ul>
      <li>Account Information. When you sign up for an Tiny Away Account, we require certain information such as your first name, last name, email address, and date of birth.</li>
      <li>Profile and Listing Information. To use certain features of the Tiny Away Platform (such as booking or creating a Listing), we may ask you to provide additional information, which may include your address, phone number, and a profile picture.</li>
      <li>Identity Verification Information. To help create and maintain a trusted environment, we may collect identity verification information (such as images of your government issued ID, passport, national ID card, or driving license, as permitted by applicable laws) or other authentication information.</li>
      <li>Payment Information. To use certain features of the Tiny Away Platform (such as booking or creating a Listing), we may require you to provide certain financial information (like your bank account or credit card information) in order to facilitate the processing of payments (via Tiny Away Payments).</li>
      <li>Communications with Tiny Away and other Members. When you communicate with Tiny Away or use the Tiny Away Platform to communicate with other Members, we collect information about your communication and any information you choose to provide.</li>
     </ul>
     <p>1.1.2 Information you choose to give us.</p>

     <p>You may choose to provide us with additional personal information in order to obtain a better user experience when using Tiny Away Platform. This additional information will be processed based on your consent.</p>
     
     <ul>
      <li>Additional Profile Information. You may choose to provide additional information as part of your Tiny Away profile (such as gender, preferred language(s), city, and a personal description). Some of this information as indicated in your Account settings is part of your public profile page, and will be publicly visible to others.</li>
      <li>Address Book Contact Information. You may choose to import your address book contacts or enter your contacts’ information manually to access certain features of the Tiny Away Platform, like inviting them to use Tiny Away.</li>
      <li>Other Information. You may otherwise choose to provide us information when you fill in a form, conduct a search, update or add information to your Tiny Away Account, respond to surveys, post to community forums, participate in promotions, or use other features of the Tiny Away Platform.</li>
     </ul>
     <p>1.1.3 Information that is necessary for the use of the Payment Services.</p>
     <p className="mb-0">The Payments Data Controller needs to collect the following information, as it is necessary for the adequate performance of the contract with you and to comply with applicable law (such as anti-money laundering regulations). Without it, you will not be able to use Payment Services:</p>
     <ul>
<li>Payment Information. When you use the Payment Services, the Payments Data Controller requires certain financial information (like your bank account or credit card information) in order to process payments and comply with applicable law.</li>
<li>Identity Verification and Other Information. If you are a Host, the Payments Data Controller may require identity verification information (such as images of your government issued ID, passport, national ID card, or driving license) or other authentication information, your date of birth, your address, email address, phone number and other information in order to verify your identity, provide the Payment Services to you, and to comply with applicable law.</li>
</ul>
 
			<p>1.2 Information We Automatically Collect from Your Use of the Tiny Away Platform and Payment Services.</p>
<p className="mb-0">When you use the Tiny Away Platform and the Payment Services, we automatically collect information, including personal information, about the services you use and how you use them. This information is necessary for the adequate performance of the contract between you and us, to enable us to comply with legal obligations and given our legitimate interest in being able to provide and improve the functionalities of the Tiny Away Platform and Payment Services.</p>
<ul>
<li>Geo-location Information. When you use certain features of the Tiny Away Platform, we may collect information about your precise or approximate location as determined through data such as your IP address or mobile device’s GPS to offer you an improved user experience. Most mobile devices allow you to control or disable the use of location services for applications in the device’s settings menu. Tiny Away may also collect this information even when you are not using the app if this connection is enabled through your settings or device permissions.</li>
<li>Usage Information. We collect information about your interactions with the Tiny Away Platform such as the pages or content you view, your searches for Listings, bookings you have made, and other actions on the Tiny Away Platform.</li>
<li>Log Data and Device Information. We automatically collect log data and device information when you access and use the Tiny Away Platform, even if you have not created an Tiny Away Account or logged in. That information includes, among other things: details about how you’ve used the Tiny Away Platform (including if you clicked on links to third party applications), IP address, access dates and times, hardware and software information, device information, device event information, unique identifiers, crash data, cookie data, and the pages you’ve viewed or engaged with before or after using the Tiny Away Platform.</li>
<li>Cookies and Similar Technologies. We use cookies and other similar technologies, such as web beacons, pixels, and mobile identifiers. We may also allow our business partners to use these tracking technologies on the Tiny Away Platform, or engage others to track your behavior on our behalf. While you may disable the usage of cookies through your browser settings, the Tiny Away Platform currently does not respond to a “Do Not Track” signal in the HTTP header from your browser or mobile application due to lack of standardization regarding how that signal should be interpreted.</li>
<li>Payment Transaction Information. Tiny Away Payments collects information related to your payment transactions through the Tiny Away Platform, including the payment instrument used, date and time, payment amount, payment instrument expiration date and billing postcode, PayPal email address, IBAN information, your address and other related transaction details. This information is necessary for the adequate performance of the contract between you and Tiny Away Payments and to allow the provision of the Payment Services.</li>
</ul>

 
			<p>1.3 Information We Collect from Third Parties.</p>
<p className="mb-0">Tiny Away and Tiny Away Payments may collect information, including personal information, that others provide about you when they use the Tiny Away Platform and the Payment Services, or obtain information from other sources and combine that with information we collect through the Tiny Away Platform and the Payment Services. We do not control, supervise or respond for how the third parties providing your information process your personal data, and any information request regarding the disclosure of your personal information to us should be directed to such third parties.</p>
<ul>
<li>Third Party Services. If you link, connect, or login to your Tiny Away Account with a third party service (e.g. Google, Facebook, WeChat), the third party service may send us information such as your registration and profile information from that service. This information varies and is controlled by that service or as authorized by you via your privacy settings at that service.</li>
<li>Your References. If someone has written a reference for you, it will be published on your Tiny Away public profile page with your consent.</li>
<li>Background Information. For Members in the United States, to the extent permitted by applicable laws, Tiny Away and Tiny Away Payments may obtain reports from public records of criminal convictions or sex offender registrations. For Members outside of the United States, to the extent permitted by applicable laws and with your consent where required, Tiny Away and Tiny Away Payments may obtain the local version of police, background or registered sex offender checks. We may use your information, including your full name and date of birth, to obtain such reports.</li>
<li>Other Sources. To the extent permitted by applicable law, we may receive additional information about you, such as demographic data or fraud detection information, from third party service providers and/or partners, and combine it with information we have about you. For example, we may receive background check results (with your consent where required) or fraud warnings from service providers like identity verification services for our fraud prevention and risk assessment efforts. We may receive information about you and your activities on and off the Tiny Away Platform through partnerships, or about your experiences and interactions from our partner ad networks.</li>
</ul>
 <h4>2. HOW WE USE INFORMATION WE COLLECT</h4>

<p>&ZeroWidthSpace;We use, store, and process information, including personal information, about you to provide, understand, improve, and develop the Tiny Away Platform, create and maintain a trusted and safer environment and comply with our legal obligations.</p>
<p className="mb-0">2.1 Provide, Improve, and Develop the Tiny Away Platform.</p>
<ul>
<li>Enable you to access and use the Tiny Away Platform.</li>
<li>Enable you to communicate with other Members.</li>
<li>Operate, protect, improve, and optimize the Tiny Away Platform and experience, such as by performing analytics and conducting research.</li>
<li>Provide customer service.</li>
<li>Send you service or support messages, updates, security alerts, and account notifications.</li>
<li>If you provide us with your contacts’ information, we may process this information: (i) to facilitate your referral invitations, (ii) send your requests for references, (iii) for fraud detection and prevention, and (iv) for any purpose you authorize at the time of collection.</li>
<li>To operate, protect, improve, and optimize the Tiny Away Platform and experience, and personalize and customize your experience (such as making Listing suggestions or ranking search results), we conduct profiling based on your interactions with the Tiny Away Platform, your search and booking history, your profile information and preferences, and other content you submit to the Tiny Away Platform.</li>
</ul>

 
			<p>We process this information given our legitimate interest in improving the Tiny Away Platform and our Members’ experience with it, and where it is necessary for the adequate performance of the contract with you.</p>
<p className="mb-0">2.2 Create and Maintain a Trusted and Safer Environment.</p>
<ul>
<li>Detect and prevent fraud, spam, abuse, security incidents, and other harmful activity.</li>
<li>Conduct security investigations and risk assessments.</li>
<li>Verify or authenticate information or identifications provided by you (such as to verify your Accommodation address or compare your identification photo to another photo you provide).</li>
<li>Conduct checks against databases and other information sources, including background or police checks, to the extent permitted by applicable laws and with your consent where required.</li>
<li>Comply with our legal obligations.</li>
<li>Resolve any disputes with any of our Members and enforce our agreements with third parties.</li>
<li>Enforce our Terms of Service and other policies.</li>
<li>In connection with the activities above, we may conduct profiling based on your interactions with the Tiny Away Platform, your profile information and other content you submit to the Tiny Away Platform, and information obtained from third parties. In limited cases, automated processes may restrict or suspend access to the Tiny Away Platform if such processes detect a Member or activity that we think poses a safety or other risk to the Tiny Away Platform, other Members, or third parties.</li>
</ul>
 	 
 
			<p>We process this information given our legitimate interest in protecting the Tiny Away Platform, to measure the adequate performance of our contract with you, and to comply with applicable laws.</p>
<p className="mb-0">2.3 Provide, Personalize, Measure, and Improve our Advertising and Marketing.</p>
<ul>
<li>Send you promotional messages, marketing, advertising, and other information that may be of interest to you based on your preferences (including information about Tiny Away or partner campaigns and services) and social media advertising through social media platforms such as Facebook or Google).</li>
<li>Personalize, measure, and improve our advertising.</li>
<li>Administer referral programs, rewards, surveys, sweepstakes, contests, or other promotional activities or events sponsored or managed by Tiny Away or its third party partners.</li>
<li>Conduct profiling on your characteristics and preferences (based on the information you provide to us, your interactions with the Tiny Away Platform, information obtained from third parties, and your search and booking history) to send you promotional messages, marketing, advertising and other information that we think may be of interest to you.</li>
</ul>
  
			<p>We will process your personal information for the purposes listed in this section given our legitimate interest in undertaking marketing activities to offer you products or services that may be of your interest. You can opt-out of receiving marketing communications from us by following the unsubscribe instructions included in our marketing communications or changing your notification settings within your Tiny Away Account.</p>
<p className="mb-0">2.4 How the Payments Data Controller uses the Information Collected.</p>
<ul>
<li>Enable you to access and use the Payment Services.</li>
<li>Detect and prevent fraud, abuse, security incidents, and other harmful activity.</li>
<li>Conduct security investigations and risk assessments.</li>
<li>Conduct checks against databases and other information sources.</li>
<li>Comply with legal obligations (such as anti-money laundering regulations).</li>
<li>Enforce the Payment Terms and other payment policies.</li>
<li>With your consent, send you promotional messages, marketing, advertising, and other information that may be of interest to you based on your preferences.</li>
</ul>
<p>The Payments Data Controller processes this information given its legitimate interest in improving the Payment Services and its users’ experience with it, and where it is necessary for the adequate performance of the contract with you and to comply with applicable laws.</p>
 
 
			<h4>3. SHARING &amp; DISCLOSURE </h4>
<p>3.1 With Your Consent.</p>
<p>Where you have provided consent, we share your information, including personal information, as described at the time of consent, such as when you authorize a third party application or website to access your Tiny Away Account or when you participate in promotional activities conducted by Tiny Away partners or third parties.</p>
<p>3.2 Sharing between Members.</p>
<p className="mb-0">To help facilitate bookings or other interactions between Members, we may need to share certain information, including personal information, with other Members, as it is necessary for the adequate performance of the contract between you and us, as follows:</p>
<ul>
<li>When you as a Guest submit a booking request, certain information about you is shared with the Host (and Co-Host, if applicable), including your full name, the full name of any additional Guests, your cancellation history, and other information you agree to share. When your booking is confirmed, we will disclose additional information to assist with coordinating the trip, like your phone number.</li>
<li>When you as a Host (or Co-Host, if applicable) have a confirmed booking, certain information is shared with the Guest to coordinate the booking, such as your full name, phone number, and Accommodation or Experience address.</li>
<li>When you as a Host invite another Member to become a Co-Host, you authorize the Co-Host to access your information and Member Content, including but not limited to certain information like your full name, phone number, Accommodation address, and email address.</li>
<li>When you as a Guest invite additional Guests to a booking, your full name, travel dates, Host name, Listing details, the Accommodation address, and other related information will be shared with each additional Guest.</li>
<li>When you as a Guest initiate a Group Payment Booking Request certain information about each participant such as first name, last initial, profile picture as well as the booking details is shared among all participants of the Group Payment Booking Request.</li>
</ul>
 
 
			<p>We don’t share your billing and payout information with other Members.</p>
<p>3.3 Profiles, Listings, and other Public Information.</p>
<p className="mb-0">The Tiny Away Platform lets you publish information, including personal information, that is visible to the general public. For example:</p>
<ul>
<li>Parts of your public profile page, such as your first name, your description, and city, are publicly visible to others.</li>
<li>Listing pages are publicly visible and include information such as the Accommodation or Experience’s approximate location (neighborhood and city) or precise location (where you have provided your consent), Listing description, calendar availability, your public profile photo, aggregated demand information (like page views over a period of time), and any additional information you choose to share.</li>
<li>After completing a booking, Guests and Hosts may write Reviews and rate each other. Reviews and Ratings are a part of your public profile page and may also be surfaced elsewhere on the Tiny Away Platform (such as the Listing page).</li>
<li>If you submit content in a community or discussion forum, blog or social media post, or use a similar feature on the Tiny Away Platform, that content is publicly visible.</li>
</ul> 



 
			<p>Based on our legitimate interest to promote the Tiny Away Platform we may display parts of the Tiny Away Platform (e.g., your Listing page) on sites operated by Tiny Away’s business partners, using technologies such as widgets or APIs. If your Listings are displayed on a partner’s site, information from your public profile page may also be displayed.</p>
<p>Information you share publicly on the Tiny Away Platform may be indexed through third party search engines. In some cases, you may opt-out of this feature in your Account settings. If you change your settings or your public-facing content, these search engines may not update their databases. We do not control the practices of third party search engines, and they may use caches containing your outdated information.</p>
<p>3.4 Additional Services by Hosts.</p>
<p>Hosts may need to use third party services available through the Tiny Away Platform to assist with managing their Accommodation or providing additional services requested by you, such as cleaning services or lock providers. Hosts may use features on the Tiny Away Platform to share information about the Guest (like check-in and check-out dates, Guest name, Guest phone number) with such third party service providers for the purposes of coordinating the stay, managing the Accommodation, or providing other services.</p>
<p>3.5 Compliance with Law, Responding to Legal Requests, Preventing Harm and Protection of our Rights.</p>
<p>Tiny Away and Tiny Away Payments may disclose your information, including personal information, to courts, law enforcement or governmental authorities, or authorized third parties, if and to the extent we are required or permitted to do so by law or if such disclosure is reasonably necessary: (i) to comply with our legal obligations, (ii) to comply with legal process and to respond to claims asserted against Tiny Away, (iii) to respond to verified requests relating to a criminal investigation or alleged or suspected illegal activity or any other activity that may expose us, you, or any other of our users to legal liability, (iv) to enforce and administer our Terms of Service, the Payment Terms or other agreements with Members, or (v) to protect the rights, property or personal safety of Tiny Away, its employees, its Members, or members of the public.</p>
<p>These disclosures may be necessary to comply with our legal obligations, for the protection of your or another person’s vital interests or for the purposes of our or a third party’s legitimate interest in keeping the Tiny Away Platform secure, preventing harm or crime, enforcing or defending legal rights, or preventing damage.</p>
<p>Where appropriate, we may notify Members about legal requests unless: (i) providing notice is prohibited by the legal process itself, by court order we receive, or by applicable law, or (ii) we believe that providing notice would be futile, ineffective, create a risk of injury or bodily harm to an individual or group, or create or increase a risk of fraud upon Tiny Away’s property, its Members and the Tiny Away Platform. In instances where we comply with legal requests without notice for these reasons, we will attempt to notify that Member about the request after the fact where appropriate and where we determine in good faith that we are no longer prevented from doing so.</p>
<p>3.6 Service Providers.</p>
<p>Tiny Away and Tiny Away Payments uses a variety of third party service providers to help us provide services related to the Tiny Away Platform and the Payment Services. Service providers may be located inside or outside of the European Economic Area (“EEA”). In particular, our service providers are based in Europe, India, Asia Pacific and North and South America.</p>
<p>For example, service providers may help us: (i) verify your identity or authenticate your identification documents, (ii) check information against public databases, (iii) conduct background or police checks, fraud prevention, and risk assessment, (iv) perform product development, maintenance and debugging, (v) allow the provision of the Tiny Away Services through third party platforms and software tools (e.g. through the integration with our APIs), or (vi) provide customer service, advertising, or payments services. These providers have limited access to your information to perform these tasks on our behalf, and are contractually bound to protect and to use it only for the purposes for which it was disclosed and consistent with this Privacy Policy.</p>
<p>Tiny Away and the Tiny Away Payments will need to share your information, including personal information, in order to ensure the adequate performance of our contract with you.</p>
<p>3.7 Corporate Affiliates.</p>
<p>To enable or support us in providing the Tiny Away Platform and the Payment Services, we may share your information, including personal information, within our corporate family of companies (both financial and non-financial entities) that are related by common ownership or control.</p>
<p>Additionally, we share your information, including personal information, with our corporate affiliates in order to support and integrate, promote, and to improve the Tiny Away Platform and our affiliates’ services.</p>
<p>3.8 Social Media Platforms.</p>
<p>Where permissible according to applicable law we may use certain limited personal information about you, such as your email address, to hash it and to share it with social media platforms, such as Facebook or Google, to generate leads, drive traffic to our websites or otherwise promote our products and services or the Tiny Away Platform. These processing activities are based on our legitimate interest in undertaking marketing activities to offer you products or services that may be if your interest.</p>
<p>The social media platforms with which we may share your personal data are not controlled or supervised by Tiny Away. Therefore, any questions regarding how your social media platform service provider processes your personal data should be directed to such provider.</p>
<p>Please note that you may, at any time ask Tiny Away to cease processing your data for these direct marketing purposes by sending an e-mail to ask@tinyaway.com.</p>
<p>3.9 Collection and Remittance of Occupancy Taxes.</p>
<p>In jurisdictions where Tiny Away facilitates the Collection and Remittance of Occupancy Taxes as described in the “Taxes” section of the Terms of Service, Hosts and Guests, where legally permissible according to applicable law, expressly grant us permission, without further notice, to disclose Hosts’ and Guests’ data and other information relating to them or to their transactions, bookings, Accommodations and Occupancy Taxes to the relevant tax authority, including, but not limited to, the Host’s or Guest’s name, Listing addresses, transaction dates and amounts, tax identification number(s), the amount of taxes received (or due) by Hosts from Guests, and contact information.</p>
<p>&ZeroWidthSpace;3.10 Government Registration.</p>
<p>In jurisdictions where Tiny Away facilitates or requires a registration, notification, permit, or license application of a Host with a local governmental authority through the Tiny Away Platform in accordance with local law, we may share information of participating Hosts with the relevant authority, both during the application process and, if applicable, periodically thereafter, such as the Host’s full name and contact details, Accommodation address, tax identification number, Listing details, and number of nights booked.</p>
<p>3.11 Host Information Provided to Tiny Away for Work Customers.</p>
<p>&ZeroWidthSpace;If a booking is designated as being for business purposes and made by a Guest affiliated with a company or other organization enrolled in our Tiny Away for Work Program (either or both an “Enterprise”), we may disclose information related to the booking to the Enterprise, such as the name of the Host, the Accommodation address, pricing, Listing details, and other related information, to the extent necessary for the adequate performance of the services. At the request of the Enterprise or the Guest, we may also share this information with third parties engaged by the Enterprise to provide travel management, travel planning, financial reporting, personnel management, crisis management, or other services.</p>
<p>&ZeroWidthSpace;3.12 Guest Information Provided to Tiny Away for Work Customers.</p>
<p>&ZeroWidthSpace;If you have linked your Tiny Away Account with an Enterprise, and you are included on a booking designated as being for business purposes, we may disclose information related to the booking to your Enterprise to the extent necessary for the adequate performance of the services. Typically, this includes your name, dates of the booking, number of guests, pricing, Listing details, the Accommodation address, and other related information. At the request of you or your Enterprise, we may also disclose this information to service providers engaged by it, such as companies that provide travel management, financial reporting, personnel management, crisis management, or other services. In the event of a dispute, emergency, or similar situation involving a booking identified as being for business purposes, Tiny Away may also share additional relevant information it believes is necessary to safely and quickly address the situation.</p>
<p>3.13 Business Transfers.</p>
<p>&ZeroWidthSpace;If Tiny Away undertakes or is involved in any merger, acquisition, reorganization, sale of assets, bankruptcy, or insolvency event, then we may sell, transfer or share some or all of our assets, including your information in connection with such transaction or in contemplation of such transaction (e.g., due diligence). In this event, we will notify you before your personal information is transferred and becomes subject to a different privacy policy.</p>
<p>&ZeroWidthSpace;3.14 Aggregated Data.</p>
<p>We may also share aggregated information (information about our users that we combine together so that it no longer identifies or references an individual user) and other anonymized information for regulatory compliance, industry and market analysis, demographic profiling, marketing and advertising, and other business purposes.</p>
<h4>4. OTHER IMPORTANT INFORMATION</h4>
<p>4.1 Analyzing your Communications.</p>
<p>We may review, scan, or analyze your communications on the Tiny Away Platform for fraud prevention, risk assessment, regulatory compliance, investigation, product development, research, and customer support purposes. For example, as part of our fraud prevention efforts, we scan and analyze messages to mask contact information and references to other websites. In some cases, we may also scan, review, or analyze messages to debug, improve, and expand product offerings. We use automated methods where reasonably possible. However, occasionally we may need to manually review some communications, such as for fraud investigations and customer support, or to assess and improve the functionality of these automated tools. We will not review, scan, or analyze your communications to send third party marketing messages to you, and we will not sell reviews or analyses of these communications.</p>
<p>These activities are carried out based on Tiny Away’s legitimate interest in ensuring compliance with applicable laws and our Terms, preventing fraud, promoting safety, and improving and ensuring the adequate performance of our services.</p>
 
 
			<p>4.2 Linking Third Party Accounts.</p>
<p className="mb-0">You may link your Tiny Away Account with your account at a third party social networking service. Your contacts on these third party services are referred to as “Friends.” When you create this link:</p>
<ul>
<li>some of the information you provide to us from the linking of your accounts may be published on your Tiny Away Account profile;</li>
<li>your activities on the Tiny Away Platform may be displayed to your Friends on the Tiny Away Platform and/or that third party site;</li>
<li>a link to your public profile on that third party social networking service may be included in your Tiny Away public profile;</li>
<li>other Tiny Away users may be able to see any common Friends that you may have with them, or that you are a Friend of their Friend if applicable;</li>
<li>other Tiny Away users may be able to see any schools, hometowns or other groups you have in common with them as listed on your linked social networking service;</li>
<li>the information you provide to us from the linking of your accounts may be stored, processed and transmitted for fraud prevention and risk assessment purposes; and</li>
<li>the publication and display of information that you provide to Tiny Away through this linkage is subject to your settings and authorizations on the Tiny Away Platform and the third party site.</li>
</ul>
 
 
			<p>We only collect your information from linked third party accounts to the extent necessary to ensure the adequate performance of our contract with you, or to ensure that we comply with applicable laws, or with your consent.</p>
<p>4.3 Google Maps/Earth.</p>
<p>Parts of the Tiny Away Platform use Google Maps/Earth services, including the Google Maps API(s). Use of Google Maps/Earth is subject to Google Maps/Earth Additional Terms of Use and the Google Privacy Policy.</p>
<h4>5. THIRD PARTY PARTNERS &amp; INTEGRATIONS</h4>
<p>The Tiny Away Platform may contain links to third party websites or services, such as third party integrations, co-branded services, or third party-branded services (“Third Party Partners”). Tiny Away doesn’t own or control these Third Party Partners and when you interact with them, you may be providing information directly to the Third Party Partner, Tiny Away, or both. These Third Party Partners will have their own rules about the collection, use, and disclosure of information. We encourage you to review the privacy policies of the other websites you visit.</p>
<h4>6. YOUR RIGHTS</h4>
<p>You may exercise any of the rights described in this section before your applicable Tiny Away Data Controller and Payments Data Controller by sending an email to ask@tinyaway.com. Please note that we may ask you to verify your identity before taking further action on your request.</p>
<p>6.1 Managing Your Information.</p>
<p>You may access and update some of your information through your Account settings. If you have chosen to connect your Tiny Away Account to a third-party application, like Facebook or Google, you can change your settings and remove permission for the app by changing your Account settings. You are responsible for keeping your personal information up-to-date.</p>
<p>6.2 Rectification of Inaccurate or Incomplete Information.</p>
<p>You have the right to ask us to correct inaccurate or incomplete personal information concerning you (and which you cannot update yourself within your Tiny Away Account).</p>
<p>6.3 Data Access and Portability.</p>
<p>&ZeroWidthSpace;In some jurisdictions, applicable law may entitle you to request copies of your personal information held by us. You may also be entitled to request copies of personal information that you have provided to us in a structured, commonly used, and machine-readable format and/or request us to transmit this information to another service provider (where technically feasible).</p>
 
 
			<p>6.4 Data Retention and Erasure.</p>
<p className="mb-0">We generally retain your personal information for as long as is necessary for the performance of the contract between you and us and to comply with our legal obligations. If you no longer want us to use your information to provide the Tiny Away Platform to you, you can request that we erase your personal information and close your Tiny Away Account. Please note that if you request the erasure of your personal information:</p>
<ul>
<li>We may retain some of your personal information as necessary for our legitimate business interests, such as fraud detection and prevention and enhancing safety. For example, if we suspend an Tiny Away Account for fraud or safety reasons, we may retain certain information from that Tiny Away Account to prevent that Member from opening a new Tiny Away Account in the future.</li>
<li>We may retain and use your personal information to the extent necessary to comply with our legal obligations. For example, Tiny Away and Tiny Away Payments may keep some of your information for tax, legal reporting and auditing obligations.</li>
<li>Information you have shared with others (e.g., Reviews, forum postings) may continue to be publicly visible on the Tiny Away Platform, even after your Tiny Away Account is cancelled. However, attribution of such information to you will be removed. Additionally, some copies of your information (e.g., log records) may remain in our database, but are disassociated from personal identifiers.</li>
<li>Because we maintain the Tiny Away Platform to protect from accidental or malicious loss and destruction, residual copies of your personal information may not be removed from our backup systems for a limited period of time.</li>
</ul>
 


 
			<p>6.5 Withdrawing Consent and Restriction of Processing.</p>
<p>Where you have provided your consent to the processing of your personal information by Tiny Away you may withdraw your consent at any time by changing your Account settings or by sending a communication to Tiny Away specifying which consent you are withdrawing. Please note that the withdrawal of your consent does not affect the lawfulness of any processing activities based on such consent before its withdrawal. Additionally, in some jurisdictions, applicable law may give you the right to limit the ways in which we use your personal information, in particular where (i) you contest the accuracy of your personal information; (ii) the processing is unlawful and you oppose the erasure of your personal information; (iii) we no longer need your personal information for the purposes of the processing, but you require the information for the establishment, exercise or defence of legal claims; or (iv) you have objected to the processing pursuant to Section 6.6 and pending the verification whether the legitimate grounds of Tiny Away override your own.</p>
<p>6.6 Objection to Processing.</p>
<p>In some jurisdictions, applicable law may entitle you to require Tiny Away and Tiny Away Payments not to process your personal information for certain specific purposes (including profiling) where such processing is based on legitimate interest. If you object to such processing Tiny Away and/or Tiny Away Payments will no longer process your personal information for these purposes unless we can demonstrate compelling legitimate grounds for such processing or such processing is required for the establishment, exercise or defence of legal claims.</p>
<p>Where your personal information is processed for direct marketing purposes, you may, at any time ask Tiny Away to cease processing your data for these direct marketing purposes by sending an e-mail to ask@tinyaway.com.</p>
<p>6.7 Lodging Complaints.</p>
<p>You have the right to lodge complaints about the data processing activities carried out by Tiny Away and Tiny Away Payments before the competent data protection authorities.</p>


<h4>7. SECURITY</h4>
<p>We are continuously implementing and updating administrative, technical, and physical security measures to help protect your information against unauthorized access, loss, destruction, or alteration. Some of the safeguards we use to protect your information are firewalls and data encryption, and information access controls. If you know or have reason to believe that your Tiny Away Account credentials have been lost, stolen, misappropriated, or otherwise compromised or in case of any actual or suspected unauthorized use of your Tiny Away Account, please contact us following the instructions in the Contact Us section below.</p>


<h4>8. CHANGES TO THIS PRIVACY POLICY</h4>
<p>Tiny Away reserves the right to modify this Privacy Policy at any time in accordance with this provision. If we make changes to this Privacy Policy, we will post the revised Privacy Policy on the Tiny Away Platform and update the “Last Updated” date at the top of this Privacy Policy. We will also provide you with notice of the modification by email at least thirty (30) days before the date they become effective. If you disagree with the revised Privacy Policy, you may cancel your Account. If you do not cancel your Account before the date the revised Privacy Policy becomes effective, your continued access to or use of the Tiny Away Platform will be subject to the revised Privacy Policy.</p>


<h4>9. CONTACT US</h4>
<p>If you have any questions or complaints about this Privacy Policy or Tiny Away’s information handling practices, you may email us or contact us at ask@tinyaway.com.</p>
 
     </div>

     {/*<SectionFounder />*/}

        {/*<SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
  />*/}

        {/*<SectionFounder />*/}

        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic />

        <SectionSubscribe2 />*/}
      </div>
    </div>
  );
};

export default Pageprivacy;
