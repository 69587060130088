import { CustomLink } from "data/types";
import { relativeTimeRounding } from "moment";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import twFocusClass from "utils/twFocusClass";


var DEMO_PAGINATION: CustomLink[] = [];

export interface PaginationProps {
  className?: string;
  count?:number;
  pageno?:string;
  page_url?:string;
  queryString?:boolean;
}



const Pagination: FC<PaginationProps> = ({ className = "" ,count=0,pageno="",page_url,queryString=false}) => {
  var Array2 = [];

  for(let index1=1;index1 <= count;index1++){
    if(queryString==true){
      Array2.push({label:`${index1}`, href:"/"+page_url+"&page="+index1});  
    }else{
      Array2.push({label:`${index1}`, href:"/"+page_url+"?page="+index1});  
    }
    
  }
  DEMO_PAGINATION = Array2;
  const renderItem = (pag: CustomLink, index: number) => {

    index     = index+1;
    if (index === parseInt(pageno)) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          key={index}
          className={`amit inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
        >
          {pag.label}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    
    return (
      <Link
        key={index}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
        to={pag.href}
      >
        {pag.label}
        
      </Link>
    );
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {DEMO_PAGINATION.map(renderItem)}
    </nav>
  );
};

export default Pagination;
