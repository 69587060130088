import React, { FC,useState,useEffect,Fragment  } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Route, Redirect,useHistory,Link,useParams} from 'react-router-dom';
import { Helmet } from "react-helmet";
import axios from 'axios'; 
export interface PayPageProps {
  className?: string;
  clientSecret?: string;
}
const PayPage: FC<PayPageProps> = (
  {className = "",
}) => {
    const history                                               = useHistory();
    const params                                                  = useParams();
    const slug                                                    = Object.values(params);
    const [bookingItem, setBookingDetails]                     = useState({listing_image:"",
                                                                           listing_name:"",
                                                                           full_address:"",
                                                                           booking_date:"",
                                                                           total_guests:"",
                                                                           confirmation_code:"",
                                                                           booked_date:"",
                                                                           total_payout:"",
                                                                           payment_method:""
                                                                          });
    const [isdataLoaded, setIsdataLoaded]                         = useState(false);
    const [isBookingFound,setIsBookingFound]                      = useState(false); 
    useEffect(() => {
        axios.get(process.env.REACT_APP_BASE_URL+'getHolidayExtensionDetails?booking_code='+slug[0]+"&token_id="+slug[1])
        .then(res => {
          if(res.data.success==true){
            setBookingDetails(res.data.bookings)
            setIsBookingFound(true)
          }
          setIsdataLoaded(true);
        })
    },[]);
  const renderBookingDetails = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-5 px-0 sm:p-6 xl:p-5">
        <h2 className="text-3xl lg:text-4xl font-semibold">
        Your holiday extension is confirmed! 🎉
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* ------------------------ */}
        {bookingItem  ? 
        <div>
        <div>
            <div className="flex justify-between space-y-6 br-sm-box">
          {/* <h3 className="text-2xl font-semibold">Booking detail's</h3> */}
          <div className="flex flex-col sm:flex-row flex-sm-div-col-none">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage src={bookingItem.listing_image} />
              </div>
            </div>
            <div className="pt-0  sm:pb-0 sm:px-5 space-y-2">
              <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1 font-bold ">
                  {bookingItem.listing_name}
                </span>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1 mt-1">
                  {bookingItem.full_address}
                </span>
                
              </div>
              {/* <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {bookingItem.beds} beds · {bookingItem.bathrooms} baths
              </span> */}
              <div className="mt-0 flex  date-time-sm">
            <div className="flex-1 p-0 flex space-x-0">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex flex-col">
                <span className="mt-1.5 text-lg font-semibold">
                  {bookingItem.booking_date}
                </span>
              </div>
            </div>
            <div className="flex-1 p-0 flex space-x-0">
              <svg className="w-8 h-8 text-neutral-300 dark:text-neutral-6000" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                 
                <span className="mt-1.5 text-lg font-semibold">{bookingItem.total_guests}</span>
              </div>
            </div>
          </div>
              
              {/* <StartRating reviewCount={bookingItem.reviewcount} point={bookingItem.reviewStar} isHost={false} /> */}
            </div>
          </div>
          <div className="space-y-0 sm-50-div">
        {/* <h2 className="text-3xl lg:text-4xl font-semibold">Booking detail's</h2> */}
          <div className="flex flex-col space-y-0 ">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1 w-250-px">Booking code</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                #{bookingItem.confirmation_code}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1 w-250-px">Date</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {bookingItem.booked_date}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1 w-250-px">Total</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {bookingItem.total_payout}
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1 w-250-px">Payment method</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {bookingItem.payment_method}
              </span>
            </div>
          </div>
        </div>
        </div>
        <div className="border-b border-neutral-200 dark:border-neutral-700 m-2"></div>
        </div>
        </div>
        :""}
      </div>
    );
  };
  const renderskeletonLoader = () => {
    return (
      <div className="custom-loader gap-3 justify-center lg:flex-row">
        <div className="border-neutral-200 sm:border sm:rounded-2xl w-70-1 w-70-loader">
          <div className="card m-2">
            <div className="card-body">
              <div className="row mb-5">
                <div className="col-12">
                  <div className="p-3 bg-grey skeleton"></div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-3">
                  <div className="p-5 bg-grey skeleton"></div>
                  <div className="p-2 bg-grey skeleton"></div>
                </div>
                <div className="col-8">
                  <div className="p-2 bg-grey mb-2 skeleton"></div>
                  <div className="p-2 bg-grey mb-2 skeleton"></div>
                  <div className="p-2 bg-grey mb-4 skeleton"></div>
                  <div className="p-2 bg-grey mb-2 skeleton"></div>
                </div>
              </div>
        <div className="row mb-3"></div>
        <div className="gap-3 mb-4 row">
          <div className="border-neutral-200 col-6 p-2.5 sm:border sm:rounded-2xl">
            <div className="p-2 bg-grey mb-2 skeleton"></div>
            <div className="p-2 bg-grey mb-0 skeleton"></div>
          </div>
          <div className="border-neutral-200 col-6 p-2.5 sm:border sm:rounded-2xl">
            <div className="p-2 bg-grey mb-2 skeleton"></div>
            <div className="p-2 bg-grey mb-0 skeleton"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="bg-grey h-6 mb-3 p-2 skeleton"></div>
          </div>
        </div>
        <div className="row"><div className="col-3 mr-40">
          <div className="bg-grey mb-3 p-1 skeleton"></div>
        </div>
        <div className="col-3 p-1">
          <div className="bg-grey mb-3 p-1 skeleton"></div>
        </div>
        </div>
        <div className="row"><div className="col-3 mr-40">
          <div className="bg-grey mb-3 p-1 skeleton"></div>
        </div>
        <div className="col-3">
          <div className="bg-grey mb-3 p-1 skeleton"></div>
        </div>
        </div>
        <div className="row">
          <div className="col-3 mr-40">
            <div className="bg-grey mb-3 p-1 skeleton"></div>
          </div>
        <div className="col-3">
          <div className="bg-grey mb-3 p-1 skeleton"></div>
        </div>
        </div>
        <div className="row">
          <div className="col-3">
            <div className="bg-grey h-6 mb-3 p-2 skeleton"></div>
          </div>
        </div>
      </div>
      </div>
      </div>
    </div>
    );
  }

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <Helmet title="Tiny Away | Payment Success"></Helmet>
      {isdataLoaded  ?
        <div>
        <main className="container mt-11 mb-4 lg:mb-2 flex flex-col-reverse lg:flex-row">
          {isBookingFound ?
            renderBookingDetails()
            :
            <div className="alert alert-danger mx-auto w-1/2" role="alert">  <span className="las la-exclamation-circle"></span>No booking found!</div>
          }
        </main>
        </div>
      :
        <main className="container mt-11 mb-24 lg:mb-32 ">
        {renderskeletonLoader()}
        </main>
      }
    </div>
  );
};
export default PayPage;