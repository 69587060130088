import React, { FC,useState,useEffect,ComponentType } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from 'axios'; 

export interface PageSponsorsProps {
  className?: string;
}
const PageSponsors: FC<PageSponsorsProps> = ({
  className = ""
}) => {  
  const [description, setDescription]          = useState("");
  const [metaTitle, setMetaTitle]              = useState("");
  const [isdataLoaded, setIsdataLoaded]        = useState(false);


  useEffect(() => {
      axios.get(process.env.REACT_APP_BASE_URL+'getSponsors')
  .then(res => {
    setDescription(res.data.sponsors);
    setMetaTitle(res.data.meta_title);
    setIsdataLoaded(true);
  })
}, [isdataLoaded]);
const renderskeletonLoader = () => {
  return (
    <div className="custom-loader"><div className="h-screen skeleton"></div></div>
  );
};
  return (
    <div className="sponnsores-main">
      <Helmet title={metaTitle}></Helmet>
      <div className="container">
        <div className="mb-24 lg:mb-32">
          <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Sponsors
          </h2>
            <div className="clins-logo"  dangerouslySetInnerHTML={ {__html: description} } />
        </div>
      </div>
    </div>
  );
};

export default PageSponsors;
