import React, { FC,useEffect,useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/five-hills-scaled_latest.jpg";
//import imagePng from "images/DJI_0756.jpg";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import videoplayback from "images/videoplayback.mp4";
import axios from 'axios';  

export interface SectionHeroProps {
  className?: string;
  targetlanguage?:string;
}

const SectionHero: FC<SectionHeroProps> = ({ 
    className = "", 
    targetlanguage  = localStorage.getItem("targetlanguage") ? localStorage.getItem("targetlanguage") : (window.location.hostname=='tinyaway.com.tw' || window.location.hostname=='www.tinyaway.com.tw')?"zh_tw":"en",
  }) => {
  const [bannnerUrl, setBannnerUrl]             = useState("");
  const [isVideo, setIsVideo]                   = useState("");
  const [bannerHeading, setBannerHeading]       = useState("");
  const [bannerSubHeading, setBannerSubHeading] = useState("");
  const [isWebsiteName, setIsWebsiteName]       = useState("tinyaway");
  const [isdataLoaded, setIsdataLoaded]         = useState(false);
  let taggetLanguage                            = localStorage.getItem("targetlanguage");


  useEffect(() => {
      axios.get(process.env.REACT_APP_BASE_URL+'getBanners?language='+targetlanguage)
  .then(res => {
    console.log(res.data);
    setBannnerUrl(res.data.banners.banner_url);
    setIsVideo(res.data.banners.is_video);
    setBannerHeading(res.data.banners.heading);
    setBannerSubHeading(res.data.banners.sub_heading);
    setIsWebsiteName(res.data.isWebsiteName);
    setIsdataLoaded(true);
  })
}, [bannnerUrl]);

const renderBannerContentsForLazarus =() =>{
  return(
    <div className="hero-sections-box">
    <svg width="600" height="600" preserveAspectRatio="xMidYMid meet" data-bbox="3.5 3.5 193 193" viewBox="3.5 3.5 193 193" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true">
            <g>
                <path fill="#FFFFFF" d="M100.267 3.5v16.632h-.533V3.5h.533z" data-color="1"></path>
                <path fill="#FFFFFF" d="M100.267 179.868V196.5h-.533v-16.632h.533z" data-color="1"></path>
                <path fill="#FFFFFF" d="M196.5 99.734v.533h-16.632v-.533H196.5z" data-color="1"></path>
                <path fill="#FFFFFF" d="M20.132 99.734v.533H3.5v-.533h16.632z" data-color="1"></path>
                <path fill="#FFFFFF" d="M183.533 51.68l.265.462-14.42 8.288-.265-.462 14.42-8.288z" data-color="1"></path>
                <path fill="#FFFFFF" d="M30.622 139.565l.265.462-14.42 8.288-.265-.463 14.42-8.287z" data-color="1"></path>
                <path fill="#FFFFFF" d="M147.819 16.183l.462.265L140 30.872l-.463-.266 8.282-14.423z" data-color="1"></path>
                <path fill="#FFFFFF" d="M59.997 169.131l.463.266-8.282 14.423-.462-.265 8.281-14.424z" data-color="1"></path>
                <path fill="#FFFFFF" d="M51.896 16.342l8.331 14.395-.461.267-8.331-14.395.461-.267z" data-color="1"></path>
                <path fill="#FFFFFF" d="M140.236 168.986l8.33 14.395-.46.267-8.332-14.395.462-.267z" data-color="1"></path>
                <path fill="#FFFFFF" d="M16.39 51.817l14.433 8.264-.265.463-14.433-8.265.265-.462z" data-color="1"></path>
                <path fill="#FFFFFF" d="M169.44 139.454l14.433 8.264-.264.463-14.434-8.265.265-.462z" data-color="1"></path>
            </g>
        </svg>
      <div className="text-banner-top">
      <p className=" font-normal white text-center sm:text-xl text-neutral-500 dark:text-neutral-400">HELLO SINGAPORE, </p>
      <h1 className="font-semibold">“TINY HOUSES”</h1>
      <p className="font-normal white text-center sm:text-xl text-neutral-500 dark:text-neutral-400"> ARE HERE AT LAZARUS ISLAND!</p>
      </div>
    </div>
  )
}
const renderBannerContentsForTinyaway=()=>{
  return(
      <>
      <h1 className="text-3xl md:text-4xl font-semibold">{bannerHeading}</h1>
      <p className="mt-2 md:mt-3 font-normal white text-center sm:text-xl text-neutral-500 dark:text-neutral-400">{bannerSubHeading}</p> 
      <div className="d-flex">
          <HeroSearchForm />
        </div>
      </>
  );
}
const renderskeletonLoader = () => {
  return (
    <div className="home-header-page">
      <div className="mix-loader-banner">
        <h1 className="h-8 w-2/3"></h1>
        <p className="h-8 w-2/3"></p>
        <div className="h-14 mt-10 w-full "></div>
      </div>
    </div>
  );
};

  return (
    <>
    {isdataLoaded ?
    <div className={`container-fluid relative space-y-0 mb-10 lg:space-y-0 lg:mb-0 banner-top ${isWebsiteName}`}>
    <div className={`nc-SectionHero flex ml-15 mr-15 flex-col-reverse lg:flex-col relative ${className}`} data-nc-id="SectionHero">
      <div className="z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        {isVideo=='yes'?
          <video width="100%"  loop autoPlay={true} height="100%" controls className="group  bg-neutral-800 rounded-l overflow-hidden border-4 border-white dark:border-neutral-900 sm:rounded-[50px] sm:border-[10px]" > 
          <source src={bannnerUrl} type="video/mp4"/> </video>
        :
          <img className="w-full" src={bannnerUrl} alt="" />
        }
      </div>
      <div className="z-10 mb-12 lg:mb-0 lg:-mt-40 w-full formsearchtop">
      {isWebsiteName=='escape_lazarus' ? renderBannerContentsForLazarus() : renderBannerContentsForTinyaway()}
      </div>
    </div>
    </div>
    :
    <div className={`container-fluid relative space-y-0 mb-10 lg:space-y-0 lg:mb-0 ${isWebsiteName}`}>
      {renderskeletonLoader()}
    </div>
    }
    </>
  );
};

export default SectionHero;
