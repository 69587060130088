import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";

export interface StayCardMProps {
  className?: string;
  data?: StayDataType;
  size?: "default" | "small";
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];
const StayCardM: FC<StayCardMProps> = ({
  className = "",
  size = "default",
  data = DEMO_DATA,
}) => {
  const {
    galleryImgs,
    listingCategory,
    address,
    title,
    bedrooms,
    beds,
    maxGuests,
    bathrooms,
    href,
    like,
    saleOff,
    pets_frendly,
    isAds,
    price,
    reviewStart,
    reviewCount,
    id,
    currency_code
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full sm:w-100 ">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={galleryImgs}
          uniqueID={`stay-${id}`}
        />
        <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" />
        {saleOff && <SaleOffBadge desc={saleOff} className="absolute left-3 top-3" />}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="hidden sm:grid grid-cols-3 gap-2">
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-bed text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {beds} bed
            </span>
          </div>
        </div>
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-bath text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {bathrooms} bath
            </span>
          </div>
        </div>
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-user text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {maxGuests} guest 
            </span>
          </div>
        </div>        
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-0">
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <span>
            Tiny House
            </span>
          </div>
          <div className="flex items-center space-x-2">
            {isAds && <Badge name="ADS" color="green" />}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
          
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            
            <span className="">{address}</span>
          </div>
        </div>
        {/* <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-2"></div> */}
        {/* {renderTienIch()} */}
        <div className="w-100 border-b border-neutral-100 dark:border-neutral-800 my-2"></div>
        <div className="flex justify-between items-end">
          <StartRating reviewCount={reviewCount} point={reviewStart} isHost={false} />
          <span className="text-base font-semibold text-secondary-500">
          <sup>{currency_code}</sup>{price}
            {` `}
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              /night
            </span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCardM group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCardM"
    >
      <Link to={href} target={'_blank'} className="flex flex-col sm:flex-row sm:items-center flex-wrap">
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default StayCardM;
