import React, { FC } from "react";
import rightImgPng from "images/TINY-AWAY-QUIET-LAKE-HOUSE-SJL-Photography-00006-667x500.jpg";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
  targetlanguage?:string;
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
  targetlanguage  = localStorage.getItem("targetlanguage") ? localStorage.getItem("targetlanguage") : (window.location.hostname=='tinyaway.com.tw' || window.location.hostname=='www.tinyaway.com.tw')?"zh_tw":"en",
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-16" : "lg:pr-16"
        }`}
      >
        
        <h2 className="font-semibold text-4xl mt-5">{targetlanguage=='zh_tw'?'得來不易的假期，住宿已經訂好了嗎？':'Have you booked your well deserved getaway?'}</h2>

        <ul className="space-y-10 mt-6 mb-li-sp">
          <li className="">
          
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {targetlanguage=='zh_tw'?'許多報導都指出「宅度假」會在假期間掀起一波新熱潮，而且國內旅客會傾向選擇較大的開放式空間，而非小而擁擠住宿體驗。':'Many reports have pointed out that staycations will be the new vacation norm for most of us. Besides that, domestic travellers will prefer accommodation that have larger open spaces and are not as crowded.'}
            </span>
          </li>
          <li className="">
          
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {targetlanguage=='zh_tw'?'不想再被關在室內，想到戶外走走活動嗎？親近大自然的體驗會是你們最棒的選擇，移動微型屋（Tiny Away）結合時下新潮流的微型屋跟生態旅遊，旅客在微型屋內能親近大自然，同時還是能享受宅在家的感覺。想不想體驗看看住在微型屋的感覺？微型屋出租，體驗式旅行等著你！':'Guests will also want to be outside more, rather than being confined indoors. And close-to-nature experiences will be high on their priority lists. Integrating the concept of the trending tiny house movement with ecotourism, Tiny Away helps guests stay close to nature, yet still be able to enjoy the comforts a normal home can afford. Would you like to take the opportunity to experience tiny house living? Tiny house rentals. Experiential travel.'}
            </span>
          </li>
          <li className="">
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {targetlanguage=='zh_tw'?'在Tiny Away，你入住我種樹。 只要你入住一天Tiny Away，我們就會在世界的某處種下一棵樹，一起讓世界更美好。':'In Tiny Away, we are planting trees every month. Let’s do this together. '}<a href="/onetreeplanted" className="color-link">{targetlanguage=='zh_tw'?'閱讀更多':'Read more.'}</a>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
