import rightImg from "images/about-hero-right.png";
import React, { FC,useState,useEffect,useRef,ComponentType } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Select from "react-select";
import axios from 'axios'; 
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
const { REACT_APP_SITE_KEY } = process.env;


export interface Pagecovid19policyProps {
  className?: string;
  autoFocus?: boolean;
}
const PageCorporateMembership: FC<Pagecovid19policyProps> = ({
  className = "",
  autoFocus = false
}) => {

  const [showPopover, setShowPopover]                       = useState(autoFocus);
  const [isdataLoaded, setIsdataLoaded]                       = useState(false);
  const [details, setDetails] = useState({
    name: "",
    companyname: "",
    email: "",
    contactnumber: "",
    country:"",
    google_captcha: ""
  });
  const videoEl :any                                          = useRef(null);
  const NameRef                                             = useRef<HTMLInputElement>(null);
  const EmailRef                                            = useRef<HTMLInputElement>(null);
  const ContactNumberRef                                    = useRef<HTMLInputElement>(null);
  const SubjectRef                                          = useRef<HTMLInputElement>(null);
  const MessagesRef                                         = useRef<HTMLInputElement>(null);
  const captchaRef                                          = useRef<ReCAPTCHA>(null);
  const [selectedOption, setSelectedOption]                   = useState();
  const [selectedCountry, setSelectedCountry]                 = useState([{ value: "id", label: "" }]);
  const [optionList, setOptionList]                           = useState([{ value: "id", label: "Red1" }]);
  const [errorMsg, setErrorMsg]                             = useState('');
  const [successMsg, setSuccessMsg]                         = useState('');
  const [value, setValue]                                     = useState("");

  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + 'CountryDropdownList')
      .then(res => {
        setOptionList(res.data.response);
      })
  }, [selectedOption]);
function handleSelect(data:any) {
  setSelectedOption(data);
  if(data?.value!='' && data?.value!=undefined){
    setSelectedCountry(data?.value);
    setDetails({...details,country:data?.value});
  }else{
    setDetails({...details,country:""});
  }
}

const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();
  details.contactnumber = value;
  const isInvalid = validateInput();
  if (!isInvalid) {
    //const token = captchaRef.current?.getValue();
      captchaRef.current?.reset();
     // console.log(token);
      //console.log(details.google_captcha);
      
    axios.post(process.env.REACT_APP_BASE_URL + '/corporateMembership', details)
      .then(function (response) {
        //console.log(response.data.response);
        if (response.data.status == 200) {
          setSuccessMsg(response.data.response);
          setDetails({
            name: "",
            companyname: "",
            email: "",
            contactnumber: "",
            country:"",
            google_captcha: ""
          });
        } else {
          setErrorMsg(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    setSuccessMsg('');
  }
};
const validateInput = () => {
  const fields = [
    {
      name: 'name',
      value: details.name,
      message: 'Your Name should not be blank.'
    },
    {
      name: 'companyname',
      value: details.companyname,
      message: 'Your Company name should not be blank.'
    },
    {
      name: 'email',
      value: details.email,
      message: 'Your Email should not be blank.'
    },
    {
      name: 'contactnumber',
      value: details.contactnumber,
      message: 'Contact Number should not be blank.'
    }
  ]
  const isNotFilled = fields.some(field => {
    if (field.value.trim() === '') {
      setErrorMsg(field.message);
      setShowPopover(true);
      return true;
    }
    setShowPopover(false);
    setErrorMsg('');
    return false;
  });
  return isNotFilled;
};
 function SetRecaptchaVal(value: any) {
   setDetails({ ...details, google_captcha:value});
 }

const handletoPlayVideo = () => {
    videoEl.current.play();
}

  return (
    <div
      className={` nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
    <Helmet title={'Tiny House Corporate Membership | Gift your employees with an amazing experience to raise their morale'}></Helmet>
     <div className="banner-images ">
      <div className="contener">
        <div className="top-section">
          <h1>Corporate Membership</h1>
          <p>The perfect opportunity for your employees to relieve stress from their <br/>daily hustle and to stay motivated</p>
          <a href="#get-in-tech" className="btn banner-button">Find Out More</a>
        </div>

      </div>
     </div>
     <div className="back-bg">
     <div className="middle-detrails">
        <div className="container">
            <div className="left-section">
            <h1>Enjoy Unlimited 10% Discount <br/>On All Tiny Houses</h1>
            <p>With over 400 Tiny Houses and 2 Escape Developments around the world, you can gift your employees an experience that is out of this world.</p>
            <a href="#get-in-tech" className="btn learn-more-button">Learn More</a>
            </div>
            <div className="right-section" onClick={handletoPlayVideo}>
              <img src="https://pictures.tinyaway.com/corporate-mobile-banner.png" className="main-image"></img>

              <div className="mobile-box">
              <img src="https://pictures.tinyaway.com/corporate-mobile-bg.png"  className="mobile-backimage"></img>
                  {<video src="https://cdn.tinyaway.com/common/2024/02/jades-video.mp4" controls autoPlay={false} ref={videoEl}  className="video">
                </video> }
              </div>
              
              
              
            </div>
        </div>
     </div>

     <div className="middle-detrails-second">
        <div className="container">
        <div className="left-section">
              <img src="https://pictures.tinyaway.com/corporate-mid.png"></img>
            </div>
            <div className="right-section">
            <h1>Accessible, Amazing activities, Away from bustling cities</h1>
            <p>The perfect getaway for your employees and co-workers to enjoy different unique experiences while lodging in the different tiny houses we have worldwide.</p>
            <a href="#get-in-tech" className="btn interested">Interested</a>
            </div>
            
        </div>
     </div>
     <div className="middle-detrails third-section">
        <div className="container">
            <div className="left-section">
            <h1>Your<br/>Superhost</h1>
            <p>Tiny Away has received the superhost status on Airbnb since 2018.</p>
            <a href="#get-in-tech" className="btn learn-more-button">Learn More</a>
            </div>
            <div className="right-section">
              <img src="https://pictures.tinyaway.com/corporate-comment.png"></img>
            </div>
        </div>
     </div>
     <div className="bottom-middle-detrails">
        <div className="container">
          <div className="bottom-text">
          <h1>Perfect For Company Retreats,<br/> Team Bondings And Holidays</h1>
            <a href="#get-in-tech" className="btn">Start Now</a>
          </div>
            <img src="https://pictures.tinyaway.com/corporate-lowermid.png"  id="get-in-tech"></img>
        </div>
     </div>
     </div>

     <div className="footer-bottom" >
      <div className="container">
        <div className="footer-content">
        <h1>Get In Touch</h1>
          <form  onSubmit={submitHandler} autoComplete="off" method="post">
          <div className="w-full text-center">
          {successMsg &&
          <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
            {successMsg}
          </div>
          }
          {errorMsg &&
            <div className="alert alert-warning" role="warning alert"><span className="las la-exclamation-circle"></span>
              {errorMsg}
            </div>
          }
          </div>
              <div className="form-group">
              <Input
                    type="text"
                    placeholder="Name"
                    name="name"
                    id="name"
                    onChange={event => setDetails({ ...details, name: event.target.value })}
                    ref={NameRef}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.name}
                  />
              </div>
              <div className="form-group">
              <Input
                    type="text"
                    placeholder="Company Name"
                    name="companyname"
                    id="companyname"
                    onChange={event => setDetails({ ...details, companyname: event.target.value })}
                    ref={NameRef}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.companyname}
                  />
              </div>
              <div className="form-group">
                <Input
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    id="email"
                    onChange={event => setDetails({ ...details, email: event.target.value })}
                    ref={EmailRef}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.email}
                  />
              </div>
              <div className="form-group">
              <PhoneInput
                  placeholder="Enter phone number"
                  international
                  value={details.contactnumber}
                  defaultCountry="AU"
                  onChange={setValue}
                  countryCallingCodeEditable={false}
                  phoneDigits={8}
                  name="contactnumber"
                  id="contactnumber"
                  error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                />
              </div>
              <div className="form-group">
              <Select
                defaultValue={selectedOption}
                onChange={handleSelect}
                placeholder="Choose country"
                options={optionList}
                isSearchable={true}
                isClearable
                id="country"
                name="country"
              />
              </div>
              <div className="form-group">
              <ReCAPTCHA
                  size="invisible"
                  sitekey={REACT_APP_SITE_KEY!}
                  ref={captchaRef}
                  id="google_captcha"
                  data-callback="onSubmit"
                  onChange={SetRecaptchaVal}
                />
              </div>
              <div className="w-full text-center">
                <ButtonPrimary type="submit" className="btn_send_email submit">Send Message</ButtonPrimary>
              </div>
            </form>
      </div>
      </div>
     </div>
    </div>
  );
};
export default PageCorporateMembership;