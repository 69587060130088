import React, { FC, useState, useEffect, ComponentType } from "react";
import { Helmet } from "react-helmet";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Rating } from 'react-simple-star-rating'
import axios from 'axios';
import { constants } from "buffer";
import GallerySlider from "components/GallerySlider/GallerySlider";



export interface PageReviewRatingProps {
  className?: string;
  ratioClass?: string;
}
const PageReviewRating: FC<PageReviewRatingProps> = ({
  className = "",
  ratioClass = "aspect-w-3 aspect-h-2",
}) => {

  const [description, setDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [bannnerUrl, setBannnerUrl] = useState("");
  const [isVideo, setIsVideo] = useState("");
  const [isImages, setIsImages] = useState("");
  const [isdataLoaded, setIsdataLoaded] = useState(false);
  const galleryImgs = ["https://pictures.tinyaway.com/filters:format(jpg)/450x300/Listings/Images/2023/04/6/nrrthn5f0xkq3rjodbd31681950655946.jpg"]
  
  const [file, setFile] = useState([]);

  const [rating, setRating] = useState(0);

  // const uploadSingleFile = (e:Array<T>) => {
  //   let ImagesArray = Object.entries(e.target.files).map((e) =>
  //     URL.createObjectURL(e[1])
  //   );
  //   console.log(ImagesArray);
  //   setFile([...file, ...ImagesArray]);
  //   console.log("file", file);
  // }

  // function upload(e) {
  //   e.preventDefault();
  //   console.log(file);
  // }

  // function deleteFile(e) {
  //   const s = file.filter((item, index) => index !== e);
  //   setFile(s);
  //   console.log(s);
  // }


  // const FileUploadService = () =>{
  //   upload(file, onUploadProgress) {
  //     let formData = new FormData();
  //     formData.append("file", file);
  //     return http.post("/upload", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data"
  //       },
  //       onUploadProgress
  //     });
  //   }
  //   getFiles() {
  //     return http.get("/files");
  //   }
  // }


  // Catch Rating value
  const handleRating = (rate: number) => {
    setRating(rate)
    console.log(rate);
    // other logic
  }
  // Optinal callback functions
  const onPointerEnter = () => console.log('Enter')
  const onPointerLeave = () => console.log('Leave')
  //const onPointerMove = (value: number, index: number) => console.log(value, index)


  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + 'getPages?page=faq')
      .then(res => {
        console.log(res.data);
        setDescription(res.data.page_details.content_html);
        setMetaTitle(res.data.page_details.meta_title);
        setBannnerUrl(res.data.page_details.banner_url);
        setIsVideo(res.data.page_details.is_video);
        setIsImages(res.data.page_details.is_images);
        setIsdataLoaded(true);
      })
  }, [isdataLoaded]);
  const renderleftimagecard = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`stay-v-rty5t`}
          ratioClass={ratioClass}
          galleryImgs={galleryImgs}
        />

      </div>
    );
  };
  const userimageanddetail = () => {
    return (
      <div className="p-3 user-detal">
        <div className="user-img"><img src="https://pictures.tinyaway.com/filters:format(jpg)/200x200/users_pics/2023/07/unnamed1690103856236.jpg" /></div>
        <div>
          <p><b>Amit Singh</b></p>
          <p>Guest </p>
          <p>12 May 2023</p>
        </div>
      </div>
    );
  };

  const renderskeletonLoader = () => {
    return (
      <div className="custom-loader"><div className="h-screen skeleton"></div></div>
    );
  };
  return (
    <div className={`nc-PageAbout overflow-hidden color-page-rewaiw relative ${className}`} data-nc-id="PageAbout">
      <Helmet title={metaTitle}></Helmet>
      {isdataLoaded ?
        <div className="container py-16 pt-0 lg:py-18 space-y-16 lg:space-y-28">
          {isVideo == 'yes' ?
            <video width="100%" loop autoPlay={true} height="100%" controls className="group  bg-neutral-800 rounded-l overflow-hidden border-4 border-white dark:border-neutral-900 sm:rounded-[50px] sm:border-[10px]" >
              <source src={bannnerUrl} type="video/mp4" /> </video>
            :
            ""
          }
          {isImages == 'yes' ?
            <img className="w-full" src={bannnerUrl} alt="hero" />
            :
            ""
          }
          <div className="our-tiny p-0 pl-0">

            <div className="review-page-row mt-5 mt-100 mb-100">
              <div className="left-w-review">
                <div className="user-photo-review">
                  {renderleftimagecard()}
                  {userimageanddetail()}
                </div>
              </div>
              <div className="right-w-review">
                <div className="review-star">
                 

                  <div className="cart-rating">
                    <div className="icon-ration-img">
                      <div className="icon-svg-rat"> <img src="https://cdn.tinyaway.com/common/2023/07/Location1690433775835.png"/> </div>
                     <h2>Location</h2>
                    
                  <Rating
                    size={50}
                    transition
                    allowFraction
                    SVGstrokeColor="#FFD700"
                    SVGstorkeWidth={1}
                    emptyColor="#fff"
                    fillColor="#FFD700"
                    onClick={handleRating}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                  // onPointerMove={onPointerMove}
                  /* Available Props */
                  />
                  </div>
                </div>

                <div className="cart-rating">
                    <div className="icon-ration-img">
                     <div className="icon-svg-rat"> <img src="https://cdn.tinyaway.com/common/2023/07/Cleanliness1690433739856.png"/> </div>
                     <h2>Cleanliness</h2>
                    
                  <Rating
                    size={50}
                    transition
                    allowFraction
                    SVGstrokeColor="#FFD700"
                    SVGstorkeWidth={1}
                    emptyColor="#fff"
                    fillColor="#FFD700"
                    onClick={handleRating}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                  // onPointerMove={onPointerMove}
                  /* Available Props */
                  />
                  </div>
                </div>

                <div className="cart-rating">
                    <div className="icon-ration-img">
                     <div className="icon-svg-rat"> <img src="https://cdn.tinyaway.com/common/2023/07/Host1690433758322.png"/> </div>
                     <h2>Host</h2>
                    
                  <Rating
                    size={50}
                    transition
                    allowFraction
                    SVGstrokeColor="#FFD700"
                    SVGstorkeWidth={1}
                    emptyColor="#fff"
                    fillColor="#FFD700"
                    onClick={handleRating}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                  // onPointerMove={onPointerMove}
                  /* Available Props */
                  />
                  </div>
                </div>

                <div className="cart-rating">
                    <div className="icon-ration-img">
                      <div className="icon-svg-rat"> <img src="https://cdn.tinyaway.com/common/2023/07/Things-to-do1690433799461.png"/> </div>
                     <h2>Things to do</h2>
                    
                  <Rating
                    size={50}
                    transition
                    allowFraction
                    SVGstrokeColor="#FFD700"
                    SVGstorkeWidth={1}
                    emptyColor="#fff"
                    fillColor="#FFD700"
                    onClick={handleRating}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                  // onPointerMove={onPointerMove}
                  /* Available Props */
                  />
                  </div>
                </div>
                </div>

              <div className="cart-full-rating">
                <h2>Tell us more about your stay.</h2>
               
               <div className="">
               <div className="user-type-textarea">
                  <input className="" placeholder="Review title" />
                </div>
                <div className="user-type-textarea">
                  <textarea className="">Your review</textarea>
                </div>
                {/* <div className="flex gap-4 justify-end mt-15">
                  <b>Upload photos/videos here:</b>
                <input type="file"/>
                </div> */}
              </div>

                <div className=" text-center max-width-600 mt-10">
                  <ButtonPrimary className="pl-4 pr-4">Submit </ButtonPrimary>
                </div>
                </div>


                {/* <div className="reviewing-for-you">
                <h2 className="mb-3">Thank you for reviewing</h2>
              <p>Thank you for reviewing your guest. your review will be visible on their
              profile page after they complete theri part of the review or when the 
              review period ends ( days after checkout)</p>
                </div> */}

                

              </div>

             
            </div>
          </div>
        </div>
        :
        <div className="container py-16 pt-0 lg:py-18 space-y-16 lg:space-y-28">


          {renderskeletonLoader()}
        </div>
      }
    </div>
  );
};

export default PageReviewRating;
