import React, { FC,useEffect,useState } from "react";
import { useParams } from 'react-router-dom';
import { ArrowRightIcon } from "@heroicons/react/outline";
//mport LocationMarker from "components/AnyReactComponent/LocationMarker";
import AnyReactThingsComponent from "components/AnyReactComponent/AnyReactThingsComponent";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import StartRating from "components/StartRating/StartRating";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import { DayPickerSingleDateController } from "react-dates";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import ExperiencesDateSingleInput from "components/HeroSearchForm/ExperiencesDateSingleInput";
import GallerySlider from "components/GallerySlider/GallerySlider";
import axios from 'axios'; 
import { Helmet } from "react-helmet";

export interface ThingsDetailPageProps {
  className?: string;
  ratioClass?: string;
}


const galleryImgs:string[]=[
  "https://pictures.tinyaway.com/filters:format(jpg)/450x300/Stories/Images/2022/05/124/Tiny-Away-800-011653897785167.jpg",
  "https://pictures.tinyaway.com/filters:format(jpg)/450x300/Stories/Images/2022/05/124/Tiny-Away-800-011653897785167.jpg",
  "https://pictures.tinyaway.com/filters:format(jpg)/450x300/Stories/Images/2022/05/124/Tiny-Away-800-011653897785167.jpg"

];
const includes_demo = [
  { name: "Set Menu Lunch on boat" },
  { name: "Express Bus From Hanoi To Halong and Return" },
  { name: "Mineral Water On Express Bus" },
  { name: "Kayak or Bamboo Boat. Life Jacket." },
  { name: "Halong Bay Entrance Ticket" },
];
console.log(11, moment());

const ThingsDetailPage: FC<ThingsDetailPageProps> = ({
  className = "",
  ratioClass = "aspect-w-3 aspect-h-2",
}) => {
  const params = useParams();
  // yuo can find all params from here
  const slug=Object.values(params);
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(
    moment().add(2, "days")
  );
  const [datall, setAatall] = useState([]);
  const [stoty_title, setStorytitle] = useState([]);
  const [infuencername, setInfuencername] = useState([]);
  const [PHOTOS, setPHOTOS] = useState([]);
  const [descriptions, setDescriptions] = useState("");
  const [listingName, setListingName] = useState("");
  const [address, setAddress] = useState("");
  const [mapcoordinates, setMapcoordinates] = useState({
    "lat": -37.3218879,
    "lng": 145.3109719
});
  const [maplat, setMaplat] = useState(-37.3218879);
  const [maplng, setMaplng] = useState(145.3109719);
  const [mapzoom, setMapzoom] = useState(6);
  const [metatitle, SetMetatitle] = useState([]);
  const [metadata, SetMetaData] = useState([]);
  const [influencer_pic, setInfluencerpic]  = useState("");
  const [storyTitle, setStoryTitle]         = useState("");
  const [listingLink, setListingLink]       = useState("");
  const [storyLink, setstoryLink]           = useState("");

console.log(process.env.REACT_APP_BASE_URL+'GetThingsDetails?id='+slug);
  useEffect(() => {
   
    axios.get(process.env.REACT_APP_BASE_URL+'GetThingsDetails?id='+slug)
 	 .then(res => {
          console.log('user effect0000'); 
	      	console.log(res.data); 
          //console.log(res.data.title);
          console.log(res.data.author_pic);
          setAatall(res.data);
          setStorytitle(res.data.title);
          setInfuencername(res.data.influencer_name);
          setPHOTOS(res.data.galleryImgs);
          setDescriptions(res.data.publicDescription);
          setListingName(res.data.listing_name);
          setAddress(res.data.address);
          setMaplat(res.data.map.lat);
          setMaplng(res.data.map.lng);
          setMapzoom(11);
          setMapcoordinates(res.data.map);
          SetMetatitle(res.data.meta_title);
          SetMetaData(res.data.meta_data);
          setInfluencerpic(res.data.influencer_pic);
          setStoryTitle(res.data.story_title);
          setListingLink(res.data.href);
          setstoryLink(res.data.story_link);
    });   
  }, []);
  

  const windowSize = useWindowSize();

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };
  const handleCloseModal = () => setIsOpen(false);
  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        {/* <div className="flex justify-between items-center">
          <Badge color="pink" name="Specific Tour" />
          <LikeSaveBtns />
        </div> */}

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold text-center">
          {stoty_title}
        </h2>

        {/* 3 */}
        {/* <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> Tokyo, Jappan</span>
          </span>
        </div> */}

        {/* 4 */}
        <div className="flex items-center flex-direction-column">
        <Avatar hasChecked sizeClass="h-28 w-28" radius="rounded-full"  avatarImage={influencer_pic} />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
          Story by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {infuencername}
            </span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />
        <div dangerouslySetInnerHTML={ {__html: descriptions} } />
      </div>
    );
  };
  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">{listingName}</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {address}
          </span>
        </div>

        <div className="icon-name-location">
        <span><i className="las la-home"></i> Accommodation</span>
        <span><i className="las la-hiking text-lg"></i> Places we visited</span>
        </div>
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" /> */}

        {/* MAP */}
        <div className="flex flex-wrap justify-between">
        <div className="aspect-w-5 aspect-h-2 sm:aspect-h-2 w-70-1">
          <div className="rounded-xl overflow-hidden">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyCxywmWV_Bz1FH-NgAgc-wwcVcD0-c8XYQ",
              }}
              defaultZoom={mapzoom}
              zoom={mapzoom}
              yesIWantToUseGoogleMapApiInternals
              defaultCenter={mapcoordinates}
              center={mapcoordinates}
            >
              {/* <LocationMarker lat={maplat} lng={maplng} /> */}
              
              <AnyReactThingsComponent
                  lat={maplat}
                  lng={maplng}
                  icontype="things"
                />
            </GoogleMapReact>
          </div>

           </div>

           <div className="bg-gray box-right-listing-map ml-3 p-4 rounded-2xl w-30-1">
            <h2>{storyTitle}</h2>
            {/* <p>Yarck VIC 3719, Australia</p> */}
            <a href={storyLink}>VIEW STORY</a>
            <h2 className="mt-5 font-size-17">{listingName}</h2>
            <p>{address}</p>
            <a href={listingLink}>VIEW LISTING</a>
           </div>
           </div>
      </div>
    );
  };
  const renderSidebar = () => {
    return (
      <div className="listingSection__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            $19
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /person
            </span>
          </span>
          <StartRating isHost={false} />
        </div>

        {/* FORM */}
        <form className="flex border divide-x divide-neutral-200 dark:divide-neutral-700 border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <div className="flex-1">
            <ExperiencesDateSingleInput
              defaultValue={selectedDate}
              onFocusChange={() => {}}
              onChange={(date) => setSelectedDate(date)}
              anchorDirection={windowSize.width > 1400 ? "left" : "right"}
              fieldClassName="p-5"
            />
          </div>
          <div className="flex-1">
            <GuestsInput
              fieldClassName="p-5"
              defaultValue={{
                guestAdults: 1,
                guestChildren: 2,
                guestInfants: 0,
              }}
            />
          </div>
        </form>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>$19 x 3 adults</span>
            <span>$57</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>$0</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>$199</span>
          </div>
        </div>

        {/* SUBMIT */}
        <ButtonPrimary>Reserve</ButtonPrimary>
      </div>
    );
  };
  return (
    <div
      className={`nc-ListingExperiencesDetailPage  ${className}`}
      data-nc-id="ListingExperiencesDetailPage"
    >
      {/* SINGLE HEADER */}
      <Helmet title={metatitle} meta={metadata}></Helmet>
      <>
        <header className="container  rounded-md sm:rounded-xl">
        <div className="relative z-index-1">
        <GallerySlider
                    uniqueID={`ExperiencesCard-1`}
                    ratioClass={ratioClass}
                    galleryImgs={PHOTOS}
                  />
            

            <div
              className="absolute hidden md:flex md:items-center md:justify-center right-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={PHOTOS}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
        />
      </>
      <main className="container mt-11 flex mb-10 ">
        <div className="w-full  space-y-8 lg:pr-10 lg:space-y-10 ">
          {renderSection1()}
          {renderSection7()}
        </div>
      </main>
    </div>
  );
};

export default ThingsDetailPage;
