import React, { useEffect, useState } from "react";
import LocationInput from "./LocationInput";
import GuestsInput, { GuestsInputProps } from "./GuestsInput";
import { FocusedInputShape } from "react-dates";
import StayDatesRangeInput from "./StayDatesRangeInput";
import ButtonSubmit from "./ButtonSubmit";
import moment from "moment";
import { FC } from "react";
import { convertCompilerOptionsFromJson } from "typescript";

export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface StaySearchFormProps {
  haveDefaultValue?: boolean;
}
let search                = window.location.search;
let params                = new URLSearchParams(search);
let location_search       = params.get('location_search');
let place_id              = params.get('place_id');
let ArriveDate            = params.get('arrive')?params.get('arrive'):"";
let DepartDate            = params.get('depart')?params.get('depart'):"";
let guestValue            = params.get('guest')?params.get('guest'):0;
let childValue            = params.get('child')?params.get('child'):0;

//let newdate               = new Date(ArriveDate).getTime();
// DEFAULT DATA FOR ARCHIVE PAGE
let defaultLocationValue        = location_search?location_search:"";
let defaultLocationPlaceValue   = place_id?place_id:"";

let defaultDateRange = {
  startDate: ArriveDate?moment(ArriveDate):null,
  endDate: DepartDate?moment(DepartDate):null,
};
const defaultGuestValue: GuestsInputProps["defaultValue"] = {
  guestAdults: 0,
  guestChildren:0,
  guestInfants: 0,
};

let defaultGuestValue1 = {guestAdults: Number(guestValue),guestChildren:Number(childValue),guestInfants: 0};

const StaySearchForm: FC<StaySearchFormProps> = ({
  haveDefaultValue = false,
}) => {
  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: null,
    endDate: null,
  });
  const [locationInputValue, setLocationInputValue] = useState("");
  const [locationInputPlaceValue, setlocationInputPlaceValue] = useState("");
  const [guestValue, setGuestValue] = useState({});
  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
    null
  );
  useEffect(() => {
    if (haveDefaultValue) {
      //console.log("AMMIT"+locationInputValue);
      setDateRangeValue(defaultDateRange);
      setLocationInputValue(defaultLocationValue);
      setlocationInputPlaceValue(defaultLocationPlaceValue);
      setGuestValue(defaultGuestValue1);
    }
  }, []);
  //

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) =>{
    event.preventDefault();
    //console.log(details.first_name);
    //console.log(locationInputValue);
    //console.log(locationInputValue);
  }
  
  const renderForm = () => {
    return (
      <form method="GET" action="/search-results/"  className="w-full relative mt-8 flex flex-col md:flex-row md:items-center rounded-3xl  shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-700 md:divide-y-0">
        <LocationInput
          defaultValue={locationInputValue}
          onChange={(e) => setLocationInputValue(e)}
          onInputDone={() => setDateFocused("startDate")}
          defaultPlaceValue={locationInputPlaceValue}
        />
        <StayDatesRangeInput
          defaultValue={dateRangeValue}
          defaultFocus={dateFocused}
          onFocusChange={(focus) => setDateFocused(focus)}
          onChange={(data) => setDateRangeValue(data)}
          availableDates=""
        />
        <GuestsInput
          defaultValue={guestValue}
          onChange={(data) => setGuestValue(data)}
        />
        {/* BUTTON SUBMIT OF FORM */}
        <div className="px-4 py-4 lg:py-0">
          <ButtonSubmit  />
        </div>
      </form>
    );
  };

  return renderForm();
};

export default StaySearchForm;
