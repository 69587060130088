import Label from "components/Label/Label";
import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";

const AccountPass = () => {
  return (
    <div>
      <CommonLayout>
        <div className="space-y-4 sm:space-y-4">
          {/* HEADING */} 
          <div className="card-title-account p-3 pl-6">
              <h2 className="text-3xl font-semibold pb-0 mb-0">Update your password</h2> 
           </div>
           <div className="card-box-account">
          <div className=" space-y-6 p-5">
            <div>
              <Label>Current password</Label>
              <Input type="password" className="mt-1.5" />
            </div>
            <div>
              <Label>New password</Label>
              <Input type="password" className="mt-1.5" />
            </div>
            <div>
              <Label>Confirm password</Label>
              <Input type="password" className="mt-1.5" />
            </div>
            <div className="pt-2">
              <ButtonPrimary>Update password</ButtonPrimary>
            </div>
          </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
