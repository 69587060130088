import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import React, { FC } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
export interface ListingStayPageProps {
  className?: string;
}
const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      
      <BgGlassmorphism />
      
      <div className="container relative overflow-hidden  container-serch-form-full">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage="Stay"
          currentTab="Stay"
          pageHeadingTitle=""
          className="pt-10 pb-0 lg:pb-3 lg:pt-0 "
          listingType={''}
        />

        {/* SECTION */}
        <SectionGridFilterCard className="pb-24 lg:pb-32" />
         
        {/* SECTION 1 */}
        {/*<div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
          />
        </div>*/}

        {/* SECTION */}
        {/* <SectionSubscribe2 className="py-24 lg:py-32" /> */}

        {/* SECTION */}
        {/* <div className="relative py-16 mb-24 lg:mb-32">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}
      </div>
    </div>
  );
};

export default ListingStayPage;
