import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import { StarIcon } from "@heroicons/react/solid";
import React, { FC } from "react";
import Avatar from "shared/Avatar/Avatar";

interface CommentListingDataType {
  name: string;
  avatar?: string;
  date: string;
  comment: string;
  starPoint: number;
}

export interface CommentListingProps {
  className?: string;
  data?: CommentListingDataType;
  hasListingTitle?: boolean;
  keynumber?:number
}

const DEMO_DATA: CommentListingDataType = {
  name: "Cody Fisher",
  date: "May 20, 2021",
  comment:
    "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
  starPoint: 5,
};

const CommentListing: FC<CommentListingProps> = ({
  className = "",
  data = DEMO_DATA,
  hasListingTitle,
  keynumber
}) => {
  return (
    <div className="border-0">
    <div
      className={`nc-CommentListing flex space-x-4 pb-3 ${className}`}
      data-nc-id={`CommentListing${keynumber}` }
    >
     
      <div className="pt-0.5">
        <Avatar
          sizeClass="h-10 w-10 text-lg"
          radius="rounded-full"
          userName={data.name}
          avatarImage={data.avatar}
        />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between space-x-3">
          <div className="flex flex-col">
            <div className="text-sm font-semibold">
              <span>{data.name}</span>
              {/* <div className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5">France, Ittly</div> */}
              {hasListingTitle && (
                <>
                  <span className="text-neutral-500 dark:text-neutral-400 font-normal">
                    {` review in `}
                  </span>
                  <a href="/">The Lounge & Bar</a>
                </>
              )}
            </div>
            
          </div>
          
        </div>
        
      </div>
    </div>

  <div className="flex text-yellow-500 border-0 gap-1">
  <FiveStartIconForRate defaultPoint={data.starPoint} iconClass="w-4 h-4" className="space-x-0.5" />
  <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-0"> {data.date}</span> 
  </div>
  <span className="block mt-1 text-neutral-6000 dark:text-neutral-300 border-0 pb-1  line-clamp-2">
  {data.comment}
  </span>
</div>
  );
};

export default CommentListing;
