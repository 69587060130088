import React, { FC, Fragment,ReactNode,useEffect,useState,useRef } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { HostTestimonialsDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";

export interface HostsTestimonialsCardProps {
  className?: string;
  ratioClass?: string;
  data?: HostTestimonialsDataType;
  size?: "default" | "small";
}
const DEMO_EXPERIENCES_LISTINGS_MEW       = [{id:"",
  hostname: "",
  hostimage: "",
  description:"",
  listing_name: "",
  address: "",
  galleryImgs: [""],
  href:""
}
  ]
const HostsTestimonialsCard: FC<HostsTestimonialsCardProps> = ({
  size = "default",
  className = "",
  data = DEMO_EXPERIENCES_LISTINGS_MEW[0],
  ratioClass = "aspect-w-3 aspect-h-2",
}) => {
  const {
    id,
    hostname,
    hostimage,
    description,
    listing_name,
    address,
    galleryImgs,
    href
  } = data;
  const [isOpenModalReviews, setisOpenModalReviews]                                 = useState(false);

  function openModalReviews() {
    setisOpenModalReviews(true);
  }
  function closeModalReviews() {
    setisOpenModalReviews(false);
  }
  const renderSliderGallery = () => {
    return (
      
      <div className="relative w-full rounded-2xl overflow-hidden">
        <GallerySlider
          uniqueID={`HostsTestimonialsCard-${id}`}
          ratioClass={ratioClass}
          galleryImgs={galleryImgs}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "py-4 space-y-4" : "py-3 space-y-2"}>
        <div className="space-y-2">
        <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-lg"
              }`}
            >
              <span className="line-clamp-1">{listing_name}</span>
            </h2>
          </div>

          
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{address}</span>
          </div>

          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            <span className="text-black">{description} <a className="view-all" onClick={openModalReviews} href="javascript:void(0)">View all</a></span>
          </div>
        </div>
        {/* <div className="border-b border-neutral-100 dark:border-neutral-800"></div> */}
      </div>
    );
  };

  const renderMotalReadMore = () => {
    return (
      <Transition appear show={isOpenModalReviews} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalReviews}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-550 h-10">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Comments
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalReviews} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200 pt-4">
                  {description}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div
      className={`nc-HostsTestimonialsCard group relative border ${className}`}
      data-nc-id="HostsTestimonialsCard"
    >
      <div className="d-flex-content-img">
          <div className="img-user-icon">
            <img src={hostimage}></img>
          </div>
          <div className="text-user-inf">
            <h5><span className="line-clamp-1">Testimonial by </span></h5>
            <p>{hostname}</p>
          </div>
        </div>
        {renderSliderGallery()}
        {renderContent()}
        {renderMotalReadMore()}
    </div>
  );
};

export default HostsTestimonialsCard;
