import React, { FC, ReactNode , useEffect, useState }  from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import StayCard from "components/StayCard/StayCard";
import HeaderFilter from "../PageHome/HeaderFilter";
import axios from 'axios'; 

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);
//
export interface SectionGridSimilarListingProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  lattitude?: string;
  longitude?: string;
  excludeListing?: string;
}

const SectionGridSimilarListing: FC<SectionGridSimilarListingProps> = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Similar Tiny Houses ",
  subHeading = "Hear it from our guests. This is the list of tiny houses that we have specially curated for you, based on reviews from our guests who have stayed in them.",
  headingIsCenter,
  tabs = [],
  lattitude,
  longitude,
  excludeListing
}) => {
  const [feature, setFeature] = useState([]); 
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL+'/GetSimilarListing?lat='+lattitude+"&lng="+longitude+"&guesty_id="+excludeListing)
 	 .then(res => {
        console.log('Loading Featured Listing'); 
        setFeature(res.data); 
    });   
  }, [lattitude,longitude,excludeListing]);

  const renderCard = (stay: StayDataType) => {
    return <StayCard key={stay.id} data={stay} />;
  };
  return (
    <div className="nc-SectionGridSimilarListing relative">
     <HeaderFilter
        tabActive={"New York"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={() => {}}
      />
     <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 ${gridClass}`}
      > 
        {feature && feature.length!=0 ?feature.map((stay) => renderCard(stay)) : ''}
      </div>
      
    </div>
  );
};
export default SectionGridSimilarListing;
