import React, { FC,useEffect,useState } from "react";
import { useParams } from 'react-router-dom';
import { ArrowRightIcon } from "@heroicons/react/outline";
//mport LocationMarker from "components/AnyReactComponent/LocationMarker";
import AnyReactThingsComponent from "components/AnyReactComponent/AnyReactThingsComponent";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import StartRating from "components/StartRating/StartRating";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import { DayPickerSingleDateController } from "react-dates";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import ExperiencesDateSingleInput from "components/HeroSearchForm/ExperiencesDateSingleInput";
import GallerySlider from "components/GallerySlider/GallerySlider";
import axios from 'axios'; 
import { Helmet } from "react-helmet";

export interface ListingExperiencesDetailPageProps {
  className?: string;
  ratioClass?: string;
}


const galleryImgs:string[]=[
  "https://pictures.tinyaway.com/filters:format(jpg)/450x300/Stories/Images/2022/05/124/Tiny-Away-800-011653897785167.jpg",
  "https://pictures.tinyaway.com/filters:format(jpg)/450x300/Stories/Images/2022/05/124/Tiny-Away-800-011653897785167.jpg",
  "https://pictures.tinyaway.com/filters:format(jpg)/450x300/Stories/Images/2022/05/124/Tiny-Away-800-011653897785167.jpg"

];
const includes_demo = [
  { name: "Set Menu Lunch on boat" },
  { name: "Express Bus From Hanoi To Halong and Return" },
  { name: "Mineral Water On Express Bus" },
  { name: "Kayak or Bamboo Boat. Life Jacket." },
  { name: "Halong Bay Entrance Ticket" },
];
console.log(11, moment());

const ListingExperiencesDetailPage: FC<ListingExperiencesDetailPageProps> = ({
  className = "",
  ratioClass = "aspect-w-3 aspect-h-2",
}) => {
  const params                                      = useParams();
  // yuo can find all params from here
  const slug                                        = Object.values(params);
  const [isOpen, setIsOpen]                         = useState(false);
  const [openFocusIndex, setOpenFocusIndex]         = useState(0);
  const [selectedDate, setSelectedDate]             = useState<moment.Moment | null>(
    moment().add(2, "days")
  );
  const [datall, setAatall]                         = useState([]);
  const [stoty_title, setStorytitle]                = useState([]);
  const [infuencername, setInfuencername]           = useState([]);
  const [PHOTOS, setPHOTOS]                         = useState([]);
  const [descriptions, setDescriptions]             = useState("");
  const [listingName, setListingName]               = useState("");
  const [address, setAddress]                       = useState("");
  const [mapcoordinates, setMapcoordinates]         = useState({
    "lat": -37.3218879,
    "lng": 145.3109719
});
  const [maplat, setMaplat]                                   = useState(-37.3218879);
  const [maplng, setMaplng]                                   = useState(145.3109719);
  const [mapzoom, setMapzoom]                                 = useState(6);
  const [thingsWeDid, setThingsWeDid]                         = useState([]);
  const [metatitle, SetMetatitle]                             = useState([]);
  const [metadata, SetMetaData]                               = useState([]);
  const [influencer_pic, setInfluencerpic]                    = useState("");
  const [listingFeaturedImg, setListingFeaturedImg]           = useState("");
  const [listingURL, setListingURL]                           = useState("");
  const [currency_code, SetCurrencycode]             = useState("");
  const [listingPrice, SetListingPrice]             = useState("");
  //const [thingsHref, setThingsHref]                 = useState("");

console.log(process.env.REACT_APP_BASE_URL+'/GetStoriesDetails?id='+slug);
  useEffect(() => {
   
    axios.get(process.env.REACT_APP_BASE_URL+'/GetStoriesDetails?id='+slug)
 	 .then(res => {
          console.log('user effect0000'); 
	      	console.log(res.data); 
          //console.log(res.data.title);
          console.log(res.data.author_pic);
          setAatall(res.data);
          setStorytitle(res.data.title);
          setInfuencername(res.data.influencer_name);
          setPHOTOS(res.data.galleryImgs);
          setDescriptions(res.data.publicDescription);
          setListingName(res.data.listing_name);
          setAddress(res.data.address);
          setMaplat(res.data.map.lat);
          setMaplng(res.data.map.lng);
          setMapzoom(11);
          setMapcoordinates(res.data.map);
          setThingsWeDid(res.data.thins_we_did);
          SetMetatitle(res.data.meta_title);
          SetMetaData(res.data.meta_data);
          setInfluencerpic(res.data.influencer_pic);
          setListingFeaturedImg(res.data.listing_featured_image);
          setListingURL(res.data.href);
          SetListingPrice(res.data.price);
          SetCurrencycode(res.data.currency_code);
          //setThingsHref(res.data.influencer_pic);
    });   
  }, []);
  

  const windowSize = useWindowSize();

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSliderGallery = () => {
    return (
      
      <div className="relative w-full rounded-2xl overflow-hidden">
        <GallerySlider
          uniqueID={`ExperiencesCard-1`}
          ratioClass={ratioClass}
          galleryImgs={galleryImgs}
        />
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className=" !space-y-6">
        {/* 1 */}
        {/* <div className="flex justify-between items-center">
          <Badge color="pink" name="Specific Tour" />
          <LikeSaveBtns />
        </div> */}

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold text-center">
          {stoty_title}
        </h2>

        {/* 3 */}
        {/* <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> Tokyo, Jappan</span>
          </span>
        </div> */}

        {/* 4 */}
        <div className="flex items-center flex-direction-column">
        <Avatar hasChecked sizeClass="h-28 w-28" radius="rounded-full"  avatarImage={influencer_pic} />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
          Story by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {infuencername}
            </span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />
        <div className="story-description" dangerouslySetInnerHTML={ {__html: descriptions} } />
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Experiences descriptions</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <p>
            TRANG AN BOAT TOUR & MUA CAVE CLIMBING TOUR FROM HANOI
            <br />
            <br />
            07:30 – 08:00 – Our guide will meet you at your hotel/stay and start
            a 120km comfortable Limousine bus journey through the verdant
            landscape. Stopover for a rest on the way.
            <br />
            <br />
            BAI DINH PAGODA EXPLORER.
            <br />
            <br />
            10:30 – Arrive Bai Dinh pagoda complex, get on electric cars to
            visit massive architecture.
            <br />
            <br />
            12:15 – Enjoy the buffet lunch at our restaurant, a great place to
            savor the flavours of Vietnamese food.
            <br />
            <br />
            TRANG AN TOUR ON BOAT.
            <br />
            <br />
            13:30 – Visit Trang An Grottoes, get on a rowing boat traveling
            along the river with scenic mountain and green fields landscape.
            <br />
            <br />
            MUA CAVE HIKING. TAKE PICTURE
            <br />
            <br />
            15:45 – Arrive at Mua Cave and start an amazing trek up to the top
            of Ngoa Long mountain.
            <br />
            <br />
            17:30 – 20:00 – Return to our Limousine bus and then come back to
            Hanoi. Drop you off at your hotel/stay. Other things to note
            <br />
            <br />
            It is one full day tour. Start from 07.30 AM and finish at 20.00. We
            just put one hour and default departure time because we have many
            other tours. IF you need any further details
          </p>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Include </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Included in the price
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {includes_demo
            .filter((_, i) => i < 12)
            .map((item) => (
              <div key={item.name} className="flex items-center space-x-3">
                <i className="las la-check-circle text-2xl"></i>
                <span>{item.name}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Availability</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        <div className="listingSection__wrap__DayPickerRangeController flow-root">
          <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
            <DayPickerSingleDateController
              date={selectedDate}
              onDateChange={(date) => setSelectedDate(date)}
              onFocusChange={() => {}}
              focused
              initialVisibleMonth={null}
              numberOfMonths={windowSize.width < 1280 ? 1 : 2}
              daySize={getDaySize()}
              hideKeyboardShortcutsPanel
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              Kevin Francis
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating isHost={false} />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
          accommodation, an outdoor swimming pool, a bar, a shared lounge, a
          garden and barbecue facilities...
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="##">See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">{listingName}</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {address}
          </span>
        </div>

        <div className="icon-name-location">
        <span><i className="las la-home"></i> Accommodation</span>
        <span><i className="las la-hiking text-lg"></i> Places we visited</span>
        </div>
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" /> */}

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <div className="rounded-xl overflow-hidden">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyCxywmWV_Bz1FH-NgAgc-wwcVcD0-c8XYQ",
              }}
              defaultZoom={mapzoom}
              zoom={mapzoom}
              yesIWantToUseGoogleMapApiInternals
              defaultCenter={mapcoordinates}
              center={mapcoordinates}
            >
              {/* <LocationMarker lat={maplat} lng={maplng} /> */}
              
              <AnyReactThingsComponent
                  lat={maplat}
                  lng={maplng}
                  icontype="home"
                />
              {thingsWeDid.map((items) => (
                 <AnyReactThingsComponent
                 key={items['id']}
                  lat={items['map']['lat']}
                  lng={items['map']['lng']}
                  listing={items}
                  icontype="things"
                />
              ))}
            </GoogleMapReact>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap Things_to_know">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold text-center text-lg">THINGS WE DID</h2>
        {thingsWeDid.map((itemDetails) => (
            <div className={`flex-right ${itemDetails['classes']}`}>
             
                
                <div className="sld-Things">
                <div className="relative w-full rounded-2xl overflow-hidden">
                  <GallerySlider
                    uniqueID={`ExperiencesCard-${itemDetails['id']}`}
                    ratioClass={ratioClass}
                    galleryImgs={itemDetails['ThingsgalleryImgs']}
                  />
                </div>
                </div>
                <div className="text-Things">
                  <div>
                  <h4><a href={itemDetails['things_href']}>{itemDetails['title']}</a></h4>
                  <div>
                    <span className="flex items-center mb-2">
                     <span className="icon-hiking">
                      <i className="las la-hiking text-lg"></i> </span>
                     <span>{itemDetails['address']}</span> 
                    </span>
                  </div>
                  <div className="flex flex-wrap" dangerouslySetInnerHTML={ {__html: itemDetails['description']} } />
                </div>
                {/* things to do  START*/}
                <div className="icons-thins-to-know">
                {[...itemDetails['categories']].map((catitems:any) => (
                    <div className={`list-icon-all ${catitems}`}>
                        <span className="icon-ms-thins">
                          <i className={`icon-${catitems}`}></i>
                        </span>
                        <span>
                            <h5>{catitems}</h5>
                        </span>
                    </div>
                ))}
                </div>
                {/* things to do  END*/}
                </div>
            </div>
        ))}
        

        
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" /> */}



        {/* CONTENT */}
        {/* <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Any experience can be canceled and fully refunded within 24 hours of
            purchase, or at least 7 days before the experience starts.
          </span>
        </div> */}


        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" /> */}

        {/* CONTENT */}
        {/* <div>
          <h4 className="text-lg font-semibold">Guest requirements</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Up to 10 guests ages 4 and up can attend. Parents may also bring
            children under 2 years of age.
          </span>
        </div> */}
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" /> */}

        {/* CONTENT */}
        {/* <div>
          <h4 className="text-lg font-semibold">What to bring</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Formal Wear To Visit Bai Dinh Pagoda Be ready before 7.30 Am.
              </li>
              <li>We will pick up from 07.30 to 08.00 AM</li>
            </ul>
          </div>
        </div> */}
      </div>
    );
  };



  const renderSidebar = () => {
    return (
      <div className="listingSection__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            $19
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /person
            </span>
          </span>
          <StartRating isHost={false} />
        </div>

        {/* FORM */}
        <form className="flex border divide-x divide-neutral-200 dark:divide-neutral-700 border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <div className="flex-1">
            <ExperiencesDateSingleInput
              defaultValue={selectedDate}
              onFocusChange={() => {}}
              onChange={(date) => setSelectedDate(date)}
              anchorDirection={windowSize.width > 1400 ? "left" : "right"}
              fieldClassName="p-5"
            />
          </div>
          <div className="flex-1">
            <GuestsInput
              fieldClassName="p-5"
              defaultValue={{
                guestAdults: 1,
                guestChildren: 2,
                guestInfants: 0,
              }}
            />
          </div>
        </form>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>$19 x 3 adults</span>
            <span>$57</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>$0</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>$199</span>
          </div>
        </div>

        {/* SUBMIT */}
        <ButtonPrimary>Reserve</ButtonPrimary>
      </div>
    );
  };

  return (
    <div
      className={`nc-ListingExperiencesDetailPage  ${className}`}
      data-nc-id="ListingExperiencesDetailPage"
    >
      {/* SINGLE HEADER */}
      <Helmet title={metatitle} meta={metadata}>
      </Helmet>
      <>
        <header className="container  rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2 sm-full-1-img">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={PHOTOS[0]}
                prevImageHorizontal
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 3 ? "hidden sm:block" : ""
                }`}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-6 sm:aspect-w-6 sm:aspect-h-4"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item || ""}
                  prevImageHorizontal
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 1)}
                />
              </div>
            ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center right-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={PHOTOS}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
        />
      </>

      {/* MAIn */}
      <main className="container mt-11 flex ">
        {/* CONTENT */}
        <div className="w-full  space-y-8 lg:pr-10 lg:space-y-10">
        
          {renderSection1()}
          {/* {renderSection2()} */}
          {/*renderSection3()*/}
          {/*renderSectionCheckIndate()*/}
          {/*renderSection5()*/}
          {/*renderSection6()*/}
          {renderSection7()}
          {renderSection8()}
          <div className="fixd_bottem_section">
     <div className="container">
    <div className="row_fixd">
        <div className="img_left_flex">
        <NcImage src={listingFeaturedImg} /></div>
         <div className="title_left_flex">
             <h4>{listingName}</h4>
             <div className="DesktopBookingBar">
                 <span className="ret_fixd">
                                          <sup>{currency_code}</sup>{listingPrice}/night                                      </span>
                 <span className="bg_icon">
             <svg viewBox="0 0 18 30" xmlns="http://www.w3.org/2000/svg">
                 <path d="M17.778 10.556h-8.89l5-10.556H3.334L0 15h7.222L.556 30z" fill="currentColor" fill-rule="nonzero"></path></svg>
            Instant Book</span>
            </div>
            
         </div>
          <div className="icon_btn_left_flex">
              <a href={listingURL} target={"_blank"}><ButtonPrimary type="submit">Book Now</ButtonPrimary></a>
              <a href="https://giftcards.tinyaway.com/" target={"_blank"} className="gift_fixd_icon ml-5">
                <svg viewBox="0 0 30 27"><g fill-rule="nonzero" fill="currentColor"><path d="M3.215 15.002v10.104c0 .93.802 1.684 1.786 1.684h8.93V15.002H3.215zM28.13 6.722h-5.934a7.204 7.204 0 0 0 1.046-.755c1.514-1.37 1.514-3.598 0-4.968-1.47-1.334-4.031-1.33-5.5 0-.814.735-2.97 3.725-2.672 5.723h-.135c.296-1.998-1.86-4.988-2.672-5.723-1.47-1.33-4.032-1.33-5.5 0C5.25 2.37 5.25 4.598 6.76 5.967a7.34 7.34 0 0 0 1.049.755H1.875C.842 6.722 0 7.48 0 8.41v2.533c0 .466.42.845.938.845h12.19V8.41h3.75v3.378h12.19c.517 0 .937-.379.937-.845V8.41c0-.93-.84-1.688-1.875-1.688zm-15.114-.056s-.078.056-.346.056c-1.296 0-3.77-1.213-4.578-1.945-.786-.713-.786-1.875 0-2.587.38-.345.885-.534 1.421-.534.535 0 1.04.19 1.42.534 1.264 1.145 2.52 4.07 2.083 4.476zm4.317.056c-.266 0-.345-.054-.345-.056-.437-.407.82-3.331 2.084-4.476.756-.688 2.078-.691 2.84 0 .789.712.789 1.874 0 2.587-.807.732-3.28 1.945-4.579 1.945zM16.074 15.002V26.79h8.93c.986 0 1.786-.754 1.786-1.684V15.002H16.074z"></path></g></svg>
              </a>
          </div>
    </div> 
    </div>
</div>
        </div>

        {/* SIDEBAR */}
        {/*<div className="hidden lg:block flex-grow">
          <div className="sticky top-24">{renderSidebar()}</div>
        </div>*/}
      </main>

      {/* STICKY FOOTER MOBILE */}
        {/* <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
          <div className="container flex items-center justify-between">
            <span className="text-2xl font-semibold">
              $311
              <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                /person
              </span>
            </span>

            <ButtonPrimary href="##">Reserve</ButtonPrimary>
          </div>
        </div> */}

      {/* OTHER SECTION */}
      {/* <div className="container py-24 lg:py-32"> */}
        {/* SECTION 1 */}
        {/*<div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
          />
        </div> */}

        {/* SECTION */}
        {/*<SectionSubscribe2 className="pt-24 lg:pt-32" />*/}
      {/* </div> */}
    </div>
  );
};

export default ListingExperiencesDetailPage;
