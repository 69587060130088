import rightImg from "images/about-hero-right.png";
import videoplayback from "images/videoplayback.mp4";
import React, { FC, Fragment,useState,useEffect,useRef } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionBecomeHostVideo from "./SectionBecomeHostVideo";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Selectss from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { TaxonomyType } from "data/types";
import SectionLandHostCommunity from "components/SectionLandHostCommunity/SectionLandHostCommunity";
import axios from 'axios'; 
import Select from "react-select";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import Switch from "react-switch"

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "https://www.bigtiny.com.sg/tinynews",
    name: "Mr. Fernando, Sithuri Tiny House",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/IMG_0434-1-2048x1365.jpg",
  },
  {
    id: "2",
    href: "https://www.bigtiny.com.sg/tinynews",
    name: "Ms. Helen, Saddle Camp",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/saddlecamp-24.jpg",
  },
  {
    id: "3",
    href: "https://www.bigtiny.com.sg/tinynews",
    name: "Mr. Taylan, Seven Hills",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/taylan1.jpg",
  },
];
export interface PageBecomeHostProps {
  className?: string;
  autoFocus?: boolean;
}
const PageBecomeHost: FC<PageBecomeHostProps> = ({ 
  className = "",
  autoFocus = false 
}) => {
const [details,setDetails]                                  = useState({name:"",
                                                            email:"",
                                                            phone:"",
                                                            country:"",
                                                            state:"",
                                                            address:"",
                                                            zip_code:"",
                                                            message:""});
const [showPopover, setShowPopover]                         = useState(autoFocus);
const containerRef                                          = useRef<HTMLDivElement>(null);
const inputRef                                              = useRef<HTMLInputElement>(null);
const NameRef                                               = useRef<HTMLInputElement>(null);
const EmailRef                                              = useRef<HTMLInputElement>(null);
const CountryRef                                            = useRef<HTMLInputElement>(null);
const StateRef                                              = useRef<HTMLInputElement>(null);
const AddressRef                                            = useRef<HTMLInputElement>(null);
const [errorMsg, setErrorMsg]                               = useState('');
const [successMsg, setSuccessMsg]                           = useState('');
const [selectedOption, setSelectedOption]                   = useState();
const [selectedStateOption, setSelectedStateOption]         = useState([{ value: "id", label: "" }]);
const [selectedCountry, setSelectedCountry]                 = useState([{ value: "id", label: "" }]);
const [value, setValue]                                     = useState("");
const [optionList, setOptionList]                           = useState([{ value: "id", label: "Red1" }]);
const [optionStateList, setOptionStateList]                           = useState([]);
useEffect(() => {
  axios.get(process.env.REACT_APP_BASE_URL + 'getCountryList')
    .then(res => {
      setOptionList(res.data.response);
    })
}, [selectedOption]);
useEffect(() => {
  axios.get(process.env.REACT_APP_BASE_URL + 'getStateList?country_id='+selectedCountry)
    .then(res => {
      setOptionStateList(res.data.response);
      setSelectedStateOption([{ value: "id", label: "" }]);
    })
}, [selectedOption]);

const submitHandler = (event: React.FormEvent<HTMLFormElement>) =>{
event.preventDefault();
details.phone = value;

const isInvalid = validateInput();

if (!isInvalid) {
    axios.post(process.env.REACT_APP_BASE_URL+'/host_enquiries?type=normal', details)
  .then(function (response) {
    console.log(response.data.response);
    if(response.data.status==200){
      setSuccessMsg(response.data.response);
      setDetails({name:"",
      email:"",
      phone:"",
      country:"",
      state:"",
      address:"",
      zip_code:"",
      message:""});
    }else{
      setErrorMsg(response.data.response);
    }
  })
  .catch(function (error) {
    console.log(error);
  });
  } else {
    setSuccessMsg('');
  }
};
useEffect(() => {
if (showPopover && inputRef.current) {
  inputRef.current.focus();
}
}, [showPopover]);
const validateInput = () => {
const fields = [
  {
    name: 'name',
    value: details.name,
    message: 'Name should not be blank.'
  },
  {
    name: 'email',
    value: details.email,
    message: 'Email should not be blank.'
  },
  {
    name: 'country',
    value: details.country,
    message: 'County should not be blank.'
  },
  {
    name: 'state',
    value: details.state,
    message: 'State should not be blank.'
  },
  {
    name: 'phone',
    value: details.phone,
    message: 'Phone should not be blank.'
  },
  {
    name: 'address',
    value: details.address,
    message: 'Address should not be blank.'
  },
  {
    name: 'zip_code',
    value: details.zip_code,
    message: 'Zip code should not be blank.'
  }
]
const isNotFilled = fields.some(field => {
  if (field.value.trim() === '') {
    setErrorMsg(field.message);
    return true;
  }
  setErrorMsg('');
  return false;
});
return isNotFilled;
};

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter",
  },
  {
    title: "💌 EMAIL",
    desc: "nc.example@example.com",
  },
  {
    title: "☎ PHONE",
    desc: "000-123-456-7890",
  },
];
function handleSelect(data:any) {
  setSelectedOption(data);
  if(data?.value!='' && data?.value!=undefined){
    setSelectedCountry(data?.value);
    setDetails({...details,country:data?.value});
  }else{
    setDetails({...details,country:""});
  }
}
function handleStateSelect(data:any) {
  setSelectedStateOption(data);
  if(data?.value!='' && data?.value!=undefined){
    setSelectedCountry(data?.value);
    setDetails({...details,state:data?.value});
  }else{
    setDetails({...details,state:""});
  }
}
  return (
    <div className={`nc-PageBecomeHost overflow-hidden relative ${className}`}  data-nc-id="PageBecomeHost">
      <Helmet title={'Become a Host - Tiny Away | Land Partners for Tiny Houses | Host a Tiny'}></Helmet>
      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      {window.location.hostname=='tinyaway.com.tw' || window.location.hostname=='www.tinyaway.com.tw' ?
      <>

      <div className="top-image-section">
      <div className="z-10 w-full relative">
        <img className="w-full" src="https://cdn.tinyaway.com/common/2024/03/banner-11709725030736.jpg" alt=""></img>
        <h4><div><span>您在台灣有風景優美的土地嗎</span><br/>
        讓閒置土地成為秘境景點<br/>
        創造專屬於您的獨特價值 </div></h4>
        </div>
      
      </div>
      <div className="clear"></div>

      <div className="middle-section-host middle-section-host-900">
        <div className="container">
        <h2>遍佈世界各地的合作夥伴</h2>
<div className="p-b-0">
                <p>Tiny Away 以環保永續且便利舒適的移動微型屋為核心，<br/>
                與擁有獨特景色或文化的土地持有者合作，<br/> 
                創造了全新、顛覆當前主流旅遊的自然住宿旅遊體驗。<br/>
                </p>

                <p>我們希望透過位於自然中的暫留，<br/>
                一方面深入自然，享受與自然共處並再次尋回自己，<br/>
                一方面遠離喧囂，擺脫繁忙、快速的科技生活，在廣闊的自然中舒緩下來。<br/>

                </p>

                <p>目前Tiny Away光在澳洲與紐西蘭已有 300多間的移動微型屋。<br/>
                而現在正在往歐洲、台灣甚至是日本，積極地尋找更多的移動微型屋的合作夥伴。<br/>
                </p>

                <p>我們相信每塊土地都是獨一無二的，<br/>
                尤其是台灣多變的自然環境以及最著名的文化 – 人，都是讓每個移動微型屋與眾不同的原因。<br/>
                Tiny Away誠摯地邀請您加入我們，讓更多人走進自然，用現代的住宿回到原始的環境。<br/>
                </p>

                <p>若您想讓更多人一起見證您擁有的美景，請留下您的聯絡資訊，<br/>
                我們將提供您如何成為Tiny Away合作夥伴的完整資訊，其中包括土地利用相關諮詢、移動微型屋的使用與管理，<br/>
                當然還有最重要的，您如何透過您的土地與移動微型屋創造更多收益。<br/>
                </p>

                <p> 您有大自然的餽贈與獨一無二的人文特色，<br/>
                我們有現代設備齊全、舒適便利的移動微型屋，<br/>
                一起創造全新的旅遊體驗吧！</p>

                </div>

        <ButtonPrimary  className="button-host" type="submit">了解更多</ButtonPrimary>

        </div>
         
      </div>

      <div className="middle-section-host">
        <div className="container">
        <h2>台灣電視台採訪</h2>
        <div className="video-tw">
          <iframe width="100%" height="600px" src="https://www.youtube.com/embed/r-XNt1SJtP4" title="在台灣，擁有一間無限可能的移動微型屋"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        </div>
        </div>
         
      </div>

       <div className="flex-list-number-content">
        <div className="container">
          <div className="list-text">
            <div className="number-listing">
              <span>01</span>
            </div>
            
            <div className="right-content">
              <h4>無需高額的初始資金</h4>
              <p>當確認條件合適，前期合作時您無需投入任何資金。我們將會全權負責移動微型屋的建造、裝潢與設備佈置，且會將移動微型屋以在不破壞土地的前提下安置在您指定的地點上。而您僅需協助住客入住與介紹當地環境，歡迎他們來到您最美的風景區。</p>
            </div>
          </div>
          <div className="list-text">
            <div className="number-listing">
              <span>02</span>
            </div>
            <div className="right-content">
              <h4>經營專屬於您的特色民宿或成為輕鬆愜意的包租公/包租婆</h4>
              <p>成為Tiny Away的合作夥伴，一定是最無憂的選擇。您可以選擇成為移動微型屋的經營者，創造專屬於您的旅遊體驗來吸引更多遊客前來，得到更多的分潤收入．也可以單純的提供一方水土，剩下的就全部交給我們！</p>
            </div>
          </div>
          <div className="list-text">
            <div className="number-listing">
              <span>03</span>
            </div>
            <div className="right-content">
              <h4>認識來自全球各地的新朋友</h4>
              <p>讀萬卷書不如行萬里路，行萬里路不如與萬人處。如果您喜歡交朋友，喜歡與來自各界各地的朋友暢談，以文化碰撞出不同的火花，與Tiny Away合作將是您非常難得的機會。您將會是我們與旅客聯繫的關鍵，協助旅客入住與享受一趟難得的旅遊體驗。</p>
            </div>
          </div>
          <div className="list-text">
            <div className="number-listing">
              <span>04</span>
            </div>
            <div className="right-content">
              <h4>您將會是最輕鬆的民宿主人</h4>
              <p>我們擁有成熟的經營模式、完善的運營系統以及遍布全球的行銷資源。成為我們的合作夥伴後，我們將處理所有行銷、訂房以及後續的客戶服務。您將專心接待每一組來到移動微型屋的旅客，讓我們一起為旅客再各階段都有最好的服務體驗。</p>
            </div>
          </div>
          <div className="list-text">
            <div className="number-listing">
              <span>05</span>
            </div>
            <div className="right-content">
              <h4>收益分潤</h4>
              <p>成為Tiny Away後，您經營的移動微型屋將會是額外的收入來源，如果您原本就已經有在經營的話，這將會是您額外且獨特的高附加價值服務。當然最重要的還是您將原本毫無收益、僅有美景的地，轉變成為高價值的生活模式！</p>
            </div>
          </div>
          </div>
          </div>

        <div className="tw-comment">
          <div className="container">
            <h2>澳洲的知名合作案例</h2>
          <div className="outer-box-comment">
            <div className="comment-box">
            <span>“</span>
            <div>
              <h3>
              
              有床、有毯子，有盤子根杯子，甚至還有戶外桌椅套組。所有東西都裝在箱子中，在開箱時就好像在開聖誕禮物一樣令人期待！<br/>
這真的是一個影響非常小的額外收入好選擇！
</h3>
              
            <p>Greg Rathbun and Tann Duangprasit 
              <i>Mushroom Farm and Avocado Orchard Operator in New Zealand</i></p>
</div>
             
            </div>
            <div className="comment-box">
            <span>“</span>
            <div>
              <h3>
              如果你想要有額外的收入，<br/>
Tiny Away真的是一個非常棒的選擇！

              </h3>
            <p>Sonia Horan 
              <i>Owner of Bedervale, NSW</i></p>
              </div>
             
            </div>
            <div className="comment-box">
            <span>“</span>
            <div>
              <h3>如果你像我一樣，在經營一個不大不小的農場，你真的需要另一個賺錢的方式…<br/>
                  旅遊就是一個非常有建設性的選擇！
                  </h3>
            <p>Sarah and Daniel Ajzner
            <i>Macedon Rages Dairy Goat Farmers</i></p>
            </div>
             
            </div>
            </div>
          </div>
        </div>

        <div className="tw-comment">
          <div className="container">
            <div className="video-host-tw">
           <iframe width="50%" height="300px" src="https://www.youtube.com/embed/HD0loiLt2m0" title="Curious City S2 小岛国 大发现 S2 EP9"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
          <iframe width="50%" height="300px" src="https://www.youtube.com/embed/r-XNt1SJtP4" title="在台灣，擁有一間無限可能的移動微型屋"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        </div>
          </div>
        </div>

        <div className="footer-host">
          <div className="container">
            <div className="conatct-form-host">
              <h2>了解經營<br/>
                移動微型屋的收益
                </h2>
              <div className="form-group">
                <input type="text" placeholder="姓名*"></input>
              </div>
              <div className="form-group">
                <input type="text" placeholder="電子郵件*"></input>
              </div>
              <div className="form-group">
                <input type="text" placeholder="電話*"></input>
              </div>
              <div className="form-group">
                <input type="text" placeholder="土地位置（縣市／行政區*"></input>
              </div>
              <div className="form-group">
                <select>
                  <option>是否能接水電？*</option>
                  <option>是</option>
                  <option>否</option>
                  
                </select>
              </div>
              <div className="form-group">
              <select>
                  <option>是否有民宿經營執照？*</option>
                
                </select>
              </div>
              <div className="form-group">
              <select>
                  <option>是否需要土地整理協助？*</option>
                
                </select>
              </div>
              <ButtonPrimary  className="button-host" type="submit">提交</ButtonPrimary> 
            </div>
        </div>
        </div>
      </>
      :
      <>
      <div className="py-16 lg:py-18 space-y-16 lg:space-y-28 pt-0">
      {/* <SectionBecomeHostVideo /> */}
     <img src="https://cdn.tinyaway.com/common/20201013_Piccolino-full_05-2048x1366.jpg"></img>
     </div>
      <div className="container py-16 lg:py-18 space-y-16 lg:space-y-28 pt-0">
        <div className="our-tiny becomehost-content pl-50 pr-50">
          <div className="meet-our-sled">
            <SectionLandHostCommunity
                heading="MEET OUR 
                LAND HOSTS COMMUNITY"
                subHeading=""
                className="hedding-center"
                categoryCardType="card8"
                categories={DEMO_CATS}
                itemPerRow={3}
              />
          </div>
          <div className="content-becomehost">
          <h3 className="mt-70">DO YOU HAVE SPARE LAND</h3>
          <p className="pmb-20">Tiny Away works towards disrupting the current tourism accommodation – in an eco-friendly, sustainable and highly efficient kind of way. We are working hard to reconnect people with nature and curate the perfect escape from a hectic and digitally-laden lifestyle.</p>
          <p className="pmb-20">We are expanding. We are looking for hosts to our tiny houses. Every property is unique and we want people to experience the magic of yours. Join us in the journey to inspire people to get out of the city and enjoy fulfilling moments in nature.</p>
          <p className="pmb-20">Get in touch with us and receive an information pack on hosting a tiny house. We will also let you know how much you can earn from hosting. No obligations.</p>
          <h3 className="mt-70 text-left mb-2">Channel 7 Interview on Hosting a Tiny House</h3>
          <iframe width="100%" height="642" src="https://www.youtube.com/embed/G1dbehZk2g8" title="Tiny House Host Interview Series - Chatting with Helen, Saddle Camp at Braidwood"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
          </div>
          <div className="flex-list-number-content">
          <div className="list-text">
            <div className="number-listing">
              <span>01</span>
            </div>
            <div className="right-content">
              <h4>NO CAPITAL REQUIRED</h4>
              <p>You do not need to put in any upfront capital to start this. To reinforce a sense of escapism, we will provide you with a fully furnished tiny house, with each one blending seamlessly into its natural surroundings and offering a local connection to the guest.</p>
            </div>
          </div>
          <div className="list-text">
            <div className="number-listing">
              <span>02</span>
            </div>
            <div className="right-content">
              <h4>FLEXIBILITY</h4>
              <p>As our tiny house host, you have the option to receive a higher income by providing utilities (water, gas and electricity), and help to keep the house clean. You also have the flexibility to simply provide the land and we will do the rest.</p>
            </div>
          </div>
          <div className="list-text">
            <div className="number-listing">
              <span>03</span>
            </div>
            <div className="right-content">
              <h4>MAKE NEW FRIENDS</h4>
              <p>If you like meeting new friends from all over the world, this is perfect for you. As our tiny house hosts, you will be our key point of contact with guests and help ensure that they have an awesome stay.</p>
            </div>
          </div>
          <div className="list-text">
            <div className="number-listing">
              <span>04</span>
            </div>
            <div className="right-content">
              <h4>BE YOUR BEST HOST</h4>
              <p>You’ve got the property and we’ve got the tools to help you host with confidence. We handle all the marketing, bookings and customer service. Together we curate a memorable experience at all stages of the guest journey.</p>
            </div>
          </div>
          <div className="list-text">
            <div className="number-listing">
              <span>05</span>
            </div>
            <div className="right-content">
              <h4>EARN RENTAL INCOME</h4>
              <p>Best of all, you will get to earn from the rental of the tiny house while inspiring people to get out of the city and reconnect with nature. If you are running a business on the property, it complements and bring you more customers.</p>
            </div>
          </div>
          </div>
          <div className="form-content-left">
          <h5 className="mt-100">Find out how much you can earn from hosting</h5>
          {successMsg && 
          <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
          {successMsg}
          </div>}
          {errorMsg && 
          <div className="alert alert-warning" role="warning alert"><span className="las la-exclamation-circle"></span>
          {errorMsg}</div>}
          <form className="grid grid-cols-2 gap-6" action="#" onSubmit={submitHandler} autoComplete="off" method="post">
              <label className="block">
                <Label>Name <span className="red">*</span></Label>
                <Input
                 type="text"
                 placeholder=""
                 name="name"
                 id="name"
                 onChange={event=>setDetails({...details,name:event.target.value})}
                 ref={NameRef}
                 autoComplete="off"
                 autoFocus={showPopover}
                 value={details.name}
                />
              </label>
              <label className="block">
                <Label>Email <span className="red">*</span></Label>
                <Input
                  type="email"
                  placeholder=""
                  name="email"
                  id="email"
                  onChange={event=>setDetails({...details,email:event.target.value})}
                  ref={EmailRef}
                  autoComplete="off"
                  autoFocus={showPopover}
                  value={details.email}
                />
              </label>
              <label className="block">
                <Label>Country  <span className="red">*</span></Label>
                <Select
                defaultValue={selectedOption}
                onChange={handleSelect}
                placeholder="Choose country"
                options={optionList}
                isSearchable={true}
                isClearable
                id="country"
                name="country"
              />
              </label>
              <label className="block">
                <Label>State/ Region<span className="red">*</span></Label>
                <Select
                  defaultValue={selectedStateOption}
                  onChange={handleStateSelect}
                  placeholder="Choose State"
                  options={optionStateList}
                  isSearchable={true}
                  isClearable
                  id="state"
                  name="state"
                />
              </label>
              <label className="block">
                <Label>Phone</Label>
                <PhoneInput
                  placeholder="Enter phone number"
                  international
                  value={details.phone}
                  defaultCountry="AU"
                  onChange={setValue}
                  countryCallingCodeEditable={false}
                  phoneDigits={8}
                  name="phone"
                  id="phone"
                  error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                />
              </label>
              <label className="block">
                <Label>Address<span className="red">*</span></Label>
                <Input
                  type="text"
                  placeholder=""
                  name="address"
                  id="address"
                  onChange={event=>setDetails({...details,address:event.target.value})}
                  ref={AddressRef}
                  autoComplete="off"
                  autoFocus={showPopover}
                  value={details.address}
                />
              </label>
              <label className="block">
                <Label>Postal Code<span className="red">*</span></Label>
                <Input
                  type="text"
                  placeholder=""
                  name="zip_code"
                  id="zip_code"
                  onChange={event=>setDetails({...details,zip_code:event.target.value})}
                  ref={AddressRef}
                  autoComplete="off"
                  autoFocus={showPopover}
                  value={details.zip_code}
                />
              </label>
              <label className="block">
                <Label>Message</Label>
                <Textarea 
                  placeholder=""
                  name="message"
                  id="message"
                  onChange={event=>setDetails({...details,message:event.target.value})}
                  autoComplete="off"
                  autoFocus={showPopover}
                  value={details.message} rows={6} />
              </label>
              
              <div></div>
              <div>
                <ButtonPrimary type="submit">Subscribe</ButtonPrimary>
              </div>
          </form>
          </div>
          <h3 className="mt-70 text-left mb-2"> Channel 7 Interview on Hosting a Tiny House</h3>
          <iframe width="100%" height="642" src="https://www.youtube.com/embed/usnixd-5wPM" title="Channel 7 Interview with Tiny Away"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
          </div>
      </div>
      </>
      }
      
    </div>
  );
};
export default PageBecomeHost;
