import rightImg from "images/about-hero-right.png";
import videoplayback from "images/videoplayback.mp4";
import React, { FC, Fragment,useState,useEffect,useRef } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionBecomeHostVideo from "./SectionBecomeHostVideo";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { TaxonomyType } from "data/types";
import SectionLandHostCommunity from "components/SectionLandHostCommunity/SectionLandHostCommunity";
import axios from 'axios';  

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "https://www.bigtiny.com.sg/tinynews",
    name: "Mr. Fernando, Sithuri Tiny House",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/IMG_0434-1-2048x1365.jpg",
  },
  {
    id: "2",
    href: "https://www.bigtiny.com.sg/tinynews",
    name: "Ms. Helen, Saddle Camp",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/saddlecamp-24.jpg",
  },
  {
    id: "3",
    href: "https://www.bigtiny.com.sg/tinynews",
    name: "Mr. Taylan, Seven Hills",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/taylan1.jpg",
  },
  
  
  
];

export interface PageBecomeHostJapanProps {
  className?: string;
  autoFocus?: boolean;
}


const PageBecomeHostJapan: FC<PageBecomeHostJapanProps> = ({ 
  className = "",
  autoFocus = false 
}) => {

  const [details,setDetails]                                  = useState({name:"",
                                                          email:"",
                                                          phone:"",
                                                          country:"",
                                                          state:"",
                                                          address:"",
                                                          message:""});
const [showPopover, setShowPopover]                         = useState(autoFocus);
const containerRef                                          = useRef<HTMLDivElement>(null);
const inputRef                                              = useRef<HTMLInputElement>(null);
const NameRef                                               = useRef<HTMLInputElement>(null);
const EmailRef                                              = useRef<HTMLInputElement>(null);
const CountryRef                                            = useRef<HTMLInputElement>(null);
const StateRef                                              = useRef<HTMLInputElement>(null);
const AddressRef                                            = useRef<HTMLInputElement>(null);
const [errorMsg, setErrorMsg]                               = useState('');
const [successMsg, setSuccessMsg]                           = useState('');

const submitHandler = (event: React.FormEvent<HTMLFormElement>) =>{
event.preventDefault();
console.log(details);
const isInvalid = validateInput();
if (!isInvalid) {
  axios.post(process.env.REACT_APP_BASE_URL+'/host_enquiries?type=japan', details)
.then(function (response) {
  console.log(response.data.response);
  if(response.data.status==200){
    setSuccessMsg(response.data.response);
    setDetails({name:"",
    email:"",
    phone:"",
    country:"",
    state:"",
    address:"",
    message:""});
  }else{
    setErrorMsg(response.data.response);
  }
})
.catch(function (error) {
  console.log(error);
});
} else {
  setSuccessMsg('');
}
};
useEffect(() => {
if (showPopover && inputRef.current) {
inputRef.current.focus();
}
}, [showPopover]);
const validateInput = () => {
const fields = [
{
  name: 'name',
  value: details.name,
  message: 'Name should not be blank.'
},
{
  name: 'email',
  value: details.email,
  message: 'Email should not be blank.'
},
{
  name: 'country',
  value: details.country,
  message: 'County should not be blank.'
},
{
  name: 'state',
  value: details.state,
  message: 'Regions should not be blank.'
},
{
  name: 'address',
  value: details.address,
  message: 'Address should not be blank.'
}
]
const isNotFilled = fields.some(field => {
if (field.value.trim() === '') {
  setErrorMsg(field.message);
  return true;
}
setErrorMsg('');
return false;
});
return isNotFilled;
};

  const info = [
    {
      title: "🗺 ADDRESS",
      desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter",
    },
    {
      title: "💌 EMAIL",
      desc: "nc.example@example.com",
    },
    {
      title: "☎ PHONE",
      desc: "000-123-456-7890",
    },
  ];

  return (
    <div
      className={`nc-PageBecomeHostJapan overflow-hidden relative ${className}`}
      data-nc-id="PageBecomeHostJapan"
    >
      <Helmet title={'Become a Host - Tiny Away | Land Partners for Tiny Houses | Host a Tiny'}></Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="py-16 lg:py-18 space-y-16 lg:space-y-28 pt-0">
      {/* <SectionBecomeHostVideo /> */}
     <img src="https://cdn.tinyaway.com/common/Taiwan.jpg.webp"></img>
     </div>
     <div className="container py-16 lg:py-18 space-y-16 lg:space-y-28 pt-0">
      <div className="our-tiny becomehost-content pl-50 pr-50">
           
           <div className="meet-our-sled">
            {/* <h4 className="mt-50">MEET OUR</h4>
            <h4 className="mb-50">LAND HOSTS COMMUNITY</h4> */}

            <SectionLandHostCommunity
                heading="我們在澳大利亞土地
                的合作夥伴"
                subHeading=""
                className="hedding-center"
                categoryCardType="card8"
                categories={DEMO_CATS}
                itemPerRow={3}
              />
              
           </div>

          <div className="content-becomehost">
          <h3 className="mt-70">您在台灣有風景優美的土地嗎？</h3>
          <p className="pmb-20">Tiny Away 致力於以環保、可持續和高效的方式顛覆當前的旅遊體驗式住宿。 </p>
          <p className="pmb-20">我們正在努力將人們與自然重新聯繫起來，並策劃一個完美的逃離忙碌和充滿數字化的生活方式。 </p>
          <p className="mt-11">我們現在在澳大利亞和新西蘭有 150 多間移動小房屋。我們正在向台灣擴張。我們正在為我們的移動小房屋尋找合作夥伴。 </p>
          <p className="pmb-20">每個合作夥伴的土地都是獨一無二的，各有獨特的風各。加入我們的概念，激勵人們走出城市，享受大自然中的充實時刻。</p>
          <p className="mt-11">馬上與我們聯繫，並獲得有關託管移動小房屋的信息包。我們還將讓您知道您可以從託管中賺取多少。 </p>
          <p className="pmb-20">您有風景優美的土地。我們有移動小房屋。這是一個雙贏的合作。</p>




          <h3 className="mt-70 text-left mb-2">新加坡新聞頻道的採訪</h3>
          <iframe width="100%" height="642" src="https://www.youtube.com/embed/e2G85kfkWF0" title="Channel 8 interview" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
          </div>

  <div className="flex-list-number-content">
    <div className="list-text">
      <div className="number-listing">
        <span>01</span>
      </div>
      <div className="right-content">
        
       <h4>無需資本</h4>
       <p>您無需投入任何前期資金即可開始這項合作。為了激勵人們走出城市，享受大自然的感覺，我們將為您提供一棟設備齊全的移動小房屋，每一棟都與自然環境無縫融合。您將為住客提供當地知識，歡迎他們來到您所在的地區。</p>
      
      </div>
    </div>

    <div className="list-text">
      <div className="number-listing">
        <span>02</span>
      </div>
      <div className="right-content">
         <h4>靈活的安排</h4>
     <p>這是一個利潤分享的雙贏合作。您提供風景優美的土地，我們提供移動小房屋。作為我們的合作夥伴，您可以選擇為移動小房屋提供水和電來獲得更高的收入，並幫助保持房屋清潔。您也可以靈活地簡單地提供土地，剩下的交給我們。</p>
      </div>
    </div>

    <div className="list-text">
      <div className="number-listing">
        <span>03</span>
      </div>
      <div className="right-content">
        <h4>結交新朋友</h4>
      <p>如果您喜歡結識來自世界各地和台灣各地的新朋友，這項合作非常適合您。作為移動小房屋的合作夥伴，您將是我們與客人聯繫的關鍵點，並幫助確保他們有一個很棒的住宿和體驗。</p>
      </div>
    </div>

    <div className="list-text">
      <div className="number-listing">
        <span>04</span>
      </div>
      <div className="right-content">
        <h4>呈現一個最棒的移動小房屋</h4>
     <p>您擁有風景優美的土地。我們擁有成熟的系統來營銷和運營業務。我們將處理所有營銷、預訂和客戶服務。我們一起在住客旅程的各個階段打造難忘的體驗。</p>
      </div>
    </div>

    <div className="list-text">
      <div className="number-listing">
        <span>05</span>
      </div>
      <div className="right-content">
        <h4> 賺取租金</h4>
<p>您可以從出租移動小房屋中賺取收入，同時鼓勵人們走出城市，重新親近自然。如果您在土地業經營業務，它可以補充並為您帶來更多客戶。最重要的是，您正在優化土地利用,在從之前沒有使用的空地中賺取收入。</p>
      </div>
    </div>



  </div>

  <div className="form-content-left">

          <h5 className="mt-100">了解從出租移動小房屋中可以賺取多少租金</h5>
          {successMsg && 
  <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
  {successMsg}
  </div>}
  {errorMsg && 
  <div className="alert alert-warning" role="warning alert"><span className="las la-exclamation-circle"></span>
  {errorMsg}</div>}
  <form className="grid grid-cols-2 gap-6" action="#" onSubmit={submitHandler} autoComplete="off" method="post">
      <label className="block">
        <Label>姓名<span className="red">*</span></Label>
        <Input
          type="text"
          placeholder=""
          name="name"
          id="name"
          onChange={event=>setDetails({...details,name:event.target.value})}
          ref={NameRef}
          autoComplete="off"
          autoFocus={showPopover}
          value={details.name}
        />
      </label>
      <label className="block">
        <Label>電子郵件 <span className="red">*</span></Label>
        <Input
          type="email"
          placeholder=""
          name="email"
          id="email"
          onChange={event=>setDetails({...details,email:event.target.value})}
          ref={EmailRef}
          autoComplete="off"
          autoFocus={showPopover}
          value={details.email}
        />
      </label>
      <label className="block">
        <Label>電話號碼</Label>
        <Input
          type="text"
          placeholder=""
          name="phone"
          id="phone"
          onChange={event=>setDetails({...details,phone:event.target.value})}
          ref={NameRef}
          autoComplete="off"
          autoFocus={showPopover}
          value={details.phone}
        />
      </label>
      <label className="block">
        <Label>國家/城市  <span className="red">*</span></Label>
        <Select className="mt-1.5"
          name="country"
          id="country"
          onChange={event=>setDetails({...details,country:event.target.value})}
          autoComplete="off"
          value={details.country}
          >
            <option value="">Select</option>
            <option value="台湾">台湾</option>
      </Select>
      </label>
      <label className="block">
        <Label>直轄市<span className="red">*</span></Label>
        <Select className="mt-1.5"
          name="state"
          id="state"
          onChange={event=>setDetails({...details,state:event.target.value})}
          autoComplete="off"
          value={details.state}
          >
            <option value="">Select</option>
            <option value="高雄市">高雄市</option>
            <option value="新北市">新北市</option>
            <option value="臺中市">臺中市</option>
            <option value="臺南市">臺南市</option>
            <option value="臺北市">臺北市</option>
            <option value="桃園市">桃園市</option>
            <option value="其他 (Others)">其他 (Others)</option>
      </Select>
      </label>
      <label className="block">
        <Label>地址    <span className="red">*</span></Label>
        <Input
          type="text"
          placeholder=""
          name="address"
          id="address"
          onChange={event=>setDetails({...details,address:event.target.value})}
          ref={AddressRef}
          autoComplete="off"
          autoFocus={showPopover}
          value={details.address}
        />
      </label>
      <label className="block">
        <Label>信息</Label>
        <Textarea 
          placeholder=""
          name="message"
          id="message"
          onChange={event=>setDetails({...details,message:event.target.value})}
          autoComplete="off"
          autoFocus={showPopover}
          value={details.message} rows={6} />
      </label>
      <div></div>
      <div>
        <ButtonPrimary type="submit">Subscribe</ButtonPrimary>
      </div>
  </form>

              </div>


              <h3 className="mt-70 text-left mb-2">澳大利亞新聞頻道的採訪</h3>
              <iframe width="100%" height="642" src="https://www.youtube.com/embed/usnixd-5wPM" title="Channel 7 Interview with Tiny Away" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
             
              <h3 className="mt-70 text-left mb-2">新加坡新聞頻道的採訪</h3>
              <iframe width="100%" height="642" src="https://www.youtube.com/embed/hZXUZ9LsdDk" title="Big Tiny - Business Times (Adrian)"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
               </div>

     {/*<SectionFounder />*/}

        {/*<SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
  />*/}

        {/*<SectionFounder />*/}

        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic />

        <SectionSubscribe2 />*/}
      </div>
    </div>
  );
};

export default PageBecomeHostJapan;
