import React, { FC, Fragment,ReactNode,useEffect,useState,useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
export interface LikeSaveBtnsProps {
  className?: string;
  DefaultUserID?: number;
  defaultValue?:boolean;
  onChange?:() => void;
}
const LikeSaveBtns: FC<LikeSaveBtnsProps> = ({
  className = "",
  DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : 0,
  onChange
}) => {

  // const [succeeded, setSucceeded]                               = useState(false);
  // useEffect(() => {
  //   if (onChange) {
  //     onChange(succeeded);
  //   }
  // }, [succeeded]);

  const handleChange = async (event:any) => onChange && onChange();
 
  const LikeSaveBtns = () => {
    return (
      <div className="flow-root">
        <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
          <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer" onClick={handleChange}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
              />
            </svg>
            <span className="hidden sm:block ml-2.5">Share</span>
          </span>
          
        </div>
      </div>
    );
  };
  return (
    <>
      {LikeSaveBtns()}
     
    </>
  );
};
export default LikeSaveBtns;





