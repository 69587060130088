import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React, { FC,useState,useEffect,ComponentType } from "react";
import axios from 'axios'; 
export interface SectionFooterProps {
  className?: string;
  targetlanguage?:string;
}
export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
  targetlanguage?:string;
}

const Footer: FC<SectionFooterProps> = ({ 
  className = "", 
  targetlanguage  = localStorage.getItem("targetlanguage") ? localStorage.getItem("targetlanguage") : (window.location.hostname=='tinyaway.com.tw' || window.location.hostname=='www.tinyaway.com.tw')?"zh_tw":"en",
}) => {
  
  const widgetMenus: WidgetFooterMenu[] = [
    {
      id: "5",
      title: "Explore",
      menus: [
        { href: "/state/tiny-houses-in-australia-sydney", label: targetlanguage=='zh_tw'?'澳洲新南威爾斯州':"New South Wales, Australia" },
        { href: "/state/tiny-houses-in-australia-melbourne", label: targetlanguage=='zh_tw'?'澳洲維多利亞':"Victoria, Australia" },
        { href: "/book-a-tiny-house", label: targetlanguage=='zh_tw'?'小房子目的地':"Tiny House Destinations" },
        { href: "/host-a-tiny-house", label: targetlanguage=='zh_tw'?'成為主持人':"Become a Host" },
        { href: "https://giftcards.tinyaway.com/", label: targetlanguage=='zh_tw'?'禮物卡':"Gift Card",targetBlank:true },
        { href: "https://tinyaway.freshdesk.com/support/solutions", label: targetlanguage=='zh_tw'?'幫助中心':"Help Centre",targetBlank:true },
      ],
    },
    {
      id: "1",
      title: "Company",
      menus: [
        { href: "/", label: "Home" },
        { href: "/about", label: "About Us" },
        { href: "/terms-of-use", label: "Terms of Use" },
        { href: "/privacy", label: "Privacy" },
        { href: "/cancellation-policy", label: "Cancellation Policy" },
        { href: "/onetreeplanted", label: "One Tree Planted" },
      ],
    },
    {
      id: "2",
      title: "Contact Us",
      menus: [
        { href: "#", label: "🗺 3/23-25 Lara Way, Campbellfield, VIC 3061" },
        { href: "#", label: "💌  ask@tinyaway.com" },
        { href: "/contact-us", label: "☎ Contact us" }, 
      ],
    },
  ];

  const [wigletMenu, setWigletMenu]                         = useState(widgetMenus);
  const [websiteTagLine, setWebsiteTagLine]                 = useState("");
  const [copyWrite, setCopyWrite]                           = useState("");
  const [socialMedia, setSocialMedia]                       = useState([]);
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL+'getFootersData?language='+targetlanguage)
    .then(res => {
      setWigletMenu(res.data.footer_menu);
      setWebsiteTagLine(res.data.taglines);
      setCopyWrite(res.data.copy_write);
      setSocialMedia(res.data.social_media);
    })
    }, []);

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative  lg:py-10  lg:pb-0 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid-col-4-1 ">
     <div className="">
          <div className="col-span-2 md:col-span-1">
          <img className="footerlogo" src="https://cdn.tinyaway.com/common/Tiny-Away-Onetreeplanted-Logo-new.jpg"></img>
          <div className="common-content-descriptions"  dangerouslySetInnerHTML={ {__html: websiteTagLine} } />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            
          </div>
        </div>
        {wigletMenu.map(renderWidgetMenuItem)}  
      </div>

      <div className=" container pr-3 pl-3 container-1500 ">
        <div className="footer-flex">
          <div className="all-right-res">
          <p>{copyWrite}</p>
          </div>
          <div className="social-icon-right text-right">
          <SocialsList1 dataSocialMedia={socialMedia} className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
        </div>
        </div>
    </div>
  );
};
export default Footer;
