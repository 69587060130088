import React, { FC, useEffect, useState } from "react";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
import Input from "shared/Input/Input";

export interface NcInputNumberProps {
  className?: string;
  defaultCheckInNumber?: number;
  defaultCheckOutNumber?: number;
  checkoutmax?: number;
  checkinmax?: number;
  onChangeCheckin?: (value: number) => void;
  onChangeCheckout?: (value: number) => void;
  label?: string;
  desc?: string;
  checkin?:string;
  checkout?:string;
}

const NcInputNumber: FC<NcInputNumberProps> = ({
  className = "w-full",
  defaultCheckInNumber = 0,
  defaultCheckOutNumber = 0,
  checkoutmax,
  checkinmax,
  onChangeCheckin,
  onChangeCheckout,
  label,
  desc,
  checkin,
  checkout
}) => {
  const [checkinMaxValue, setCheckinMaxValue] = useState(defaultCheckInNumber);
  const [checkoutMaxValue, setCheckoutMaxValue] = useState(defaultCheckOutNumber);
  useEffect(() => {
    onChangeCheckin && onChangeCheckin(checkinMaxValue);
  }, [checkinMaxValue]);

  useEffect(() => {
    setCheckinMaxValue(defaultCheckInNumber);
  }, [defaultCheckInNumber]);

  useEffect(() => {
    setCheckoutMaxValue(defaultCheckOutNumber);
  }, [defaultCheckOutNumber]);

  useEffect(() => {
    onChangeCheckout && onChangeCheckout(checkoutMaxValue);
  }, [checkoutMaxValue]);

  const handleClickDecrement = () => {
    if (checkinmax && checkinmax <= checkinMaxValue) return;
    setCheckinMaxValue((state) => state + 1);
  };
  const handleClickIncrement = () => {
    if (checkoutmax && checkoutmax <= checkoutMaxValue) return;
    setCheckoutMaxValue((state) => state + 1);
  };
 
  const renderLabel = () => {
    return (
      <div className="flex flex-col">
        <span className="font-medium text-neutral-800 dark:text-neutral-200">
          {label}
        </span>
        {desc && (
          <span className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">
            {desc}
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      className={`nc-NcInputNumber flex items-center justify-between ${className}`}
      data-nc-id="NcInputNumber"
    >
      {/* {label && renderLabel()} */}

      <div
        className={`nc-NcInputNumber flex items-center justify-between  ml-5 relative relative-text-box-mind`}
      >
        <div className="button-right-left">
        <button
          className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
          type="button"
          onClick={handleClickDecrement}
          disabled={checkinmax ? checkinmax <= checkinMaxValue  : false}
        >
          <MinusIcon className="w-4 h-4" />
        </button>
        <button
          className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
          type="button"
          onClick={handleClickIncrement}
          disabled={checkoutmax ? checkoutmax <= checkoutMaxValue : false}
        >
          <PlusIcon className="w-4 h-4" />
        </button>
        </div>
        {/* {checkinMaxValue} */}
        <div className="flex gap-20 items-center justify-center pl-10 pr-10">
          <div className="flex flex-direction-column">
            <label>Check In</label>
            <span><b>{checkin}</b></span>
          </div>
          <div>|</div>
          <div className="flex flex-direction-column">
            <label>Check Out</label>
            <span><b>{checkout}</b></span>
          </div>
        </div>
        {/* {checkoutMaxValue} */}
        
      </div>
    </div>
  );
};
export default NcInputNumber;
