import React, { FC,useEffect,useState } from "react";
import { Helmet } from "react-helmet";
import { Route, Redirect,useHistory,Link,useParams,useLocation} from 'react-router-dom';
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import axios from 'axios';  

export interface PageGuidesProps {
  className?: string;
}
 


const PageGuides: FC<PageGuidesProps> = ({ className = "" }) => {
  let search                                                  = window.location.search;
  const history                                               = useHistory();
  let searchParams                                            = new URLSearchParams(search);
  const location                                              = useLocation();
  const [description, setDescription]                         = useState("");
  const [metaTitle, setMetaTitle]                             = useState("");

  console.log(location.pathname);
  useEffect(() => {
      console.log(process.env.REACT_APP_BASE_URL+'getPageContents?slug='+location.pathname);
      axios.get(process.env.REACT_APP_BASE_URL+'getPageContents?slug='+location.pathname)
  .then(res => {
    console.log(res.data);
    setDescription(res.data.page_details.content_html);
    setMetaTitle(res.data.page_details.meta_title);
  })
}, [location.pathname]);

  return (
    <div
      className={`main-content-PageGuides nc-PageGuides overflow-hidden ${className}`}
      data-nc-id="PageGuides"
    >
      <Helmet title={metaTitle}></Helmet>

      <div className="container relative">
        <div dangerouslySetInnerHTML={ {__html: description} } />
      </div>
    </div>
  );
};

export default PageGuides;
