import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import convertNumbThousand from "utils/convertNumbThousand";

export interface CardCategory6Props {
  className?: string;
  taxonomy: TaxonomyType;
  listingType?: string;
}

const CardCategory6: FC<CardCategory6Props> = ({
  className = "",
  taxonomy,
  listingType
}) => {
  const { count, name, href = "", thumbnail } = taxonomy;
  return (
    <Link
      to={{pathname: href}}
      className={`nc-CardCategory6 flex flex-col ${className}`}
      data-nc-id="CardCategory6"
    >
      <div
        className={`flex-shrink-0 relative overflow-hidden group`}
      >
        <NcImage
          src={thumbnail}
          className="object-cover filter-grayscale max-h70"
        />
        <span className="opacity-0  absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
      </div>
     
    </Link>
  );
};

export default CardCategory6;
