import React, { FC, ReactNode , useEffect, useState }  from "react";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import axios from 'axios'; 
import BackgroundSection from "components/BackgroundSection/BackgroundSection";


// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: ExperiencesDataType[] = DEMO_EXPERIENCES_LISTINGS.filter((_, i) => i < 8);

//
export interface SectionGridFeatureStoryProps {
  stayStories?: ExperiencesDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  targetlanguage?:string;
}

const SectionGridFeatureStory: FC<SectionGridFeatureStoryProps> = ({
  stayStories = DEMO_DATA,
  gridClass = "",
  targetlanguage  = localStorage.getItem("targetlanguage") ? localStorage.getItem("targetlanguage") : (window.location.hostname=='tinyaway.com.tw' || window.location.hostname=='www.tinyaway.com.tw')?"zh_tw":"en",
  heading = "Tiny House Stories",
  subHeading = targetlanguage=="zh_tw"?"探索終點，創造體驗」 這些是關於我們微型屋的故事，能讓您了解在這裡可以做些什麼事。":"Discover destinations, curating experiences. These are stories on our tiny houses to help you find out what to do in each tiny house.",
  headingIsCenter,
  tabs = []
}) => {
  const [stories, setStories]                     = useState([]); 
  const [isdataLoaded, setIsdataLoaded]           = useState(false);
  const [customheading, setCustomheading]         = useState(heading); 
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL+'GetFeaturedStories?language='+targetlanguage)
 	 .then(res => {
          setStories(res.data.stories); 
          if(res.data.stories.length > 0){
            setIsdataLoaded(true);
          }
          setCustomheading(res.data.heading);
    });   
  }, []);
  //console.log(stories);
  const renderCard = (stay: ExperiencesDataType) => {
    return <ExperiencesCard key={stay.id} data={stay} />;
  };
  return (
    <>
      {isdataLoaded ? 
    <div className="relative py-16 mb-6">
    <BackgroundSection />
    <div className="nc-ExperiencesCard relative">
      <HeaderFilter
        tabActive={"New York"}
        subHeading={subHeading}
        tabs={tabs}
        heading={customheading}
        onClickTab={() => {}}
      />
     <div
        className={`grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 ${gridClass}`}
      > 
        {stories && stories.length!=0 ?
        
        stories.map((stay) => renderCard(stay)) 
        
        : ''
        
        }
      </div>
      
    </div>
    </div>
      :''
      }
    </>
  );
};

export default SectionGridFeatureStory;
