import rightImg from "images/about-hero-right.png";
import videoplayback from "images/videoplayback.mp4";
import React, { FC,useEffect,useState,useRef } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionAboutVideo from "./SectionAboutVideo";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from 'axios'; 
export interface PageRewardsProps {
  className?: string;
}
const PageRewards: FC<PageRewardsProps> = ({ className = "" }) => {
  const [earnPoints, setEarnPoints]                       = useState([]);
  const [spendPoints, setSpendPoints]                     = useState([]);
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL+'GetRewards')
 	 .then(res => {
      setEarnPoints(res.data.earnigs);
      setSpendPoints(res.data.spendings);
    });   
  }, [earnPoints]);

  return (
    <div
      className={`nc-PageRewards overflow-hidden relative ${className}`}
      data-nc-id="PageRewards"
    >
      <Helmet title={'About Us - Tiny Away | Tiny House | Tiny Homes | Weekend Getaways | Getaway Aus'}></Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 pt-0 lg:py-18 space-y-16 lg:space-y-28">
      {/* <SectionAboutVideo /> */}
       <div className="our-tiny mt-11 ">
        <div className="EarnPoints">
          <h3>Earn Points</h3>
          <div className="sm:grid grid-cols-3 gap-5">
          {earnPoints.map((item) => (
              <a href="" className="backdrop-blur h-full">
                <div className="icon-box-w-points">
                  <span className={`icon-svg ${item['icon']}`}>
                  </span>
                  <h5>{item['title']}</h5>
                  <p className="color-points">{item['points']}</p>
                  {item['description']!='' && 
                    <span className="sm-text">({item['description']} )</span>
                  }
                </div>
              </a>
            ))}
          </div>
          <h3 className="mt-12">How to Spend Points </h3>
          <div className="sm:grid grid-cols-3 gap-5">
            {spendPoints.map((spending) => (
              <a href="" className="backdrop-blur h-full">
                <div className="icon-box-w-points">
                <span className="icon-svg points-hart">
                  </span>
                  <h5>{spending['points']} points </h5>
                  <p className="color-points">${spending['discount_value']} discount</p>
                </div>
              </a>
            ))}
          </div>
          </div>
      </div>
      </div>
    </div>
  );
};

export default PageRewards;
