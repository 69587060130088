import { Tab } from "@headlessui/react";
import { PencilAltIcon } from "@heroicons/react/outline";
import React, { FC, Fragment,ReactNode,useEffect,useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Route, Redirect,useHistory,Link} from 'react-router-dom';

import {
   CardElement,
    PaymentElement,
    useStripe,
    useElements
  } from "@stripe/react-stripe-js";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
export interface CheckOutFormProps {
  className?: string;
  paymentIntent?: { // 👈️ could be undefined
    status?: string;
  };
  clientSecret?: string;
  DefaultUserID?: number;
  bookingRef?: string;
}

const CheckOutForm: FC<CheckOutFormProps> = ({ 
  className = "",
  paymentIntent,
  clientSecret="",
  bookingRef,
  DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : 0
}) => {
    const history                                                 = useHistory();
    const stripe                                                  = useStripe();
    const elements                                                = useElements();
    const [message, setMessage]                                   = useState("");
    const [isLoading, setIsLoading]                               = useState(false);
    const [disabled, setDisabled]                                 = useState(true);
    const [error, setError]                                       = useState('');
    const [processing, setProcessing]                             = useState(false);
    const [succeeded, setSucceeded]                               = useState(false);
    const [userDisplayName,setUserDisplayName]                    = useState("");
    const [userLocalEmail,setUserLocalEmail]                      = useState("");
    const [user_id,setUserId]                                     = useState(DefaultUserID);
    
    useEffect(() => {
      setUserId(localStorage.getItem("user_id"));
    }, [DefaultUserID]);
    

    useEffect(() => {
      const localName = localStorage.getItem("display_name");
      const localEmail = localStorage.getItem("display_user_email");
      if (localName) {
        setUserDisplayName(localName);
      }
      if (localEmail) {
        setUserLocalEmail(localEmail);
      }
    }, [userDisplayName,userLocalEmail])
  
    // useEffect(() => {
    //     if (!stripe) {
    //       return;
    //     }
    //     const clientSecret = new URLSearchParams(window.location.search).get(
    //       "payment_intent_client_secret"
    //     );
    //     if (!clientSecret) {
    //       return;
    //     }
    //    stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
    //       switch (paymentIntent?.status) {
    //          case "succeeded":
    //            setMessage("Payment succeeded!");
    //            break;
    //          case "processing":
    //            setMessage("Your payment is processing.");
    //            break;
    //         case "requires_payment_method":
    //           setMessage("Your payment was not successful, please try again.");
    //           break;
    //         default:
    //           setMessage("Something went wrong.");
    //           break;
    //       }
    //     });
    //   }, [stripe]);


    // useEffect(() => {
    //   // Create PaymentIntent as soon as the page loads
    //   window
    //     .fetch("https://operations.tinyaway.com/stripe/index.php", {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json"
    //       },
    //       body: JSON.stringify({items: [{ id: "xl-tshirt" }]})
    //     })
    //     .then(res => {
    //       return res.json();
    //     })
    //     .then(data => {
    //       setClientSecret(data.clientSecret);
    //     });
    // }, []);

      const cardStyle = {
        style: {
          base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#32325d"
            }
          },
          invalid: {
            fontFamily: 'Arial, sans-serif',
            color: "#fa755a",
            iconColor: "#fa755a"
          }
        }
      };
      const handleChange = async (event:any) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
        
      };

      // const handleSubmit = async (e:any) => {
      //   e.preventDefault();
      //   if (!stripe || !elements) {
      //     // Stripe.js has not yet loaded.
      //     // Make sure to disable form submission until Stripe.js has loaded.
      //     return;
      //   }
      //   setIsLoading(true);
      //   const { error } = await stripe.confirmPayment({
      //     elements,
      //     confirmParams: {
      //       // Make sure to change this to your payment completion page
      //       return_url: "http://localhost:3000/checkout",
      //     },
      //   });
    
      //   // This point will only be reached if there is an immediate error when
      //   // confirming the payment. Otherwise, your customer will be redirected to
      //   // your `return_url`. For some payment methods like iDEAL, your customer will
      //   // be redirected to an intermediate site first to authorize the payment, then
      //   // redirected to the `return_url`.
      //   if (error.type === "card_error" || error.type === "validation_error") {
      //     //setMessage(error?.message);
      //   } else {
      //     setMessage("An unexpected error occurred.");
      //   }
      //   setIsLoading(false);
      // };

      const handleChangeC = (e:any) => {
        setUserDisplayName(e.target.value);
      };

      const handleSubmit = async (ev:any) => {
        ev.preventDefault();
        setProcessing(true);
        if (!stripe || !elements) {
             // Stripe.js has not yet loaded.
             // Make sure to disable form submission until Stripe.js has loaded.
             return;
        }
        const cardElement = elements.getElement(CardElement);
        if (cardElement!=null) {
          const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: cardElement
            }
          });
          if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
          } else {
            setError('');
            setProcessing(false);
            setSucceeded(true);
            setTimeout(() => {
              history.push("pay-done/"+bookingRef);
              history.go(0);
            }, 2000);
          }
        }
      };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="flex gap-2 border-r-10">
      {/* <div className="d-flex mb-3 mt-3 w-1/2">
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Name
        </span>
        <Input
          type="text"
          placeholder=""
          className="mt-1"
          name="name"
          id="name"
          value={userDisplayName}
        />
      </label>
      </div> */}
      {/* <div className="d-flex mb-3 mt-3 w-1/2">
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Email
        </span>
        <Input
          type="email"
          placeholder=""
          className="mt-1"
          name="email"
          id="email"
         value={userLocalEmail}
        />
      </label>
      </div> */}
      </div>
      <div className="space-y-1 mt-2 border-input-all ">
        <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
      </div>
      <div className="d-flex mb-3 mt-3">
      <ButtonPrimary disabled={processing || disabled || succeeded} id="submit">
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Pay now"
          )}
        </span>
      </ButtonPrimary>
      </div>
        
        {/* <PaymentElement id="payment-element"  />
        <div className="space-y-1 mt-2">
        <Label>Messager for author </Label>
        <Textarea placeholder="..." />
        <span className="text-sm text-neutral-500 block">
            Write a few sentences about yourself.
        </span>
        </div> 
        <div className="d-flex mb-3 mt-3">
        <ButtonPrimary disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </ButtonPrimary>
        </div> */}
        {/* Show any error or success messages */}

        {error && (
        <div className="alert alert-danger" role="alert">  <span className="las la-exclamation-circle"></span>
         {error}</div>
      )}
      {/* Show a success message upon completion */}
        {succeeded && <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>Payment succeeded</div>}
      </form>
  );
};
export default CheckOutForm;
