import { Tab } from "@headlessui/react";
import { PencilAltIcon } from "@heroicons/react/outline";
import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Route, Redirect, useHistory, Link, useParams, useLocation } from 'react-router-dom';
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import StripePaymentForm from "./StripePaymentForm";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import axios from 'axios';
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import { Helmet } from "react-helmet";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import NcInputNumberExtendedHoliday from "components/NcInputNumber/NcInputNumberExtendedHoliday";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'

export interface ExtendedHolidayProgramProps {
  className?: string;
  clientSecret?: string;
  DefaultUserID?: string;
  autoFocus?: boolean;
  defaultCheckInNumber?: number;
  defaultCheckOutNumber?: number;
  onChangeCheckin?: (data: ExtendedHolidayProgramProps["defaultCheckInNumber"]) => void;
  onChangeCheckout?: (data: ExtendedHolidayProgramProps["defaultCheckOutNumber"]) => void;
}

const ExtendedHolidayProgram: FC<ExtendedHolidayProgramProps> = ({
  className = "",
  DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : "",
  autoFocus = false,
  defaultCheckInNumber,
  defaultCheckOutNumber,
  onChangeCheckin,
  onChangeCheckout
}) => {
  const appearance = {
    theme: 'stripe',
  };
  const [clientSecret, setClientSecret]                         = useState("");
  const [selectedDate, setSelectedDate]                         = useState<DateRage>({
    /*startDate: moment(),
    endDate: moment().add(1, "days"),*/
    startDate: null,
    endDate: null,
  });
  let search = window.location.search;
  const history = useHistory();
  const params                                                  = useParams();
  const slug                                                    = Object.values(params);
  const [checkin, setCheckin]                                   = useState("");
  const [checkout, setCheckout]                                 = useState("");
  const [checkinFormatted, setCheckinFormatted]                 = useState("");
  const [checkoutFormatted, setCheckoutFormatted]               = useState("");
  const [checkinMax, setCheckinMax]                             = useState(-1);
  const [checkoutMax, setCheckoutMax]                           = useState(-1);
  const [isdataLoaded, setIsdataLoaded]                         = useState(false);
  const [isDataFound, setIsDataFound]                           = useState(false);
  const [listingName, setListingName]                           = useState("");
  const [success, setSuccess]                                   = useState(false);
  const [errorMessage, setErrorMessage]                         = useState('');
  const [orderId, setOrderId]                                   = useState(false);
  const [authenticated, setauthenticated]                       = useState(null);
  const [user_id, setUserId]                                    = useState(DefaultUserID);
  const [bookingRef, setBookingRef]                             = useState("");
  const [paypalClientid, setPaypalClientid]                     = useState("");
  const [stripeClientid, setStripeClientid]                     = useState("");
  const [paypalCurrency, setPaypalCurrency]                     = useState("");
  const [paypalTotalPrice, setPaypalTotalPrice]                 = useState(0);
  const [isMerchantsAllow, setIsMerchantsAllow]                 = useState(true);
  const [isCartProceed, setisCartProceed]                       = useState(false);
  const [processing, setProcessing]                             = useState(false);
  const [disabled, setDisabled]                                 = useState(false);
  const [details, setDetails]                                   = useState({name:"",email: "", phone: "" });
  let searchParams                                              = new URLSearchParams(search);
  const [value, setValue]                                       = useState("");
  const [displayPrice, SetDisplayPrice]                         = useState("");
  const [bookingDetails, setBookingDetails]                     = useState({listing_name:"",
                                                                                beds:"",
                                                                                listing_image:"",
                                                                                bathrooms:"",
                                                                                checkin_date:"",
                                                                                checkout_date:"",
                                                                                total_guests:"",
                                                                                });
  const [bookingSource, setBookingSource]                       = useState("");
  const [bookingToken, setBookingToken]                         = useState("");
  const [guestID, setGuestID]                                   = useState(0);
  const [errorMsg, setErrorMsg]                               = useState('');
  const [successMsg,setSuccessMsg]                            = useState('');
  const [bookinhid,setBookinhid]                              = useState('');

  const randomString = (length: any) => {
    var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
  }
  let stripePromise: any;

  const [dcrementOnNight, setDcrementOnNight] = useState(defaultCheckInNumber || 0);
  const [incrementOnNight, setIncrementOnNight] = useState(defaultCheckOutNumber || 0);
  const options = {
    clientSecret
  };
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + 'getBookingEHP?&booking_code='+ slug[0]
    +'&extended_night_token='+slug[1]+"&checkin_increment="+dcrementOnNight+"&checkout_increment="+incrementOnNight)
      .then(res => {
        setIsdataLoaded(true);
        if (res.data.success == true) {
          setIsDataFound(res.data.success);
          setBookingDetails(res.data.response);
          setCheckin(res.data.response.checkin_date);
          setCheckout(res.data.response.checkout_date);
          setPaypalClientid(res.data.merchant_info.paypal_client_id);
          setStripeClientid(res.data.merchant_info.stripe_client_id);
          setPaypalCurrency(res.data.response.currency_code);
          setIsMerchantsAllow(res.data.isMerchantsAllow);
          setBookingSource(res.data.response.booking_code);
          setisCartProceed(res.data.isCartProceed);
          SetDisplayPrice(res.data.response.price_details.display_price);
          setPaypalTotalPrice(res.data.response.price_details.payment_price);
          setCheckinFormatted(res.data.response.checkin_formatted_date);
          setCheckoutFormatted(res.data.response.checkout_formatted_date);
          setCheckinMax(res.data.response.checkin_extend);
          setCheckoutMax(res.data.response.checkout_extend);
          setBookingToken(res.data.response.extended_holiday_token);
          setGuestID(res.data.response.guest_id);
          setBookinhid(res.data.response.guesty_reservation_id);
        }
      });
  }, [dcrementOnNight,incrementOnNight]);
  if (stripeClientid != '') {
    stripePromise = loadStripe(stripeClientid);
  }
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (paypalTotalPrice > 0) {
      axios.post(process.env.REACT_APP_BASE_URL + "stripe_signature_ehp", {
        items: [{
          amount: paypalTotalPrice,
          booking: bookingSource,
          token:slug[1],
          extendcheckin:dcrementOnNight,
          extendcheckout:incrementOnNight
        }]
      })
        .then(function (response) {
          setClientSecret(response.data.clientSecret);
        });
    }
  }, [paypalTotalPrice]);
  const createOrder = (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [
        {
          reference_id: slug[1],
          extendcheckin:dcrementOnNight,
          description: 'Booking Extention for ' + listingName,
          amount: {
            currency_code: paypalCurrency,
            value: paypalTotalPrice
          },
        },
      ],
      application_context: {
        shipping_preference: 'NO_SHIPPING'
      }
    })
      .then((orderID: any) => {
        setOrderId(orderID);
        console.log(orderID);
        return orderID
      })
  }
  const onApprove = (data: any, actions: any) => {
    return actions.order.capture().then(function (details: any) {
      const { payer } = details
      setSuccess(true);
      axios.post(process.env.REACT_APP_BASE_URL + "extend_booking", {
        items: [{
          amount          : paypalTotalPrice,
          booking         : bookingSource,
          token_id        : slug[1],
          extendcheckin   : dcrementOnNight,
          extendcheckout  : incrementOnNight,
          payment_method  : "Paypal",
          payment_details : details,
        }]
      })
        .then(function (response) {
          if (response.data.success) {
            history.push("/extended-paydone/"+bookingSource+"/"+slug[1]);
          }
        });
    })
  }
  const onError = (data?: any, actions?: any) => {
    setErrorMessage("An error occured with your payment")
  };
  function reduceCheckInDate(date:any){
    const checkin_changes   =   new Date(checkin);
    console.log(checkin_changes.getDate());
    checkin_changes.setDate(checkin_changes.getDate()-1);
    console.log(checkin_changes);
    const needate       =   checkin_changes.getDate();
    const needate1       =   checkin_changes.getMonth()+1;
    const needate2       =   checkin_changes.getFullYear();
    setCheckin(needate2+"-"+needate1+"-"+needate);
  }
  function IncementCheckOutDate(date:any){
    const checkin_changes   =   new Date(checkout);
    console.log(checkin_changes.getDate());
    checkin_changes.setDate(checkin_changes.getDate()+1);
    console.log(checkin_changes);
    const needate         =   checkin_changes.getDate();
    const needate1        =   checkin_changes.getMonth()+1;
    const needate2        =   checkin_changes.getFullYear();
    setCheckout(needate2+"-"+needate1+"-"+needate);
  }
  useEffect(() => {
    if (onChangeCheckin) {
      onChangeCheckin(dcrementOnNight);
    }
  }, [dcrementOnNight]);
  useEffect(() => {
    if (onChangeCheckout) {
      onChangeCheckout(incrementOnNight);
    }
  }, [incrementOnNight]);

  useEffect(() => {
    setDcrementOnNight(defaultCheckInNumber || 0);
  }, [defaultCheckInNumber]);

  useEffect(() => {
    setIncrementOnNight(defaultCheckOutNumber || 0);
  }, [defaultCheckOutNumber]);
  const validateInput = () => {
    const fields = [
      {
        name: 'name',
        value: details.name,
        message: 'Name should not be blank.'
      },
      {
        name: 'email',
        value: details.email,
        message: 'Email should not be blank.'
      }
    ];
    console.log(fields);
    //return false;
    const isNotFilled = fields.some(field => {
      if (field.value.trim() === '') {
        console.log(field.message);
        setErrorMsg(field.message);
        //field.name === 'email' ? emailRef.current.focus() : passwordRef.current.focus();
        return true;
      }
      setErrorMsg('');
      return false;
    });
    return isNotFilled;
  };
  const submitHandler = (event: React.FormEvent<HTMLFormElement>) =>{
    event.preventDefault();
      const isInvalid               = validateInput();
      const isPhoneInvalid          = isValidPhoneNumber(value);
      if (!isInvalid) {
        if(isPhoneInvalid){
          setSuccessMsg("You're good to go!");
          details.phone             = value;
            axios.post(process.env.REACT_APP_BASE_URL+'verify_guests_details?booking='+bookinhid, details)
          .then(function (response) {
            if(response.data.status==200){
              //setsuccess('Successfully register');
              setDetails({name:"",email:"",phone:""});
              window.location.reload();
            }else{
              //setError(response.data.response);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        }else{
          if(!isPhoneInvalid){
            setSuccessMsg("");
            setErrorMsg('Please input correct phone number');
          }
        }
      }
  }
  const renderBookingDetails = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8 padding-box-20">
        {isDataFound ?
          <>
          <div className="flex flex-direction-column">
              <h2 className="text-2xl sm:text-2xl lg:text-2xl font-semibold font-sm-mid-20">Booking Details</h2>
              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          </div>
          <div className="flex mt-top-m-20 flex-col-full">
           <div className="flex-shrink-phone">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" w-checkout-page-sm-img  overflow-hidden mb-2">
                  <NcImage src={bookingDetails.listing_image} />
              </div>
            </div>
            <div className="py-0  px-3 space-y-0">
              <div>
                  <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1 font-bold">
                  {bookingDetails.listing_name}
                  </span>
              </div>
              <div className="mb-0">
                  <span className="block  text-sm text-neutral-500 dark:text-neutral-400 mb-0">
                  {bookingDetails.beds} beds · {bookingDetails.bathrooms} baths
                  </span>
              </div>
              <div className="mb-0">
                  <span className="block  text-sm text-neutral-500 dark:text-neutral-400 mb-0">
                      Check In - {bookingDetails.checkin_date} 
                  </span>
              </div>
              <div className="mb-0">
                  <span className="block  text-sm text-neutral-500 dark:text-neutral-400 mb-0"> 
                  Check Out - {bookingDetails.checkout_date} 
                  </span>
              </div>
              <div className="mb-0">
                  <span className="block  text-sm text-neutral-500 dark:text-neutral-400 mb-0">
                  Guests - {bookingDetails.total_guests} Guests
                  </span>
              </div>
            </div>
          </div>

            <div className="py-0  px-3 space-y-0 lr-auto-pay-box"  dangerouslySetInnerHTML={ {__html: displayPrice} } />
          </div>
          <div className="flex flex-direction-column">
            <div className="align-middle flex justify-between">
          <h2 className="text-2xl sm:text-2xl lg:text-2xl font-semibold font-sm-mid-20">Extend your dates</h2>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          </div>
          <div className="ExtendedHolidayProgram ">
            <div className="DateExtendedHolidayProgram mb-3">
              {/* <div className="w-fill pr-3">
                <p className="text-left">Check In Date</p>
                <div className="felx flex gap-0 items-center">
                  <div className="min-icon-date cursor-pointer" onClick={() => reduceCheckInDate(0)}><a href={void(0)} >-</a></div>
                  <div className="h-w-80">
                      <input  readOnly={true} className="input-left-border" type="text" value={checkin} /></div>
                </div>
              </div> */}
              <div className="w-fill">

              <NcInputNumberExtendedHoliday
                className="w-full"
                defaultCheckInNumber={dcrementOnNight}
                defaultCheckOutNumber={incrementOnNight}
                checkinmax={checkinMax}
                checkoutmax={checkoutMax}
                label="Adults"
                desc="Ages 13 or above"
                checkin={checkinFormatted}
                checkout={checkoutFormatted}
                onChangeCheckin={(value) => setDcrementOnNight(value)}
                onChangeCheckout={(value) => setIncrementOnNight(value)}
              />

                {/* <p className="text-left">Check Out Date</p>
                <div className="felx flex gap-0 items-center">
                  <div className="h-w-80"> <input type="text" value={checkout}  /></div>
                  <div className="pl-icon-date cursor-pointer" onClick={() => IncementCheckOutDate(0)}>
                    <a href={void(0)}>+</a></div>
                </div> */}
              </div>
              <div className="rest-btn-date">
                <button className="px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none bg-neutral-800 text-white" 
                      type="button" 
                      aria-controls="headlessui-tabs-panel-16"
                      onClick={() => {
                        setDcrementOnNight(0);
                        setIncrementOnNight(0);
                        setCheckinMax(checkinMax);
                        setCheckoutMax(checkoutMax);
                      }}
                      >Reset</button>
              </div>
            </div>
             
          </div>
          </>
          

          :<div className="alert alert-danger mx-auto w-1/2" role="alert">  <span className="las la-exclamation-circle"></span>No booking found!</div>
          }     
      </div>
    );
  };
  const renderPaymentMehods = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8 padding-box-20">
        <h2 className="text-2xl sm:text-2xl lg:text-2xl font-semibold font-sm-mid-20">
          Confirm and pay
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        {isCartProceed ?
          <div className="pay-with-main">
            {isMerchantsAllow ?
              <>
              <div className="w-full coupon-page">
                <h3 className="font-size-16 font-semibold">Pay with</h3>
              </div>
              <div className="mt-4 button-check-out-page">
                <Tab.Group>
                  <Tab.List className="flex">
                    <Tab as={Fragment}>
                      {({ selected }) => (
                        <button
                          className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${selected
                            ? "bg-neutral-800 text-white"
                            : " text-neutral-6000 dark:text-neutral-400"
                            }`}
                        >
                          <span className="mr-2.5">Credit card</span>
                          <img className="w-8" src={visaPng} alt="" />
                          <img className="w-8" src={mastercardPng} alt="" />
                        </button>
                      )}
                    </Tab>
                    <Tab as={Fragment}>
                      {({ selected }) => (
                        <button
                          className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${selected
                            ? "bg-neutral-800 text-white"
                            : "text-neutral-6000 dark:text-neutral-400"
                            }`}
                        >
                          Paypal
                        </button>
                      )}
                    </Tab>
                  </Tab.List>
                  <div className="w-full border-b border-neutral-200 my-5 mb-3"></div>
                  <Tab.Panels>
                    <Tab.Panel className="space-y-5">
                      {clientSecret && stripePromise && (
                        <Elements options={options} stripe={stripePromise}>
                          <StripePaymentForm clientSecret={clientSecret} bookingRef={bookingSource} token={bookingToken}  />
                        </Elements>
                      )}
                    </Tab.Panel>
                    <Tab.Panel className="space-y-5">
                      <PayPalScriptProvider
                        options={{
                          "client-id": paypalClientid,
                          currency: paypalCurrency,
                          intent: "capture",
                        }}
                      >
                        <div className="pt-4  w-1.5 ">
                          {/* <ButtonPrimary type="submit">Confirm and pay</ButtonPrimary>
                    */}
                          <PayPalButtons
                            style={{
                              color: "silver",
                              layout: "horizontal",
                              height: 48,
                              tagline: false,
                              shape: "pill"
                            }}
                            createOrder={createOrder}
                            onApprove={onApprove}
                            onError={onError}
                          />
                          {/* asdasd */}
                        </div>
                      </PayPalScriptProvider>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
              </>                
              :
              <div className="alert alert-danger" role="alert">  <span className="las la-exclamation-circle"></span>Select a date for your extended holiday.</div>
            }
          </div>
          :
          <div className="alert alert-danger" role="alert">  <span className="las la-exclamation-circle"></span>These dates are no longer available.</div>
        }
      </div>
    );
  };
  const renderRegistrationSection = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8 padding-box-20">
        <h2 className="text-2xl sm:text-2xl lg:text-2xl font-semibold font-sm-mid-20">
        Please provide us with your details
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        {successMsg && 
          <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
          {successMsg}
          </div>}
          {errorMsg && 
          <div className="alert alert-warning" role="warning alert"><span className="las la-exclamation-circle"></span>
          {errorMsg}</div>}
          <form className="grid grid-cols-1 gap-6" onSubmit={submitHandler}>
          <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">
            Name
          </span>
          <Input
            type="text"
            placeholder=""
            className="mt-1"
            name="name"
            id="name"
            onChange={event=>setDetails({...details,name:event.target.value})}
            autoComplete="off"
            value={details.name}
          />
        </label>
          <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">
            Email address
          </span>
          <Input
            type="email"
            placeholder=""
            className="mt-1"
            name="email"
            id="email"
            onChange={event=>setDetails({...details,email:event.target.value})}
            autoComplete="off"
            value={details.email}
          />
        </label>
          <label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              Phone
            </span>
            <PhoneInput
              placeholder="Enter phone number"
              international
              value={details.phone}
              defaultCountry="AU"
              countryCallingCodeEditable={false}
              onChange={setValue}
              phoneDigits={8}
              error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
            />
          </label>
          <ButtonPrimary type="submit">Continue</ButtonPrimary>
          </form>
      </div>
      )
  }
  const renderskeletonLoader = () => {
    return (
      <div>
        <div className="custom-loader lg:flex-row gap-3 flex">
          <div className="w-70-loader  lg:w-3/5 xl:w-2/3 border-neutral-200  sm:rounded-2xl sm:border">
            <div className="card m-2">
              <div className="card-body">
                <div className="row mb-5">
                  <div className="col-12">
                    <div className="p-3 bg-grey skeleton"></div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-3">
                    <div className="p-2 bg-grey skeleton"></div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-6">
                    <div className="p-2 bg-grey mb-2 skeleton"></div>
                    <div className="p-2 bg-grey mb-2 skeleton"></div>
                  </div>
                  <div className="col-6">
                    <div className="p-2 bg-grey mb-2 skeleton"></div>
                    <div className="p-2 bg-grey mb-2 skeleton"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <div className="p-2 bg-grey mb-3 skeleton"></div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-4">
                    <div className="p-2 bg-grey mb-2 skeleton"></div>
                  </div>
                  <div className="col-2">
                    <div className="p-2 bg-grey mb-2 skeleton"></div>
                  </div>
                </div>
                <div className="row mb-4 justify-content-between">
                  <div className="col-3">
                    <div className="p-2 bg-grey mb-2 skeleton"></div>
                  </div>
                  <div className="col-3">
                    <div className="p-2 bg-grey mb-2 skeleton"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <div className="p-2 bg-grey mb-3 skeleton"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-30-loader hidden lg:block flex-grow border-neutral-200 sm:rounded-2xl sm:border">
            <div className="card m-2">
              <div className="card-body">
                <div className="row mb-4">
                  <div className="col-5">
                    <div className="p-5 bg-grey skeleton"></div>
                    <div className="p-2 bg-grey skeleton"></div>
                  </div>
                  <div className="col-7">
                    <div className="p-2 bg-grey mb-2 skeleton"></div>
                    <div className="p-2 bg-grey mb-2 skeleton"></div>
                    <div className="p-2 bg-grey mb-4 skeleton"></div>
                    <div className="p-2 bg-grey mb-2 skeleton"></div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="p-3 bg-grey skeleton"></div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="row mb-3 justify-content-between">
                    <div className="col-4">
                      <div className="p-2 bg-grey skeleton"></div>
                    </div>
                    <div className="col-3">
                      <div className="p-2 bg-grey skeleton"></div>
                    </div>
                  </div>
                  <div className="row mb-3 justify-content-between">
                    <div className="col-4">
                      <div className="p-2 bg-grey skeleton"></div>
                    </div>
                    <div className="col-3 justify-content-between">
                      <div className="p-2 bg-grey skeleton"></div>
                    </div>
                  </div>
                  <div className="row mb-3 justify-content-between">
                    <div className="col-4">
                      <div className="p-2 bg-grey skeleton"></div>
                    </div>
                    <div className="col-3">
                      <div className="p-2 bg-grey skeleton"></div>
                    </div>
                  </div>
                  <div className="row mb-4 justify-content-between">
                    <div className="col-4">
                      <div className="p-2 bg-grey skeleton"></div>
                    </div>
                    <div className="col-3">
                      <div className="p-2 bg-grey skeleton"></div>
                    </div>
                  </div>
                  <div className="row mb-3 justify-content-between">
                    <div className="col-4">
                      <div className="p-3 bg-grey skeleton"></div>
                    </div>
                    <div className="col-3">
                      <div className="p-3 bg-grey skeleton"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={`nc-ExtendedHolidayProgram ${className}`} data-nc-id="ExtendedHolidayProgram">
      <Helmet title="Tiny Away | Extended Holiday Program"></Helmet>
      {isdataLoaded ?
        <main className="container mt-11 mb-24 lg:mb-32">
          <h2 className="container-header text-center"> Extended Holiday Program</h2>
          <div className="flex flex-col-reverse lg:flex-row">
            <div className=" w-full lg:block flex-grow">{renderBookingDetails()}</div>
            {isDataFound && guestID > 0 ?
            <div className=" w-2/3 pl-4 sm-full">{renderPaymentMehods()}</div>
            :""}
            {guestID == 0 && isDataFound ?
            <div className=" w-2/3 pl-4 sm-full">{renderRegistrationSection()}</div>
            :""}
          </div>
        </main>
        :
        <main className="container mt-11 mb-24 ">
          {renderskeletonLoader()}
        </main>
      }
    </div>
  );
};
export default ExtendedHolidayProgram;
