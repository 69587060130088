import React, { FC,useEffect,useState } from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.png";
import logoLightImg from "images/logo-light.png";
import LogoSvgLight from "./LogoSvgLight";
import LogoSvg from "./LogoSvg";
import LogoSvgGrampians from "./LogoSvgGrampians";
import axios from 'axios';  

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "",
}) => {

  const [isWebSiteName, setWebSiteName]       = useState("tinyaway");
  useEffect(() => {
      axios.get(process.env.REACT_APP_BASE_URL+'getWebsiteLogo_v1')
  .then(res => {
    console.log(res.data);
    setWebSiteName(res.data.logo_details.website_name);
  })
}, []);


  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 ${className}`}
    >

      
      <LogoSvg isWebSiteName={isWebSiteName} />
      
      
      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {/* {img ? (
        <img
          className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
          src={img}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden max-h-12 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )} */}
    </Link>
  );
};

export default Logo;
