import React, { FC, Fragment,useState,useEffect,useRef } from "react";
import MyRouter from "routers/index";
import ReactGA from "react-ga4";
import axios from 'axios'; 
function App() {
  const hostname  =  window.location.hostname;
  if(hostname=='escapeatlazarus.com'){
    const TRACKING_ID      = "G-VV5KJ49ZMH";
    ReactGA.initialize(TRACKING_ID);
  }else{
    const TRACKING_ID      = "G-R3C04MEMSF";
    ReactGA.initialize(TRACKING_ID);
  }
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.pathname + window.location.search });
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}

export default App;
