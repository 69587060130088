import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";

export interface SocialsList1Props {
  className?: string;
  dataSocialMedia?:any;
}

const socials: SocialType[] = [
  { name: "", icon: "lab la-facebook-square", href: "https://www.facebook.com/tinyaway/",targetBlank:true},
  { name: "", icon: "lab la-pinterest", href: "https://www.pinterest.com/tiny_away/",targetBlank:true },
  { name: "", icon: "lab la-youtube", href: "https://www.youtube.com/channel/UC0bqRZQeaV5r66ns9mnGf4Q",targetBlank:true },
  { name: "", icon: "lab la-instagram", href: "https://www.instagram.com/tiny.away/",targetBlank:true },
];
const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-2.5",dataSocialMedia }) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={item.href}
        className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
        key={index}
        target="_blank"
      >
        <i className={item.icon}></i>
        <span className="hidden lg:block text-sm">{item.name}</span>
      </a>
    );
  };

  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
      {dataSocialMedia.map(renderItem)}
    </div>
  );
};

export default SocialsList1;
