import React from "react";
import { ReactNode } from "react";

export interface HeadingMapProps {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
  totalPages?: number;
}

const HeadingMap: React.FC<HeadingMapProps> = ({
  className = "",
  heading = "",
  subHeading,
  totalPages
}) => {
  return (
    <div className={`mb-6 lg:mb-6 ${className}`}>
      <h2 className="text-4xl font-semibold">{heading}</h2>
      {subHeading ? (
        subHeading
      ) : (
        <span className="block  mt-3 font-bold font-size-16">
        {totalPages} stays
        </span>
      )}
    </div>
  );
};

export default HeadingMap;
