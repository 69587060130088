import Label from "components/Label/Label";
import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import { Route, Redirect, useHistory, Link, useParams } from 'react-router-dom';
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ReCAPTCHA from "react-google-recaptcha";
import { FacebookShareButton, TwitterShareButton,WhatsappShareButton,PinterestShareButton,LinkedinShareButton,EmailShareButton,FacebookMessengerShareButton} from "react-share";
import { FacebookIcon, TwitterIcon,WhatsappIcon,PinterestIcon,LinkedinIcon,EmailIcon } from "react-share";

export interface AccountPageProps {
  className?: string;
  DefaultUserID?: string;
  autoFocus?: boolean;
}

const AccountPage: FC<AccountPageProps> = ({
  className = "",
  DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : "",
  autoFocus = false
}) => {
  const history                                                   =   useHistory();
  const expororerPoingts                                          =   0;
  const GlobetrotterPoints                                        =   0;
  const [user_id, setUserId]                                      =   useState(DefaultUserID);
  const [first_name, setFirst_name]                               =   useState("");
  const [last_name, setLast_name]                                 =   useState("");
  const [dob, setDob]                                             =   useState("");
  const [gender, setGender]                                       =   useState("");
  const [username, setUsername]                                   =   useState("");
  const [email, setEmail]                                         =   useState("");
  const [address, setAddress]                                     =   useState("");
  const [phonenumber, setPhonenumber]                             =   useState("");
  const [aboutus, setAboutus]                                     =   useState("");
  const [errorMsg, setErrorMsg]                                   =   useState('');
  const [successMsg, setSuccessMsg]                               =   useState('');
  const [details, setDetails]                                     =   useState({
                                                                          first_name: "",
                                                                          last_name: "",
                                                                          email: "",
                                                                          username: "",
                                                                          dob: "",
                                                                          gender: "",
                                                                          address: "",
                                                                          phone: "",
                                                                          aboutus: "",
                                                                          google_captcha:""
                                                                        });
  const firstNameRef                                              =   useRef<HTMLInputElement>(null);
  const lastNameRef                                               =   useRef<HTMLInputElement>(null);
  const emailRef                                                  =   useRef<HTMLInputElement>(null);
  const usernameRef                                               =   useRef<HTMLInputElement>(null);
  const genderRef                                                 =   useRef<HTMLInputElement>(null);
  const addressRef                                                =   useRef<HTMLInputElement>(null);
  const phoneRef                                                  =   useRef<HTMLInputElement>(null);
  const aboutusRef                                                =   useRef<HTMLInputElement>(null);
  const dobRef                                                    =   useRef<HTMLInputElement>(null);
  const [showPopover, setShowPopover]                             =   useState(autoFocus);
  const containerRef                                              =   useRef<HTMLDivElement>(null);
  const inputRef                                                  =   useRef<HTMLInputElement>(null);
  const [isdataLoaded, setIsdataLoaded]                           =   useState(false);
  const [images, setImages]                                       =   useState([]);
  const [imageURLs, setImageURLs]                                 =   useState("");
  const [defaultPic, setDefaultPic]                               =   useState("");
  const [selectedFile, setSelectedFile]                           =   useState("");
  const selectForm                                                =   useRef(null);
  const [isModalEditProfile, setIsModalEditProfile]               =   useState(false);
  const captchaRef                                                =   useRef<ReCAPTCHA>(null);
  const [isModalResetPassowrd, setIsModalResetPassowrd]           =   useState(false);
  const [passwordUpdate, setpasswordUpdate]                       =   useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
    reset_pass_google_captcha:""
  });
  const [errorResetMsg, setErrorResetMsg]                         =   useState('');
  const [successResetMsg, setSuccessResetMsg]                     =   useState('');


  useEffect(() => {
    setUserId(localStorage.getItem("user_id"));
    if (DefaultUserID == "") {
      history.push("/login");
    }
  }, [DefaultUserID]);
  useEffect(() => {
    setDefaultPic("<div></div>")
    axios.get(process.env.REACT_APP_BASE_URL + 'get_user_info?user_id=' + DefaultUserID)
      .then(res => {
        //console.log(res.data);
        setDetails({
          first_name: res.data.user_details.first_name,
          last_name: res.data.user_details.last_name,
          email: res.data.user_details.user_email,
          username: res.data.user_details.user_name,
          dob: res.data.user_details.dob,
          gender: res.data.user_details.gender,
          address: res.data.user_details.address,
          phone: res.data.user_details.phone_number,
          aboutus: res.data.user_details.aboutus,
          google_captcha:""
        });
        setDefaultPic(res.data.user_details.user_pic);
        setIsdataLoaded(true);
      })
  }, [DefaultUserID]);
  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(details);
    const isInvalid = validateInput();
    if (!isInvalid) {
      axios.post(process.env.REACT_APP_BASE_URL + '/update_user_details?user_id=' + DefaultUserID, details)
        .then(function (response) {
          if (response.data.status == 200) {
            setSuccessMsg(response.data.response);
            history.go(0);
          } else {
            setErrorMsg(response.data.response);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setSuccessMsg('');
    }
  };
  const submitHandlerResetPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isInvalid = validateInputResetPass();
    if (!isInvalid) {
      axios.post(process.env.REACT_APP_BASE_URL + '/update_user_passowrd?user_id=' + DefaultUserID, passwordUpdate)
        .then(function (response) {
          if (response.data.status == 200) {
            setSuccessResetMsg(response.data.response);
            localStorage.clear();
            setpasswordUpdate({
              current_password: "",
              new_password: "",
              confirm_password: "",
              reset_pass_google_captcha:""
            });
            history.go(0);
          } else {
            setErrorResetMsg(response.data.response);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setSuccessResetMsg('');
    }
  };
  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);
  const validateInput = () => {
    const fields = [
      {
        name: 'fist_name',
        value: details.first_name,
        message: 'First name should not be blank.'
      },
      {
        name: 'last_name',
        value: details.last_name,
        message: 'Last name should not be blank.'
      },
      // {
      //   name: 'gender',
      //   value: details.gender,
      //   message: 'Gender should not be blank.'
      // },
      {
        name: 'username',
        value: details.username,
        message: 'Username should not be blank.'
      },
      {
        name: 'email',
        value: details.username,
        message: 'Email should not be blank.'
      }
      // {
      //   name: 'dob',
      //   value: details.dob,
      //   message: 'Date of birth should not be blank.'
      // }
      // {
      //   name: 'address',
      //   value: details.address,
      //   message: 'Address should not be blank.'
      // },
      // {
      //   name: 'phone',
      //   value: details.phone,
      //   message: 'Phone number should not be blank.'
      // },
      // {
      //   name: 'aboutus',
      //   value: details.aboutus,
      //   message: 'About you should not be blank.'
      // }
    ];
    const isNotFilled = fields.some(field => {
      if (field.value.trim() === '') {
        setErrorMsg(field.message);
        return true;
      }
      setErrorMsg('');
      return false;
    });
    return isNotFilled;
  };
  const validateInputResetPass = () => {
    const fields = [
      {
        id: 'current_password',
        value: passwordUpdate.current_password,
        message: 'Current password should not be blank.'
      },
      {
        id: 'new_password',
        value: passwordUpdate.new_password,
        message: 'New password should not be blank.'
      },
      {
        id: 'confirm_password',
        value: passwordUpdate.confirm_password,
        message: 'Confirm password should not be blank.'
      },
      {
        id: 'reset_pass_google_captcha',
        value: passwordUpdate.reset_pass_google_captcha,
        message: 'Captcha should not be blank.'
      }
    ];
    const isNotFilled = fields.some(field => {
      if (field.value.trim() === '') {
        setErrorResetMsg(field.message);
        return true;
      }
      if (field.id.trim() == 'new_password' || field.id.trim() == 'confirm_password') {
        if ((passwordUpdate.new_password!='' && passwordUpdate.confirm_password) && (passwordUpdate.new_password != passwordUpdate.confirm_password)) {
            console.log(field);
            setErrorResetMsg("New Password and confirm password should be same");
            return true;
        }
      }
      setErrorResetMsg('');
      return false;
    });
    return isNotFilled;
  };
  const renderskeletonLoader = () => {
    return (
      <div className="container pt-5 sm:pt-0 pb-24 lg:pb-32  custom-loader flex-direction-column">
        <div className="p-3 bg-grey skeleton w-30-1 mb-5"></div>
         
        <div className="flex flex-col md:flex-row mt-4">
          <div className="flex-shrink-0 flex items-start"><div className="flex h-32 overflow-hidden relative rounded-full skeleton w-32"></div></div>
          <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
            <div className="bg-grey h-2.5 skeleton w-1/100"></div>
            <div className="p-3 bg-grey skeleton sm:rounded-2xl"></div>
            <div className="bg-grey h-2.5 skeleton w-1/100"></div>
            <div className="p-3 bg-grey skeleton sm:rounded-2xl"></div>
            <div className="bg-grey h-2.5 skeleton w-1/100"></div>
            <div className="p-3 bg-grey skeleton sm:rounded-2xl"></div>
            <div className="bg-grey h-2.5 skeleton w-1/100"></div>
            <div className="p-3 bg-grey skeleton sm:rounded-2xl"></div>
            <div className="bg-grey h-2.5 skeleton w-1/100"></div>
            <div className="p-3 bg-grey skeleton sm:rounded-2xl"></div>
            <div className="p-3 bg-grey skeleton sm:rounded-2xl w-1/100"></div>
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (images.length < 1) return;
    setImageURLs(URL.createObjectURL(images[0]));
  }, [images]);
  const handleSubmit = async (event: any) => {
    event.preventDefault()
    const formData = new FormData();
    formData.append("selectedFile", selectedFile);
    try {
      const response = await axios({
        method: "post",
        url: "/api/upload/file",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (error) {
      console.log(error)
    }
  }
  const onImageChange = (e: any) => {
    setImages(e.target.files);
    const file = e.target.files[0];
    setSelectedFile(e.target.files[0])
    console.log(file.size);
    if (file.size > 1024) {
      if (selectForm.current != null && file != '') {
        //selectForm.current.submit();
      }
    }
  }
  
  function SetResetRecaptchaVal(value: any) {
    setpasswordUpdate({ ...passwordUpdate, reset_pass_google_captcha:value});
  }
  function SetRecaptchaVal(value: any) {
    setDetails({ ...details, google_captcha:value});
  }
  const renderModalEditProfile = () => {
    return (
      <Transition appear show={isModalEditProfile} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto model-sm-min"
          onClose={closeModalEditProfile}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border   h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">
                      Edit Profile
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalEditProfile} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                  <div className="container mt-5">
                    <div className="mx-auto space-y-6">
                      <form className="grid grid-cols-1 gap-6"  onSubmit={submitHandler} autoComplete="off">
                      {successMsg &&
                          <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
                            {successMsg}
                          </div>}
                        {errorMsg &&
                          <div className="alert alert-warning" role="warning alert"><span className="las la-exclamation-circle"></span>
                            {errorMsg}</div>}
                        <div className="flex-grow flex flex-50">
                          <div>
                            <Label>First Name</Label>
                            <Input className="mt-1.5"
                              type="text"
                              placeholder=""
                              name="first_name"
                              id="first_name"
                              onChange={event => setDetails({ ...details, first_name: event.target.value })}
                              ref={firstNameRef}
                              autoComplete="off"
                              autoFocus={showPopover}
                              value={details.first_name} />
                          </div>
                          <div>
                            <Label>Last Name</Label>
                            <Input className="mt-1.5"
                              type="text"
                              placeholder=""
                              name="last_name"
                              id="last_name"
                              onChange={event => setDetails({ ...details, last_name: event.target.value })}
                              ref={lastNameRef}
                              autoComplete="off"
                              autoFocus={showPopover}
                              value={details.last_name} />
                          </div>
                          
                          <div>
                            <Label>Username</Label>
                            <Input className="mt-1.5"
                              type="text"
                              placeholder=""
                              name="username"
                              id="username"
                              onChange={event => setDetails({ ...details, username: event.target.value })}
                              ref={usernameRef}
                              autoComplete="off"
                              autoFocus={showPopover}
                              value={details.username}
                              readOnly={true}
                            />
                          </div>
                          {/* ---- */}
                          <div>
                            <Label>Email</Label>
                            <Input className="mt-1.5"
                              type="email"
                              placeholder=""
                              name="email"
                              id="email"
                              onChange={event => setDetails({ ...details, email: event.target.value })}
                              ref={emailRef}
                              autoComplete="off"
                              autoFocus={showPopover}
                              value={details.email}
                              readOnly={true}
                            />
                          </div>

                          <div>
                            <Label>Gender</Label>
                            <Select className="mt-1.5"
                              name="gender"
                              id="gender"
                              onChange={event => setDetails({ ...details, gender: event.target.value })}
                              autoComplete="off"
                              autoFocus={showPopover}
                              value={details.gender}
                            >
                              <option value="">Select</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </Select>
                          </div>
                          {/* ---- */}
                          
                          {/* ---- */}
                          <div className="max-w-lg">
                            <Label>Date of birth</Label>
                            <Input
                              className="mt-1.5"
                              type="date"
                              placeholder=""
                              name="dob"
                              id="dob"
                              onChange={event => setDetails({ ...details, dob: event.target.value })}
                              ref={dobRef}
                              autoComplete="off"
                              autoFocus={showPopover}
                              value={details.dob}
                            />
                          </div>
                          {/* ---- */}
                          <div>
                            <Label>Addess</Label>
                            <Input className="mt-1.5"
                              type="text"
                              placeholder=""
                              name="address"
                              id="address"
                              onChange={event => setDetails({ ...details, address: event.target.value })}
                              ref={addressRef}
                              autoComplete="off"
                              autoFocus={showPopover}
                              value={details.address}
                            />
                          </div>
                          {/* ---- */}
                          <div>
                            <Label>Phone number</Label>
                            <Input className="mt-1.5"
                              type="text"
                              placeholder=""
                              name="phone"
                              id="phone"
                              onChange={event => setDetails({ ...details, phone: event.target.value })}
                              ref={phoneRef}
                              autoComplete="off"
                              autoFocus={showPopover}
                              value={details.phone}
                            />
                          </div>
                          {/* ---- */}
                         
                        </div>
                        <div className="mb-4 w-full">
                          <div>
                            <Label>About you</Label>
                            <Textarea className="mt-1.5"
                              placeholder=""
                              name="aboutus"
                              id="aboutus"
                              onChange={event => setDetails({ ...details, aboutus: event.target.value })}
                              autoComplete="off"
                              autoFocus={showPopover}
                              value={details.aboutus}
                            />
                          </div>
                          <div>
                          <Label>&nbsp;</Label>
                            <ReCAPTCHA
                              // size="invisible"
                              sitekey="6LeBUm4jAAAAANOeGumtzoKLckycxi0aSp6Cm0Mk"
                              ref={captchaRef}
                              id="google_captcha"
                              onChange={SetRecaptchaVal}
                            />
                          </div>
                          <div className="pt-2">
                            <ButtonPrimary type="submit">Update info</ButtonPrimary>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const renderModalResetPassowrd = () => {
    return (
      <Transition appear show={isModalResetPassowrd} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto model-sm-min"
          onClose={closeModalResetPassowrd}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8  w-half">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border   h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">
                      Update Password
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalResetPassowrd} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                  <div className="container mt-5">
                    <div className="mx-auto space-y-6 container-model-popup">
                      <form className="grid grid-cols-1 gap-6"  onSubmit={submitHandlerResetPassword} autoComplete="off">
                      {successResetMsg &&
                          <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
                            {successResetMsg}
                          </div>}
                        {errorResetMsg &&
                          <div className="alert alert-warning" role="warning alert"><span className="las la-exclamation-circle"></span>
                            {errorResetMsg}</div>}
                        <div className="flex-grow flex flex-50">
                          <div>
                            <Label>Current Password</Label>
                            <Input className="mt-1.5"
                              type="password"
                              placeholder=""
                              name="current_password"
                              id="current_password"
                              onChange={event => setpasswordUpdate({ ...passwordUpdate, current_password: event.target.value })}
                              ref={firstNameRef}
                              autoComplete="off"
                              autoFocus={showPopover}
                               />
                          </div>
                          <div>
                            <Label>New password</Label>
                            <Input className="mt-1.5"
                              type="password"
                              placeholder=""
                              name="new_passowrd"
                              id="new_passowrd"
                              onChange={event => setpasswordUpdate({ ...passwordUpdate, new_password: event.target.value })}
                              ref={lastNameRef}
                              autoComplete="off"
                              autoFocus={showPopover}
                              />
                          </div>
                          <div>
                            <Label>Confirm password</Label>
                            <Input className="mt-1.5"
                              type="password"
                              placeholder=""
                              name="confirm_password"
                              id="confirm_password"
                              onChange={event => setpasswordUpdate({ ...passwordUpdate, confirm_password: event.target.value })}
                              ref={usernameRef}
                              autoComplete="off"
                              autoFocus={showPopover}
                            />
                          </div>
                        </div>
                        <div className="mb-4 w-full">
                          <div>
                          <Label>&nbsp;</Label>
                            <ReCAPTCHA
                              // size="invisible"
                              sitekey="6LeBUm4jAAAAANOeGumtzoKLckycxi0aSp6Cm0Mk"
                              ref={captchaRef}
                              id="reset_pass_google_captcha"
                              onChange={SetResetRecaptchaVal}
                            />
                          </div>
                          <div className="pt-2">
                            <ButtonPrimary type="submit" >Update info</ButtonPrimary>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };
  function OpenModalEditProfile() {
    setIsModalEditProfile(true);
  }
  function closeModalEditProfile() {
    setIsModalEditProfile(false);
  }
  function closeModalResetPassowrd() {
    setIsModalResetPassowrd(false);
  }
  function OpenModalResetPassowrd() {
    setIsModalResetPassowrd(true);
  }
  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account | Tiny Away</title>
      </Helmet>
      <CommonLayout>
        {/* HEADING */}
        {isdataLoaded ?
          <div className="space-y-6 sm:space-y-4">
            {/* <h2 className="text-3xl font-semibold">Account info</h2> */}
           <div className="user-info-page">
            <div className="user-photo-icon">
            <div className="relative rounded-full overflow-hidden flex ">
                <form ref={selectForm} className="grid grid-cols-1 gap-6 flex-grow" onSubmit={handleSubmit} autoComplete="off">
                  <Avatar sizeClass="w-32 h-32" />
                  <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                          stroke="currentColor"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <img src={imageURLs} />
                    <span className="mt-1 text-xs">Change Image</span>
                  </div>
                  <input type="file" accept="image/*" className="absolute inset-0 opacity-0 cursor-pointer" onChange={onImageChange} />
                </form>
              </div>
            </div>
            <div className="user-about-all">
                    <div className="flex justify-between ">
                      <div className="box-user-name">
                        <h4>{details.first_name} {details.last_name}</h4>
                        <p>I'm Guest</p>
                      </div>
                      <div className="flex gap-3">
                      <a href={void(0)} onClick={OpenModalEditProfile} className="btn-referral"><i className="las la-user-edit"></i> Edit Profile</a>
                      <a href={void(0)} onClick={OpenModalResetPassowrd} className="btn-referral"><i className="las la-key"></i> Change Password</a>
                      {/* <a href={void(0)} className="btn-referral btn-ref-gr"><i className="las la-share-alt"></i> Referral code</a> */}
                        </div>
                    </div>
                    <div className="flex flex-wrap box-mid-detail-box gap-5">
                    <div className="name-about-user flex flex-direction-column gap-3 w-50/1">
                        <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm"><b>About</b></p>
                        <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm">{details.aboutus}</p>
                        </div>
                        <div className="flex flex-direction-column gap-3 w-50/1 bod-text-user">
                          <div>
                            <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm"><b>First Name</b></p>
                            <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm">{details.first_name}</p>
                            </div>
                          <div>
                            <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm"><b>Last Name</b></p>
                            <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm">{details.last_name}</p>
                          </div>
                          <div>
                            <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm"><b>Gender</b></p>
                            <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm">{details.gender}</p>
                            </div>
                          <div>
                            <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm"><b> Username</b></p>
                            <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm">{details.username}</p>
                            </div> 
                        </div>
                        <div className="flex flex-direction-column gap-3 w-50/1 bod-text-user">
                        <div>
                          <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm"><b>Email</b></p>
                          <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm">{details.email}</p>
                          </div> 
                        <div>
                          <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm"><b>Date of birth</b></p>
                          <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm">{details.dob}</p>
                          </div> 
                        <div>
                          <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm"><b>Addess</b></p>
                          <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm">{details.address}</p>
                          </div> 
                        <div>
                          <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm"><b>Phone number</b></p>
                          <p className="dark:text-neutral-300 font-medium nc-Label text-neutral-700 text-sm">{details.phone}</p>
                          </div> 
                        </div>
                    </div>
            </div>
           </div>
           {/* <div className="user-info-page box-shadow-card w-full pl-6">
              <div className="shere-link-btn">
                <h3>Share Invite link</h3>
                <div className="flex flex-direction-column gap-5 justify-center">
                  <div className="Invite-box">
                    <input type="text" className="" value={"https://tinyaway.com/referral=0sfjgdk"}/>
                    <button className="">Copy</button>
                  </div>
                  <div className="socal-icon-account">
                  <FacebookShareButton
                  title={""}
                  url={""}
                  quote={"Testing"}
                  hashtag={"#tinyaway"}
                  className="">
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton
                title={""}
                url={""}
                hashtags={["tinyaway", "discovernature","stayincomfort","tinyhouses","bigtiny"]}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
			  <WhatsappShareButton
                title={""}
                url={""}
                separator={`\n${""}, ${""}, ${""}\n`}
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
			  
			  <PinterestShareButton
                description={""}
                url={""}
                media={""}
              >
                <PinterestIcon size={32} round />
              </PinterestShareButton>
			  
			  <LinkedinShareButton
                title={""}
                url={""}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
			  
			  <EmailShareButton
                url={""}
                subject="Sharing with you Tiny Away"
                body={"Hi!,"+`\n\n`+
                      "I know you always enjoy a good getaway. Please check out Tiny Away."+`\n\n\n`+
                      `${""}\n`+
                      `${""}\n\n`}>
                <EmailIcon size={32} round />
              </EmailShareButton>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          :
          <div className="flex flex-col md:flex-row">
            {renderskeletonLoader()}
          </div>
        }
      </CommonLayout>
      {renderModalEditProfile()}
      {renderModalResetPassowrd()}
    </div>
  );
};

export default AccountPage;
