import React, { FC, ReactNode , useEffect, useState }  from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";
import axios from 'axios'; 


// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

//
export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  targetlanguage?:string;
}
const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  targetlanguage  = localStorage.getItem("targetlanguage") ? localStorage.getItem("targetlanguage") : (window.location.hostname=='tinyaway.com.tw' || window.location.hostname=='www.tinyaway.com.tw')?"zh_tw":"en",
  heading = targetlanguage=='zh_tw'?"熱門的移動微型屋介紹":"Popular Tiny House Destinations",
  subHeading = targetlanguage=='zh_tw'?"聽取房客的回饋做出改變，下列微型屋都是為各位精心設計的空間。":"Hear it from our guests. This is the list of tiny houses that we have specially curated for you, based on reviews from our guests who have stayed in them.",
  headingIsCenter,
  tabs = [],
  
}) => {
  const [feature, setFeature]               = useState([]); 
  const [customheading, setCustomheading]   = useState(heading); 
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL+'GetListing?language='+targetlanguage)
 	 .then(res => {
        //console.log('Loading Featured Listing'); 
        setFeature(res.data.listings); 
        setCustomheading(res.data.heading);
    });   
  }, []);
  
  const renderCard = (stay: StayDataType) => {
    return <StayCard key={stay.id} data={stay} />;
  };
  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={"New York"}
        subHeading={subHeading}
        tabs={tabs}
        heading={customheading} 
        onClickTab={() => {}}
      />
     <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 ${gridClass}`}
      > 
        {feature && feature.length!=0 ?feature.map((stay) => renderCard(stay)) : ''}
      </div>
      
    </div>
  );
};

export default SectionGridFeaturePlaces;
