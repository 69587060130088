import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import React, { FC } from "react";
import SectionHostTestiGridFilterCard from "./SectionHostTestiGridFilterCard";
export interface ListingExperiencesPageProps {
  className?: string;
}
const ListingExperiencesPage: FC<ListingExperiencesPageProps> = ({
  className = "",
}) => {
  return (
    <div
      className={`nc-HostsTestimonialsPage relative overflow-hidden ${className}`} data-nc-id="HostsTestimonialsPage">
      <div className="container relative overflow-hidden  container-serch-form-full">
        <SectionHostTestiGridFilterCard className="pb-24 lg:pb-32" />
      </div>
    </div>
  );
};
export default ListingExperiencesPage;
