import rightImg from "images/about-hero-right.png";
import videoplayback from "images/videoplayback.mp4";
import React, { FC,useState,useEffect,ComponentType } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from 'axios'; 

export interface PageAboutProps {
  className?: string;
}
const PageAbout: FC<PageAboutProps> = ({
  className = "" 
}) => {

  const [description, setDescription]                         = useState("");
  const [metaTitle, setMetaTitle]                             = useState("");
  const [bannnerUrl, setBannnerUrl]                           = useState("");
  const [isVideo, setIsVideo]                                 = useState("");
  const [isImages, setIsImages]                               = useState("");
  const [isdataLoaded, setIsdataLoaded]                       = useState(false);


  useEffect(() => {
      axios.get(process.env.REACT_APP_BASE_URL+'getPages?page=about_us')
  .then(res => {
    console.log(res.data);
    setDescription(res.data.page_details.content_html);
    setMetaTitle(res.data.page_details.meta_title);
    setBannnerUrl(res.data.page_details.banner_url);
    setIsVideo(res.data.page_details.is_video);
    setIsImages(res.data.page_details.is_images);
    setIsdataLoaded(true);
  })
}, [isdataLoaded]);
const renderskeletonLoader = () => {
  return (
    <div className="custom-loader"><div className="h-screen skeleton"></div></div>
  );
};
  return (
    <div className={`nc-PageWriteReview overflow-hidden relative ${className}`} data-nc-id="PageAbout">
      <Helmet title={metaTitle}></Helmet>
      <BgGlassmorphism />
      {isdataLoaded  ?
      <div className="container py-16 pt-0 lg:py-18 space-y-16 lg:space-y-28">
        <div className="our-wishlist pt-5 pl-0">
        <h2 className="text-4xl font-semibold">
        Wishlists
        </h2>
        
        <div className="list-wishlist">
          <h5>Create your first wishlist</h5>
          <p className="w-2/5">As you search, click heart icon to save your fovourite places and Experiences to a wishlist.</p>
        </div>
        </div>
      </div>
      :
      <div className="container py-16 pt-0 lg:py-18 space-y-16 lg:space-y-28">
        {renderskeletonLoader()}
      </div>
      }
    </div>
  );
};

export default PageAbout;
