import React, { FC, Fragment,useState,useEffect,useRef } from "react";
import { Route, Redirect,useHistory,Link,useParams} from 'react-router-dom';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import NcImage from "shared/NcImage/NcImage";
import { Dialog, Transition } from "@headlessui/react";
import axios from 'axios';  
import { Helmet } from "react-helmet";
import ReactPaginate from 'react-paginate';
import { FocusedInputShape } from "react-dates";
import ReservationDateRangeInput from "./ReservationDateRangeInput";
import Input from "shared/Input/Input";


export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface AccountReservationsProps {
   className?: string;
   DefaultUserID?: string;
 }

 const AccountReservations: FC<AccountReservationsProps> = ({ 
   className = "",
   DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : "",
 }) => {

   const history                                               = useHistory();
   const [user_id,setUserId]                                   = useState(DefaultUserID);
   const [feature, setFeature]                                 = useState([]); 
   const [isdataLoaded, setIsdataLoaded]                       = useState(false);
   const [isOpenModalAddons, setIsOpenModalAddons]             = useState(false);
   const [bookingcode, setbookingcode]                         = useState("");
   const [totalRecords, setTotalRecords]                       = useState(0);
   const [starLimit, setStarLimit]                             = useState(0);
   const [endLimit, setEndLimit]                               = useState(0);
   const [totalPages, setTotalPages]                           = useState(0);
   const [pageRangeDisplay, setPageRangeDisplay]               = useState(0);
   const [pageNumber, setPageNumber]                           = useState(0);
   const [locationInputValue, setLocationInputValue]           = useState("");
   const [dateFocused, setDateFocused]                         = useState<FocusedInputShape | null>(
     null
   );
   const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: null,
    endDate: null,
  });
   useEffect(() => {
    console.log(localStorage.getItem("user_id"));
      setUserId(localStorage.getItem("user_id"));
      if(DefaultUserID==""){
        history.push("/login");
      }
    }, [DefaultUserID]);

    const  PaginatedItems  = (itemsPerPage:any) => {
      // Here we use item offsets; we could also use page offsets
      // following the API or data you're working with.
      const [itemOffset, setItemOffset]                           = useState(0);
      
      //setStarLimit(itemOffset);
      // Simulate fetching items from another resources.
      // (This could be items from props; or items loaded in a local state
      // from an API endpoint with useEffect and useState)
      //console.log("itemOffset"+itemOffset);
      const endOffset = itemOffset + parseInt(itemsPerPage.itemsPerPage);
      //setEndLimit(endOffset);
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      //const currentItemsss = items.slice(0, 4);
      const pageCount = Math.ceil(totalRecords / itemsPerPage.itemsPerPage);
      // Invoke when user click to request another page.
      const handlePageClick = (event:any) => {
        const newOffset = (event.selected * itemsPerPage.itemsPerPage) % totalRecords;
        console.log(
          `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
        setStarLimit(newOffset);
        //setEndLimit(newOffset);
      };
      return (
        <>
          
        </>
      );
    }
    
    const handlePageClick = (event:any) => {
      const newOffset = parseInt(event.selected)+1;
      setPageNumber(newOffset);
      //setIsdataLoaded(false);
      // console.log(
      //   `User requested page number ${event.selected}, which is offset ${newOffset}`
      // );
      //setItemOffset(newOffset);
      //setStarLimit(newOffset);
      //setEndLimit(newOffset);
    };

    useEffect(() => {
      //setIsdataLoaded(false);
      axios.get(process.env.REACT_APP_BASE_URL+'getBookings?user_id='+user_id+"&search_by="+locationInputValue
      +"&checkin="+(dateRangeValue.startDate ? dateRangeValue.startDate.format('YYYY-MM-DD'):"")
      +"&checkout="+(dateRangeValue.endDate ? dateRangeValue.endDate.format('YYYY-MM-DD'):"")
      +"&page="+pageNumber)
      .then(res => {
        console.log(res.data);
        setFeature(res.data.bookings);
        setTotalRecords(res.data.total_record);
        setTotalPages(res.data.total_pages);
        setPageRangeDisplay(res.data.page_range_display);
        setIsdataLoaded(true);
      })
  }, [DefaultUserID,pageNumber,locationInputValue,dateRangeValue.startDate,dateRangeValue.endDate]);

  function closeModalViewAddons() {
   setIsOpenModalAddons(false);
 }
 function openModalViewAddons(booking_code:string) {
  axios.get(process.env.REACT_APP_BASE_URL+'getaddons?booking_code='+booking_code+"&user_id="+user_id+"&itemOffset=")
  .then(res => {
      setbookingcode(res.data.addpn_html)
    })
   setIsOpenModalAddons(true);
 }

 const Items = (currentItems: any) => {
  return (
    <>
      {currentItems.map((item:any) => (
          <div>
            <h3>Item #{item}</h3>
          </div>
        ))}
    </>
  );
}
  const renderMotalViewAddons = () => {
   return (
     <Transition appear show={isOpenModalAddons} as={Fragment}>
       <Dialog as="div"className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModalViewAddons}>
         <div className="min-h-screen px-4 text-center">
           <Transition.Child
             as={Fragment}
             enter="ease-out duration-300"
             enterFrom="opacity-0"
             enterTo="opacity-100"
             leave="ease-in duration-200"
             leaveFrom="opacity-100"
             leaveTo="opacity-0"
           > 
             <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
           </Transition.Child>

           {/* This element is to trick the browser into centering the modal contents. */}
           <span className="inline-block h-screen align-middle"  aria-hidden="true" >&#8203;</span>
           <Transition.Child
             as={Fragment}
             enter="ease-out duration-300"
             enterFrom="opacity-0 scale-95"
             enterTo="opacity-100 scale-100"
             leave="ease-in duration-200"
             leaveFrom="opacity-100 scale-100"
             leaveTo="opacity-0 scale-95"
           >
             <div className="inline-block py-8 h-screen w-small">
               <div className="inline-flex flex-col w-small max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full model-add-on-w-table


">
                 <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                   <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">
                     Add-ons
                   </h3>
                   <span className="absolute left-3 top-3">
                     <ButtonClose onClick={closeModalViewAddons} />
                   </span>
                 </div>
                 <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                  <div className="flex items-center py-6 space-x-8">
                       {/* <i className={`text-4xl text-neutral-6000 all-cons`}></i> */}
                       {/* <div className="model-add-ons-table">
                       <div dangerouslySetInnerHTML={ {__html: bookingcode} } />
                       </div> */}
                       <div className="model-add-ons-table">
                          <div className="grid grid-cols-1 gap-6 table-account">
                            <table>
                                <tr>
                                  <th>Sr No</th>
                                  <th>Tickets Details</th>
                                  <th>Meals Details</th>
                                  <th>Pets Details</th>
                                  <th>Total Paid</th>
                                </tr>
                                <tbody dangerouslySetInnerHTML={ {__html: bookingcode} } />
                            </table>
                          </div>
                      </div>
                     </div>
                 </div>
               </div>
             </div>
           </Transition.Child>
         </div>
       </Dialog>
     </Transition>
   );
 };
  const renderskeletonLoader = () => {
   return (
      <div className="pt-5 sm:pt-0 pb-24 lg:pb-32  custom-loader flex-direction-column">
         <div className="p-3 bg-grey skeleton w-30-1 mb-5"></div>  
         <div className="grid grid-cols-1 gap-6 table-account">
         <table className="w-100">
            <tr>
               <th className=""><div className="p-3 bg-grey skeleton w-100 h-10"></div></th>
               <th className=""><div className="p-3 bg-grey skeleton w-100 h-10"></div></th>
               <th className=""><div className="p-3 bg-grey skeleton w-100 h-10"></div></th>
               <th className=""><div className="p-3 bg-grey skeleton w-100 h-10"></div></th>
               <th className=""><div className="p-3 bg-grey skeleton w-100 h-10"></div></th>
               <th className=""><div className="p-3 bg-grey skeleton w-100 h-10"></div></th>
               <th className=""><div className="p-3 bg-grey skeleton w-100 h-10"></div></th>
               <th className=""><div className="p-3 bg-grey skeleton w-100 h-10"></div></th>
               <th className=""><div className="p-3 bg-grey skeleton w-100 h-10"></div></th>
            </tr>
            <tr>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
            </tr>
            <tr>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
            </tr>
            <tr>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
            </tr>
            <tr>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
            </tr>
            <tr>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
            </tr>
            <tr>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
            </tr>
            <tr>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
            </tr>
            <tr>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
               <td className=""><div className="p-3 bg-grey skeleton w-100"></div></td>
            </tr>
         </table>
         </div>
      </div>
   );
 };
  return (
    <div>
      <Helmet>
        <title>Reservations | Tiny Away</title>
      </Helmet>
      <CommonLayout>
          {isdataLoaded ? 
           
          <div className="space-y-4 sm:space-y-4 mt-2">
           <div className="card-title-account p-3 pl-6">
              <h2 className="text-3xl font-semibold pb-0 mb-0">Reservations ({totalRecords})</h2> 
           </div>
           <div className="card-box-account">
           <div className="page-filtter">
              <div className="gropu-box-input">
                <p>Search by confirmation code</p>
                <Input
                  required
                  aria-required
                  placeholder="Enter your booking code"
                  type="email"
                  name="search_by_code"
                  id="search_by_code"
                  autoComplete="off"
                  onChange={event => setLocationInputValue(event.target.value)}
                />
              </div>
              <div className="gropu-box-input">
                <p>Search by booking dates</p>
                <ReservationDateRangeInput
                  defaultValue={dateRangeValue}
                  defaultFocus={dateFocused}
                  onFocusChange={(focus) => setDateFocused(focus)}
                  onChange={(data) => setDateRangeValue(data)}
                  availableDates=""
                />
              </div>
            </div>
            <div className="table-main">
               <table>
                  <tr>
                     <th>Status</th>
                     <th className="width-th-200">Guests</th>
                     <th>Check-in</th>
                     <th>Checkout</th>
                     <th>Booked</th>
                     <th className="width-th-200">Listing</th>
                     <th>Confirmation Code</th>
                     <th>Total Payout</th>
                     <th>Actions</th>
                  </tr>
                  {feature.map((stay) => (
                     <tr>
                        <td><b>{stay['booking_status']}</b></td>
                        <td className="width-th-200"><b>{stay['guest_name']}</b> <span className="sm-text">{stay['total_guests']}</span></td>
                        <td>{stay['checkin']}</td>
                        <td>{stay['checkout']}</td>
                        <td>{stay['booked_at']} </td>
                        <td className="width-th-200">{stay['listing_name']}</td>
                        <td>{stay['confirmation_code']}</td>
                        <td>{stay['total_payout']}</td>
                        <td>
                           <div className="gap-2 btn-Details">
                              {stay['is_addons']==1 ?
                                 stay['isAddonPurchased'] ?
                                  <>
                                    <a target="_blank" className="btn-custom" href={stay['payment_addon_payment_link']}>Buy add-ons</a>
                                    <ButtonPrimary type="submit" className="btn-custom" onClick={() => openModalViewAddons(stay['confirmation_code'])}>View add-ons</ButtonPrimary>
                                  </>
                                    :
                                    <a target="_blank" className="btn-custom" href={stay['payment_addon_payment_link']}>Buy add-ons</a>
                                 :
                                 ""
                              }
                              <ButtonPrimary type="submit" className="btn-custom">View details</ButtonPrimary>
                              <a type="submit" className="btn-custom dots-icon" href="">...</a>
                           </div>
                        </td>
                     </tr>
                  ))}
                  </table>
               </div>
               <ReactPaginate
                  breakLabel="..."
                  className="pagination justify-center mt-4"
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={pageRangeDisplay}
                  pageCount={totalPages}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  activeClassName="active"
                  containerClassName="pagination "
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                />
               {/* <PaginatedItems itemsPerPage={itemsPerPage} />    */}
               </div>                    
               {renderMotalViewAddons()}

            </div>
            :
            <div className="space-y-6 sm:space-y-8 mt-2">
               {renderskeletonLoader()}
            </div>
         }
      </CommonLayout>
    </div>
  );
};
export default AccountReservations;
