import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import convertNumbThousand from "utils/convertNumbThousand";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";

export interface CardCategory8Props {
  className?: string;
  taxonomy: TaxonomyType;
  listingType?: string;
  defaultPoint?: string;
  public_review?: string;
  review_date?: string;
}

const CardCategory8: FC<CardCategory8Props> = ({
  className = "",
  taxonomy,
  listingType,
  defaultPoint,
}) => {
  const { count, name, href = "/",public_review, thumbnail,review_date} = taxonomy;
  
  return (
    
    <Link
      to={href}
      className={`nc-CardCategory8 flex flex-col ${className}`}
      data-nc-id="CardCategory8"
    >
      <div
        className={`flex-sled-meet-community`}
      >
        <div className="meet-community-images">
        <img src={thumbnail}></img>
        <div className="name-meet-community">
        <h2>{name}</h2>
       </div>
        </div>
      </div>
     
    </Link>
  );
};

export default CardCategory8;
