import Label from "components/Label/Label";
import React, { FC, Fragment,useState,useEffect,useRef } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
export interface PageGiftCardsRedeemProps {
  className?: string;
  DefaultUserID?: string;
}

const PageGiftCardsRedeem: FC<PageGiftCardsRedeemProps> = ({ 
  className = "",
  DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : "",
}) => {
  const options = {
    title: {
      text: 'Total Gift Card Value USD$500',
      align: 'left'
    },
    series: [{
      type: 'pie',
      data: [{
        y: 1,
        name: "Used Gift Value",
        color: "#000000"
    }, {
        y: 7,
        name: "Balance Gift Value",
        color: "#f15e75"
    }]
    }]
  }
  return (
    <div>
      <CommonLayout>
        <div className="space-y-4 sm:space-y-4">
          {/* HEADING */}
          <div className="card-title-account p-3 pl-6">
            <h2 className="text-3xl font-semibold pb-0 mb-0">Redeem Code</h2>
          </div>
          <div className="table-box-main ">
          <div className="card-box-account table-main box-shadow-card">
            <div className=" space-y-6 p-5 gift-reddem">
              <Label>Enter Your Gift Code</Label>
              <div className="reddem-input">

                <Input type="password" className="mt-1.5" />
                <ButtonPrimary>Send</ButtonPrimary>
              </div>
          </div>
          </div>
          <div className="card-box-account box-tranform box-shadow-card">
            <div className="total-pounts">
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                />
            </div>
          </div>
          </div>

        </div>
      </CommonLayout>
    </div>
  );
};
export default PageGiftCardsRedeem;
