import rightImg from "images/about-hero-right.png";
import videoplayback from "images/videoplayback.mp4";
import React, { FC,useState,useEffect,ComponentType } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionAboutVideo from "./SectionAboutVideo";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from 'axios'; 

export interface PageAccommodationOptionsProps {
  className?: string;
}
const PageAccommodationOptions: FC<PageAccommodationOptionsProps> = ({
  className = "" 
}) => {

  const [description, setDescription]                         = useState("");
  const [metaTitle, setMetaTitle]                             = useState("");
  const [bannnerUrl, setBannnerUrl]                           = useState("");
  const [isVideo, setIsVideo]                                 = useState("");
  const [isdataLoaded, setIsdataLoaded]                       = useState(false);


  useEffect(() => {
      axios.get(process.env.REACT_APP_BASE_URL+'getPageAbout')
  .then(res => {
    console.log(res.data);
    setDescription(res.data.page_details.content_html);
    setMetaTitle(res.data.page_details.meta_title);
    setBannnerUrl(res.data.page_details.banner_url);
    setIsVideo(res.data.page_details.is_video);
    setIsdataLoaded(true);
  })
}, [isdataLoaded]);
const renderskeletonLoader = () => {
  return (
    <div className="custom-loader"><div className="h-screen skeleton"></div></div>
  );
};
  return (
    <div className={`nc-PageAccommodationOptions overflow-hidden relative ${className}`} data-nc-id="PageAccommodationOptions">
      <Helmet title={metaTitle}></Helmet>
      <BgGlassmorphism />
      {isdataLoaded  ?
      <div className="container-full py-16 pt-0 lg:py-18 space-y-16 lg:space-y-28">
        <div className="banner-ac-options h-450 overflow-hidden relative">
       <img src="https://lakefyansholidaypark.com.au/wp-content/uploads/2022/09/Lake-Fyans-Holiday-Park-Victoria-Grampians.jpg"></img>
       
       <div className="banner-box-acop">
       <h1>Tiny Away Escape</h1>
       <ButtonPrimary type="submit">BOOK ONLINE</ButtonPrimary>
       </div>
       </div>
       <div className="container py-16 pt-0 lg:py-18 space-y-16 lg:space-y-28">
          <div className="grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
            <div className="box-banner-text-accommodation">
              <h2>Enjoy a Getaway Close to Nature </h2>
              <p>Come and relax at the edge of the Grampians in Dadswells Bridge, Victoria. Our cosy cabins and comfortable campsites are the perfect family getaway. You can even bring your dog to our pet friendly caravan park.</p>
              <p>We have a wide range of accommodation options, perfect for all travellers and family sizes. Self-catering cabins and tiny houses on wheels, with kitchenette and air-conditioning/ heater, provided in our accommodation. </p>
           <p>A choice of spacious powered sites and bush camping are available. </p>
            </div>
            <div className="">
              <img src="https://lakefyansholidaypark.com.au/wp-content/uploads/2022/09/LakeFyans-4Bed-room-Accommodation-4-Family-Room-Holiday-Park-Grampains-768x512.jpg"></img>
            </div>
          </div>

          <div className="grid gap-6 md:gap-8 sm:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4">
            <div><a href="" className="btn btn-theme-big">PARK MAP</a></div>
            <div><a href="https://escapeatgrampiansedge.com.au/terms-of-use" className="btn btn-theme-sm">Terms & Conditions</a></div>
            <div><a href="https://lakefyansholidaypark.com.au/dog-friendly-policy/ "  className="btn btn-theme-sm">Dog Policy</a></div>
            <div><a href="https://lakefyansholidaypark.com.au/fire-safety-policy/"  className="btn btn-theme-sm">Fire Policy</a></div>
            </div>


        </div>
        <div className="container-full full-banner-page-color">
        <div className="container py-16 pt-0 lg:py-18 space-y-16 lg:space-y-28">
          <div className="grid gap-6 md:gap-8 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
            <div className="box-page-accommodation">
              <a href="">
              <div className="flex-left-site-ca">
                <div><span>Sites</span></div>
                <div><i className="las la-archive text-lg"></i></div>
              </div>
              <div className="max-height-200">
              <img src="https://pictures.tinyaway.com/filters:format(jpg)/fit-in/960x720/Listings/Images/2023/02/398/ky31judrg143o5azximd1675990234517.jpg"></img>
              </div>
              <div className="max-wbox-in">
             <p><b>Get back to nature and stay in the great outdoors!</b></p>
             <p>Ensuite, Powered and Unpowered sites available.</p>
             </div>
              </a>
            </div>
 

            <div className="box-page-accommodation">
              <a href="">
              <div className="flex-left-site-ca">
                <div><span>Cabins</span></div>
                <div><i className="las la-bed text-lg"></i></div>
              </div>
              <div className="max-height-200">
              <img src="https://pictures.tinyaway.com/filters:format(jpg)/fit-in/960x720/Listings/Images/2023/02/208/qnscdhpv6otbnk6j4rht1675386815073.jpg"></img>
             </div>
             <div className="max-wbox-in">
             <p><b>Stay in one of our fantastic Cabins</b></p>
             <p>Sleeps Up To 6 Guests – Perfect For Families.</p>
             </div>
              </a>
            </div>

            <div className="box-page-accommodation">
              <a href="">
              <div className="flex-left-site-ca">
                <div><span>Holiday House</span></div>
                <div><i className="las la-home text-lg"></i></div>
              </div>
              <div className="max-height-200">
              <img src="https://pictures.tinyaway.com/filters:format(jpg)/fit-in/960x720/Listings/Images/2023/02/208/qnscdhpv6otbnk6j4rht1675386815073.jpg"></img>
              </div>
              <div className="max-wbox-in">
             <p><b>Tiny Houses on Wheels</b></p>
             <p>Discover Nature. Stay in Comfort in a tiny house on wheels. </p>
             </div>
              </a>
            </div>



          </div>
        </div>
        </div>


        <div className="container-full mt-0 container-top-0 relative">
        <BgGlassmorphism />
        <div className="container py-16 pt-0 lg:py-18 space-y-16 lg:space-y-28">
          <div className="grid gap-6 md:gap-8 sm:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4">
            <div className="fusion-layout-column fusion-builder-column-15">
            <i className="las la-utensils text-lg"></i>
              <h4>Camp Kitchen & BBQs</h4>
            </div>

            <div className="fusion-layout-column fusion-builder-column-15">
            <i className="las la-fire text-lg"></i>
              <h4>Fires Permitted</h4>
              <p>In dedicated fire pits. <a href="">Fire Policy</a></p>
            </div>

            <div className="fusion-layout-column fusion-builder-column-15">
            <i className="las la-water text-lg"></i>
              <h4>Camp Kitchen & BBQs</h4>
              <p>Incredible views</p>
            </div>

            <div className="fusion-layout-column fusion-builder-column-15">
            <i className="las la-dog text-lg"></i>
              <h4>Camp Kitchen & BBQs</h4>
              <p>In low season</p>
            </div>



            </div>
          </div>
         </div>

        
        

      
      </div>
      :
      <div className="container py-16 pt-0 lg:py-18 space-y-16 lg:space-y-28">
        {renderskeletonLoader()}
      </div>
      }
    </div>
  );
};

export default PageAccommodationOptions;
