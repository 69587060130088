import React, { FC } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/2-750x500.jpg";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";

export interface SectionSubscribe2Props {
  className?: string;
  targetlanguage?:string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ 
  className = "",
  targetlanguage  = localStorage.getItem("targetlanguage") ? localStorage.getItem("targetlanguage") : (window.location.hostname=='tinyaway.com.tw' || window.location.hostname=='www.tinyaway.com.tw')?"zh_tw":"en",
}) => {
  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">{targetlanguage=='zh_tw'?'訂閱我們的電子報':'Join our newsletter'} </h2>
        {targetlanguage=='zh_tw'?
        <>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
        快來領取移動微型屋的最新消息與獨家優惠！
        </span>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
        好不容易有了假期，結果住宿卻很難訂嗎？
        </span>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
        快訂閱我們的電子報，就會收到微型屋的最新資訊以及下期預告！
        </span>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
        更棒的是，成為我們移動微型屋的一員，就能收到第一手的獨家優惠與消息！
        </span>
        </>
        :
        <>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
        Receive exclusive email offers and promotions from Tiny Away. We know it is hard to book your preferred weekend dates due to overwhelming demand. Be on our mailing list and be informed on our newest tiny houses and what’s coming up soon!
        </span>
        <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            More importantly, we always have exclusive offers and information for our Tiny Away family. Be the first to be informed.
        </span>
        </>
        }
        
        <ul className="space-y-4 mt-5">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
            {targetlanguage=='zh_tw'?'領取移動微型屋的會員優惠':'Receive Tiny Away member discount'}
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="red" name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
            {targetlanguage=='zh_tw'?'領取移動微型屋的電子報 ':'Receive Tiny Away newsletters'}
            </span>
          </li>
        </ul>
        <form className="mt-7 relative max-w-sm">
          <Input
            required
            aria-required
            placeholder={targetlanguage=='zh_tw'?"輸入你的電子郵件信箱":"Enter your email"}
            type="email"
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-1"
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionSubscribe2;
