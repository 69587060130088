import { MegamenuItem, NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import __megamenu from "./jsons/__megamenu.json";

const megaMenuDemo: MegamenuItem[] = [
  {
    id: ncNanoId(),
    image:
      "https://images.pexels.com/photos/1591373/pexels-photo-1591373.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "Company",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i.Company,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://images.pexels.com/photos/2662116/pexels-photo-2662116.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "App Name",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i.AppName,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://images.pexels.com/photos/5059013/pexels-photo-5059013.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "City",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i.City,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://images.pexels.com/photos/5159141/pexels-photo-5159141.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "Contruction",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i.Contruction,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://images.pexels.com/photos/7473041/pexels-photo-7473041.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "Country",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i.Country,
    })),
  },
];
const demoChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Online Booking",
  },
  {
    id: ncNanoId(),
    href: "/home-2",
    name: "Real Estate",
    isNew: true,
  },
];
const otherPageChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blog Page",
  },
  {
    id: ncNanoId(),
    href: "/blog-single",
    name: "Blog Single",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "About",
  },
  {
    id: ncNanoId(),
    href: "/contact-us",
    name: "Contact us",
  },
  {
    id: ncNanoId(),
    href: "/login",
    name: "Login",
  },
  {
    id: ncNanoId(),
    href: "/signup",
    name: "Signup",
  },
  {
    id: ncNanoId(),
    href: "/subscription",
    name: "Subscription",
  },
];
const templatesChildrenMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/add-listing-1",
    name: "Add Listings",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/add-listing-1",
        name: "Add Listings 1",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-2",
        name: "Add Listings 2",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-3",
        name: "Add Listings 3",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-4",
        name: "Add Listings 4",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-5",
        name: "Add Listings 5",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-6",
        name: "Add Listings 6",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-7",
        name: "Add Listings 7",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-8",
        name: "Add Listings 8",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-9",
        name: "Add Listings 9",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-10",
        name: "Add Listings 10",
      },
    ],
  },
  //
  { id: ncNanoId(), href: "/checkout", name: "Checkout" },
  { id: ncNanoId(), href: "/pay-done", name: "Pay done" },
  //
  { id: ncNanoId(), href: "/author", name: "Author Page" },
  { id: ncNanoId(), href: "/account", name: "Account Page" },
];
const tinyhouseDestination:NavItemType[]=[
  
  {
    id: ncNanoId(),
    href: "/country/must-visit-in-australia",
    name: "Australia",
    type: "dropdown",
    children: [
      { id: ncNanoId(), href: "/state/tiny-houses-in-australia-sydney", name: "NEW SOUTH WALES" },
      { id: ncNanoId(), href: "/state/tiny-houses-in-australia-melbourne", name: "VICTORIA" },
      { id: ncNanoId(), href: "/state/south-australia", name: "SOUTH Australia" },
      { id: ncNanoId(), href: "/state/western-australia", name: "Western Australia" },
      { id: ncNanoId(), href: "/state/tiny-houses-in-australia-queensland", name: "QUEENSLAND" },
      { id: ncNanoId(), href: "/state/tasmania", name: "TASMANIA" },
      { id: ncNanoId(), href: "/listing-stay-map", name: "Map View" },
    ],
  },
  {
   id: ncNanoId(), 
   href: "/country/must-visit-in-new-zealand",
   name: "New Zealand",
   type: "dropdown",
   children: [
      { id: ncNanoId(), href: "/state/tiny-houses-in-north-island-of-new-zealand", name: "NORTH ISLAND" },
      { id: ncNanoId(), href: "/state/tiny-houses-in-north-south-of-new-zealand", name: "SOUTH ISLAND" },
    ],
  },
  {
   id: ncNanoId(), 
   href: "/country/must-visit-in-malaysia",
   name: "Malaysia",
   type: "dropdown",
   children: [
      { id: ncNanoId(), href: "/state/tiny-houses-in-pahang-malaysia", name: "Pahang" },
      { id: ncNanoId(), href: "/state/tiny-houses-in-malacca-malaysia", name: "Malacca" },
    ],
  },
  {
   id: ncNanoId(), 
   href: "/country/must-visit-in-italy",
   name: "Italy",
   type: "dropdown",
   children: [
      { id: ncNanoId(), href: "/state/tiny-houses-in-italy-tuscany", name: "Tuscany" },
      // { id: ncNanoId(), href: "/state/tiny-houses-in-malacca-malaysia", name: "Malacca" },
    ],
  },
  {
   id: ncNanoId(), 
   href: "/tiny-houses-coming-soon",
   name: "Coming Soon",
  }
]
const tinyhouseEscape:NavItemType[]=[
  
    // {
    //   id: ncNanoId(),
    //   href: "/country/must-visit-in-australia",
    //   name: "Lazarus (Singapore)",
    // },
    {
     id: ncNanoId(), 
     href: "/escape/grampians-edge",
     name: "@ GRAMPIANS EDGE (AUSTRALIA)",
    }
]
export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "HOME",
  },
  {
    id: ncNanoId(),
    href: "/book-a-tiny-house",
    name: "Tiny House Destination",
    type: "dropdown",
    children: tinyhouseDestination
  },
  {
    id: ncNanoId(),
    href: "/escape",
    name: "Tiny Away Escape",
    type: "dropdown",
    children: tinyhouseEscape
  },
  {
    id: ncNanoId(),
    href: "/tiny-house-stories",
    name: "Tiny Stories",
    /*type: "dropdown",
    children: [
      { id: ncNanoId(), href: "/tiny-house-stories-map", name: "Tiny Stories (map)" }]*/
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "About Us",
    type: "dropdown",
    children: [
      { id: ncNanoId(), href: "/about", name: "Our Story" },
      { id: ncNanoId(), href: "/host-a-tiny-house", name: "Become a Host" },
      { id: ncNanoId(), href: "https://tinyaway.freshdesk.com/support/solutions", name: "FAQS",targetBlank:true },
      { id: ncNanoId(), href: "/contact-us", name: "Contact Us" },
      { id: ncNanoId(), href: "/cancellation-policy", name: "Cancellation Policy" },
      // { id: ncNanoId(), href: "/rewards", name: "Rewards" }
    ]
  },
  {
     id: ncNanoId(),
     href: "https://giftcards.tinyaway.com/",
     name: "Gift Cards",
     targetBlank:true
   },
  /*{
    id: ncNanoId(),
    href: "/login",
    name: "Login",
  },
  {
    id: ncNanoId(),
    href: "/signup",
    name: "Register",
  },
  {
    id: ncNanoId(),
    href: "#",
    name: "Five columns",
    type: "megaMenu",
    megaMenu: megaMenuDemo,
  },
  {
    id: ncNanoId(),
    href: "#",
    name: "Listing Page",
    type: "dropdown",
    children: [
      { id: ncNanoId(), href: "/book-a-tiny-house", name: "Stay page" },
      { id: ncNanoId(), href: "/listing-stay-map", name: "Stay page (map)" },
      { id: ncNanoId(), href: "/listing-stay-detail", name: "Stay Detail" },
      
      {
        id: ncNanoId(),
        href: "/listing-experiences",
        name: "Experiences page",
      },
      {
        id: ncNanoId(),
        href: "/listing-experiences-map",
        name: "Experiences page (map)",
      },
      {
        id: ncNanoId(),
        href: "/listing-experiences-detail",
        name: "Experiences Detail",
      },
     
      { id: ncNanoId(), href: "/listing-car", name: "Cars page" },
      { id: ncNanoId(), href: "/listing-car-map", name: "Cars page (map)" },
      { id: ncNanoId(), href: "/listing-car-detail", name: "Car Detail" },
      
      {
        id: ncNanoId(),
        href: "/listing-real-estate",
        name: "Real Estate Listings",
      },
      {
        id: ncNanoId(),
        href: "/listing-real-estate-map",
        name: "Real Estate Maps",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "#",
    name: "Templates",
    type: "dropdown",
    children: templatesChildrenMenus,
  },
  {
    id: ncNanoId(),
    href: "#",
    name: "Other pages",
    type: "dropdown",
    children: otherPageChildMenus,
  },*/
];
