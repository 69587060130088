import rightImg from "images/about-hero-right.png";
import videoplayback from "images/videoplayback.mp4";
import React, { FC, Fragment,useState,useEffect,useRef } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionBecomeHostVideo from "./SectionBecomeHostVideo";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { TaxonomyType } from "data/types";
import SectionLandHostCommunity from "components/SectionLandHostCommunity/SectionLandHostCommunity";
import axios from 'axios';  


const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "https://www.bigtiny.com.sg/tinynews",
    name: "Mr. Fernando, Sithuri Tiny House",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/IMG_0434-1-2048x1365.jpg",
  },
  {
    id: "2",
    href: "https://www.bigtiny.com.sg/tinynews",
    name: "Ms. Helen, Saddle Camp",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/saddlecamp-24.jpg",
  },
  {
    id: "3",
    href: "https://www.bigtiny.com.sg/tinynews",
    name: "Mr. Taylan, Seven Hills",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/taylan1.jpg",
  },
  
  
  
];

export interface PageBecomeHostJapanProps {
  className?: string;
  autoFocus?: boolean;
}
const PageBecomeHostJapan: FC<PageBecomeHostJapanProps> = ({ 
  className = "",
  autoFocus = false 
}) => {

const [details,setDetails]                                  = useState({name:"",
                                                          email:"",
                                                          phone:"",
                                                          country:"",
                                                          state:"",
                                                          address:"",
                                                          message:""});
const [showPopover, setShowPopover]                         = useState(autoFocus);
const containerRef                                          = useRef<HTMLDivElement>(null);
const inputRef                                              = useRef<HTMLInputElement>(null);
const NameRef                                               = useRef<HTMLInputElement>(null);
const EmailRef                                              = useRef<HTMLInputElement>(null);
const CountryRef                                            = useRef<HTMLInputElement>(null);
const StateRef                                              = useRef<HTMLInputElement>(null);
const AddressRef                                            = useRef<HTMLInputElement>(null);
const [errorMsg, setErrorMsg]                               = useState('');
const [successMsg, setSuccessMsg]                           = useState('');

const submitHandler = (event: React.FormEvent<HTMLFormElement>) =>{
event.preventDefault();
console.log(details);
const isInvalid = validateInput();
if (!isInvalid) {
  axios.post(process.env.REACT_APP_BASE_URL+'/host_enquiries?type=japan', details)
.then(function (response) {
  console.log(response.data.response);
  if(response.data.status==200){
    setSuccessMsg(response.data.response);
    setDetails({name:"",
    email:"",
    phone:"",
    country:"",
    state:"",
    address:"",
    message:""});
  }else{
    setErrorMsg(response.data.response);
  }
})
.catch(function (error) {
  console.log(error);
});
} else {
  setSuccessMsg('');
}
};
useEffect(() => {
if (showPopover && inputRef.current) {
inputRef.current.focus();
}
}, [showPopover]);
const validateInput = () => {
const fields = [
{
  name: 'name',
  value: details.name,
  message: 'Name should not be blank.'
},
{
  name: 'email',
  value: details.email,
  message: 'Email should not be blank.'
},
{
  name: 'country',
  value: details.country,
  message: 'County should not be blank.'
},
{
  name: 'state',
  value: details.state,
  message: 'Regions should not be blank.'
},
{
  name: 'address',
  value: details.address,
  message: 'Address should not be blank.'
}
]
const isNotFilled = fields.some(field => {
if (field.value.trim() === '') {
  setErrorMsg(field.message);
  return true;
}
setErrorMsg('');
return false;
});
return isNotFilled;
};

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter",
  },
  {
    title: "💌 EMAIL",
    desc: "nc.example@example.com",
  },
  {
    title: "☎ PHONE",
    desc: "000-123-456-7890",
  },
];

  return (
    <div
      className={`nc-PageBecomeHostJapan overflow-hidden relative ${className}`}
      data-nc-id="PageBecomeHostJapan"
    >
      <Helmet title={'Become a Host - Tiny Away | Land Partners for Tiny Houses | Host a Tiny'}></Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="py-16 lg:py-18 space-y-16 lg:space-y-28 pt-0">
      {/* <SectionBecomeHostVideo /> */}
     <img src="https://cdn.tinyaway.com/common/We-are-expanding-into-Japan.jpg.webp"></img>
     </div>
     <div className="container py-16 lg:py-18 space-y-16 lg:space-y-28 pt-0">
      <div className="our-tiny becomehost-content pl-50 pr-50">
           
           <div className="meet-our-sled">
            {/* <h4 className="mt-50">MEET OUR</h4>
            <h4 className="mb-50">LAND HOSTS COMMUNITY</h4> */}

            <SectionLandHostCommunity
                heading="MEET OUR 
                LAND HOSTS COMMUNITY"
                subHeading="私たちのランドホストコミュニティ                "
                className="hedding-center"
                categoryCardType="card8"
                categories={DEMO_CATS}
                itemPerRow={3}
              />
              
           </div>

          <div className="content-becomehost">
          <h3 className="mt-70">DO YOU HAVE SPARE LAND IN JAPAN?</h3>
          <p className="pmb-20">Tiny Away works towards disrupting the current tourism accommodation – in an eco-friendly, sustainable and highly efficient kind of way. We are working hard to reconnect people with nature and curate the perfect escape from a hectic and digitally-laden lifestyle.
          </p>
          <p className="pmb-20">Tiny Away manages almost 200 tiny houses in Australia and New Zealand. We are expanding into Japan.</p>
          <p className="pmb-20">Get in touch with us and receive an information pack on hosting a tiny house. We will also let you know how much you can earn from hosting. No obligations. </p>
          
          <h3 className="mt-70">日本で余分な土地を持っていませんか？</h3>
          <p className="pmb-20">Tiny Away は、現在の観光宿泊施設を混乱させる方向に取り組んでいます – 環境にやさしく、持続可能で、非常に効率的な方法で。 私たちは、人々を自然と再接続し、多忙でデジタルを多用するライフスタイルからの完璧な脱出をキュレートするために懸命に取り組んでいます。
          </p>
          <p className="pmb-20">私たちはタイニーハウスのホストを探しています。様々な土地や資産があるため、より沢山の人達にあなたの所有する素敵な土地を体験してもらいたいと思っています。 人々が都会を離れ、自然の中で充実したひとときを楽しむように促す旅に参加してください。
           </p>
           <p className="pmb-20">ご不明な点がある場合は、いつでもご連絡お待ちしております。喜んで必要な詳細をお伝え致します。また、ホスティングでいくら稼げるかもお伝えすることが出来ます。こちらは義務ではありませんので、お気軽にお知らせくださいね。
            </p>




          <h3 className="mt-70 text-left mb-2">Channel 7 Interview on Hosting a Tiny House (Australia) | タイニーハウスのホストのインタビュー(オーストラリア)</h3>
          <iframe width="100%" height="642" src="https://www.youtube.com/embed/G1dbehZk2g8" title="Tiny House Host Interview Series - Chatting with Helen, Saddle Camp at Braidwood" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
          
          </div>

  <div className="flex-list-number-content">
    <div className="list-text">
      <div className="number-listing">
        <span>01</span>
      </div>
      <div className="right-content">
        <h4>NO CAPITAL REQUIRED</h4>
        <p>You do not need to put in any upfront capital to start this. To reinforce a sense of escapism, we will provide you with a fully furnished tiny house, with each one blending seamlessly into its natural surroundings and offering a local connection to the guest.
       </p>
       <h4>資本金不要</h4>
       <p>前払いの 資金は必要ありません。現実逃避をより味わっていただく為に、家具付きのタイニーハウス、自然に溶け込んだ環境、そして地元のつながりをゲストに提供します。</p>
      
      </div>
    </div>

    <div className="list-text">
      <div className="number-listing">
        <span>02</span>
      </div>
      <div className="right-content">
        <h4>FLEXIBILITY</h4>
        <p>As our tiny house host, you have the option to receive a higher income by providing utilities (water, gas and electricity), and help to keep the house clean. You also have the flexibility to simply provide the land and we will do the rest.</p>
     <h4>柔軟性</h4>
     <p>タイニーハウスのホストとして、公益設備(水、ガス、電気)を提供する事、そしてハウスの清掃など、より高い収入を受け取るオプションがあります。
また、ただ土地を提供するだけで残りのサービスは私たちが行うというオプションもあります。</p>
      </div>
    </div>

    <div className="list-text">
      <div className="number-listing">
        <span>03</span>
      </div>
      <div className="right-content">
        <h4>MAKE NEW FRIENDS</h4>
        <p>If you like meeting new friends from all over the world, this is perfect for you. As our tiny house hosts, you will be our key point of contact with guests and help ensure that they have an awesome stay.</p>
      <h4>新しい友達作り</h4>
      <p>もし世界中から来た人々に会って友達を作る事が好きでしたら、これは最高の機会です。あなたはゲストとの重要な連絡窓口となり、最高に楽しい滞在のお手伝いをして頂きます。</p>
      </div>
    </div>

    <div className="list-text">
      <div className="number-listing">
        <span>04</span>
      </div>
      <div className="right-content">
        <h4>DO YOU HAVE A UNIQUE RURAL PROPERTY?</h4>
        <p>We’re looking for unique accommodation in non-urban areas with beautiful nature and outdoor activities nearby. We’ve got the tools to help you host with confidence. We handle all the marketing, bookings and customer service. Together we curate a memorable experience at all stages of the guest journey.</p>
     <h4>ユニークな田舎の土地を持っていますか？</h4>
     <p>私たちは、美しい自然とアウトドアアクティビティが出来る田舎地方の宿泊施設を探しています。自信を持ってホスト出来るお手伝いをしますのでご安心ください。 すべてのマーケティング、予約、カスタマーサービスは私たちが行うので心配は入りません。ゲストの楽しい思い出に残るような体験を一緒に作りましょう！</p>
      </div>
    </div>

    <div className="list-text">
      <div className="number-listing">
        <span>05</span>
      </div>
      <div className="right-content">
        <h4>EARN RENTAL INCOME</h4>
        <p>Best of all, you will get to earn from the rental of the tiny house while inspiring people to get out of the city and reconnect with nature. If you are running a business on the property, it complements and bring you more customers.</p>
      <h4>  レンタル収入を得る</h4>
<p> 何よりも、街から出て自然と繋がる手助けをしながら、タイニーハウスの賃貸で稼ぐことができます。あなたの土地でビジネスを運営している場合は、更なる集客を確保することが出来ます。</p>
      </div>
    </div>



  </div>

<div className="form-content-left">
  <h5 className="mt-100">ホスティングでいくら稼げるかを調べる</h5>
  {successMsg && 
  <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
  {successMsg}
  </div>}
  {errorMsg && 
  <div className="alert alert-warning" role="warning alert"><span className="las la-exclamation-circle"></span>
  {errorMsg}</div>}
  <form className="grid grid-cols-2 gap-6" action="#" onSubmit={submitHandler} autoComplete="off" method="post">
      <label className="block">
        <Label>Name <span className="red">*</span></Label>
        <Input
          type="text"
          placeholder=""
          name="name"
          id="name"
          onChange={event=>setDetails({...details,name:event.target.value})}
          ref={NameRef}
          autoComplete="off"
          autoFocus={showPopover}
          value={details.name}
        />
      </label>
      <label className="block">
        <Label>Email <span className="red">*</span></Label>
        <Input
          type="email"
          placeholder=""
          name="email"
          id="email"
          onChange={event=>setDetails({...details,email:event.target.value})}
          ref={EmailRef}
          autoComplete="off"
          autoFocus={showPopover}
          value={details.email}
        />
      </label>
      <label className="block">
        <Label>Phone</Label>
        <Input
          type="text"
          placeholder=""
          name="phone"
          id="phone"
          onChange={event=>setDetails({...details,phone:event.target.value})}
          ref={NameRef}
          autoComplete="off"
          autoFocus={showPopover}
          value={details.phone}
        />
      </label>
      <label className="block">
        <Label>Country  <span className="red">*</span></Label>
        <Select className="mt-1.5"
          name="country"
          id="country"
          onChange={event=>setDetails({...details,country:event.target.value})}
          autoComplete="off"
          value={details.country}
          >
            <option value="">Select</option>
            <option value="Japan">Japan</option>
      </Select>
      </label>
      <label className="block">
        <Label>Regions<span className="red">*</span></Label>
        <Select className="mt-1.5"
          name="state"
          id="state"
          onChange={event=>setDetails({...details,state:event.target.value})}
          autoComplete="off"
          value={details.state}
          >
            <option value="">Select</option>
            <option value="Hokkaido">Hokkaido</option>
            <option value="Tohoku">Tohoku</option>
            <option value="Kanto">Kanto</option>
            <option value="Chubu">Chubu</option>
            <option value="Kinki">Kinki</option>
            <option value="Chugoku">Chugoku</option>
            <option value="Shikoku">Shikoku</option>
            <option value="Kyushu-Okinawa">Kyushu-Okinawa</option>
      </Select>
      </label>
      <label className="block">
        <Label>Address    <span className="red">*</span></Label>
        <Input
          type="text"
          placeholder=""
          name="address"
          id="address"
          onChange={event=>setDetails({...details,address:event.target.value})}
          ref={AddressRef}
          autoComplete="off"
          autoFocus={showPopover}
          value={details.address}
        />
      </label>
      <label className="block">
        <Label>Message</Label>
        <Textarea 
          placeholder=""
          name="message"
          id="message"
          onChange={event=>setDetails({...details,message:event.target.value})}
          autoComplete="off"
          autoFocus={showPopover}
          value={details.message} rows={6} />
      </label>
      <div></div>
      <div>
        <ButtonPrimary type="submit">Subscribe</ButtonPrimary>
      </div>
  </form>
</div>


  <h3 className="mt-70 text-left mb-2">Channel 7 Interview on Hosting a Tiny House (Australia) | タイニーハウスのホストのインタビュー(オーストラリア)</h3>
  <iframe width="100%" height="642" src="https://www.youtube.com/embed/usnixd-5wPM" title="Channel 7 Interview with Tiny Away" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
  
  <h3 className="mt-70 text-left mb-2">The Business Times Interview (Singapore) | ニュースインタビュー（シンガポール）</h3>
  <iframe width="100%" height="642" src="https://www.youtube.com/embed/hZXUZ9LsdDk" title="Big Tiny - Business Times (Adrian)"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
    </div>
      </div>
    </div>
  );
};

export default PageBecomeHostJapan;
