import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import convertNumbThousand from "utils/convertNumbThousand";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";

export interface CardCategory7Props {
  className?: string;
  taxonomy: TaxonomyType;
  listingType?: string;
  defaultPoint?: string;
  public_review?: string;
  review_date?: string;
}

const CardCategory7: FC<CardCategory7Props> = ({
  className = "",
  taxonomy,
  listingType,
  defaultPoint,
}) => {
  const { count, name, href = "/",public_review, thumbnail,review_date} = taxonomy;
  
  return (
    
    <Link
      to={href}
      className={`nc-CardCategory7 flex flex-col ${className}`}
      data-nc-id="CardCategory7"
    >
      <div
        className={`flex-sled-customers-reviews`}
      >
        
        <div className="stars-images">
        <NcImage
          src={thumbnail}
          className="object-cover filter-grayscale max-h70"
        /> 
        <div className="name-stars">
        <h2>{name}</h2>
        <span className="tiny_showdate_reviews">{review_date}</span>
        <FiveStartIconForRate defaultPoint={count} iconClass="w-6 h-6" className="space-x-0.5" />
       </div>

        </div>

        <div className="reviews-comment">
      <p>{public_review}</p>
      <img className="icon-reviews-logo" src="https://cdn.tinyaway.com/common/airbnb_outline.png"></img>
      
    </div>

      </div>
     
    </Link>
  );
};

export default CardCategory7;
