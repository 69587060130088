import React, { FC,useState,useEffect, } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { Disclosure } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
// import { NAVIGATION_DEMO } from "data/navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SocialsList from "shared/SocialsList/SocialsList";
import { ChevronDownIcon } from "@heroicons/react/solid";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import axios from 'axios';  
import Switch from "react-switch";


export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
  menuData:any[];
  targetlanguage?:string;
}

const NavMobile: React.FC<NavMobileProps> = ({
  menuData,
  onClickClose,
  targetlanguage  = localStorage.getItem("targetlanguage") ? localStorage.getItem("targetlanguage") : (window.location.hostname=='tinyaway.com.tw' || window.location.hostname=='www.tinyaway.com.tw')?"zh_tw":"en",
}) => {
  const [checked, setChecked]                           =  useState(targetlanguage=='en'?false:true);
  let search                                                = window.location.search;
  let params                                                = new URLSearchParams(search);
  let aic                                                   = params.get('aic');
  let utm_source                                            = params.get('utm_source');
  if(utm_source!=null && utm_source!=''){
    const obj = {
        referralCanyCode: aic,
        utm_source: utm_source
    };
    localStorage.setItem('referralCandy', JSON.stringify({ ...obj }));
  }

  const handleChange = (nextChecked:any) => {
    setChecked(nextChecked);
    if(checked){
      localStorage.setItem("targetlanguage","en");
    }else{
      localStorage.setItem("targetlanguage","zh_tw");
    }
    window.location.reload();
  }
  const _renderMenuChild = (item: NavItemType) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <NavLink
              exact
              strict
              to={{
                pathname: i.href || undefined,
              }}
              target={i.targetBlank ? "_blank" : undefined}
              className="flex px-4 py-2.5 text-neutral-900 dark:text-neutral-200 text-sm font-medium rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-[2px]"
              activeClassName="text-secondary"
            >
              <span
                className={!i.children ? "block w-full" : ""}
                onClick={onClickClose}
              >
                {i.name}
              </span>
              {i.children && (
                <span
                  className="block flex-grow"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="flex justify-end flex-grow"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-neutral-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.children && (
              <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure
        key={item.id}
        as="li"
        className="text-neutral-900 dark:text-white"
      >
        <NavLink
          exact
          strict
          className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
          to={{
            pathname: item.href || undefined,
          }}
          target={item.targetBlank ? "_blank" : undefined}
          activeClassName="text-secondary"
        >
          <span
            className={!item.children ? "block w-full" : ""}
            onClick={onClickClose}
          >
            {item.name}
          </span>
          {item.children && (
            <span
              className="block flex-grow"
              onClick={(e) => e.preventDefault()}
            >
              <Disclosure.Button
                as="span"
                className="flex justify-end flex-grow"
              >
                <ChevronDownIcon
                  className="ml-2 h-4 w-4 text-neutral-500"
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </span>
          )}
        </NavLink>
        {item.children && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };
  
  return (
    <div className="overflow-y-auto w-full max-w-sm h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-3 px-5">
        <Logo />
        <div className="flex flex-col mt-0 text-neutral-700 dark:text-neutral-300 text-sm">
         <div className="flex justify-between items-center mt-4">
            <SocialsList itemClass="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300" />
            {window.location.hostname=='tinyaway.com.tw' || window.location.hostname=='www.tinyaway.com.tw' ?
            <div className="flex gap-2 change-languege change-languege-mobile py-1"><span>ENG</span> <Switch onColor={'#f15e75'} onChange={handleChange} checked={checked} /> <span>CN</span></div>
            :""}
          </div>
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>
      <ul className="flex flex-col py-6 px-2 space-y-1">
        {menuData.map(_renderItem)}
      </ul>
      <div className="flex items-center justify-between py-3 px-5 space-x-4">
        <a href="/host-a-tiny-house" rel="noopener noreferrer">
          <ButtonPrimary>Become a host</ButtonPrimary>
        </a>
      </div>
    </div>
  );
};

export default NavMobile;
