import React, { FC, Fragment,ReactNode,useEffect,useState,useRef } from "react";
import { useParams } from 'react-router-dom';
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon } from "@heroicons/react/outline";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import StartRating from "components/StartRating/StartRating";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import { DayPickerRangeController, FocusedInputShape } from "react-dates";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridSimilarListing from "./SectionGridSimilarListing";
import SectionGridSimilarStories from "./SectionGridSimilarStories";
import { Helmet } from "react-helmet";
import axios from 'axios'; 
import { convertCompilerOptionsFromJson } from "typescript";
import { Route, Redirect,useHistory,Link} from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import facebookSvg from "images/Facebook.svg";
import googleSvg from "images/Google.svg";
import { FacebookShareButton, TwitterShareButton,WhatsappShareButton,PinterestShareButton,LinkedinShareButton,EmailShareButton,FacebookMessengerShareButton} from "react-share";
import { FacebookIcon, TwitterIcon,WhatsappIcon,PinterestIcon,LinkedinIcon,EmailIcon } from "react-share";
import ReCAPTCHA from "react-google-recaptcha";
import useCopy from 'use-copy';


const { REACT_APP_SITE_KEY }      = process.env;
export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
  autoFocus?: boolean;
  DefaultUserID?: number;
}
const ListingStayDetailPage: FC<ListingStayDetailPageProps> = ({
  className = "",
  isPreviewMode,
  autoFocus = false,
  DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : 0
}) => {
  const [isOpen, setIsOpen]                                                         = useState(false);
  const [openFocusIndex, setOpenFocusIndex]                                         = useState(0);
  const [selectedDate, setSelectedDate]                                             = useState<DateRage>({
    /*startDate: moment(),
    endDate: moment().add(1, "days"),*/
    startDate: null,
    endDate: null,
  });
  const params                                                                      = useParams();
  const slug                                                                        = Object.values(params);
  const history                                                                     = useHistory();
  const [isdataLoaded, setIsdataLoaded]                                             = useState(false);
  const [datall, setAatall]                                                         = useState([]);
  const [imgUrl1, setimgUrl]                                                        = useState([]);
  const [authorname, setAuthorname]                                                 = useState([]);
  const [hostinfo, setHostinfo]                                                     = useState("");
  const [totalhosted, setTotalhosted]                                               = useState(0);
  const [title, setTitle]                                                           = useState("");
  const [beds, setBeds]                                                             = useState([]);
  const [bedrooms, setBedrooms]                                                     = useState([]);
  const [bath, setBath]                                                             = useState([]);
  const [maxguest, setMaxguest]                                                     = useState(0);
  const [maxAdults, setMaxAdults]                                                   = useState(0);
  const [maxChilds, setMaxChilds]                                                   = useState(0);
  

  const [PHOTOS, setPHOTOS]                                                         = useState([]);
  const [listingaddress, listingAddress]                                            = useState([]);
  const [price, setPrice]                                                           = useState([]);
  const [descriptionsummary, setDescriptionsummary]                                 = useState([]);
  const [descriptionspace, setDescriptionspace]                                     = useState([]);
  const [descriptionaccess, setDescriptionaccess]                                   = useState([]);
  const [descriptionnotes, setDescriptionnotes]                                     = useState([]);
  const [descriptionneighborhood, setDescriptionneighborhood]                       = useState([]);
  const [reviewStart, setReviewStart]                                               = useState(0);
  const [reviewCount, setReviewCount]                                               = useState(0);
  const [mapzoom, setMapzoom]                                                       = useState(6);
  const [currency_code, Setcurrency_code]                                           = useState([]);
  const [allReviews, SetAllreviews]                                                 = useState([]);
  const [metatitle, SetMetatitle]                                                   = useState("");
  const [metadata, SetMetaData]                                                     = useState([]);
  const [mapcoordinates, setMapcoordinates]                                         = useState({
    "lat": -37.3218879,
    "lng": 145.3109719
});
  const [maplat, setMaplat]                                                         = useState(-37.3218879);
  const [maplng, setMaplng]                                                         = useState(145.3109719);
  const [maplatSimilar, setMaplatSimilar]                                           = useState("");
  const [maplngSimilar, setMaplngSimilar]                                           = useState("");
  const [propertyArea, SetpropertyArea]                                             = useState([]);
  const [listingType, SetlistingType]                                               = useState([]);
  const [defaultCheckInTime, SetDefaultCheckInTime]                                 = useState([]);
  const [defaultCheckOutTime, SetDefaultCheckOutTime]                               = useState([]);
  const [NightlyPrice, SetNightlyPrice]                                             = useState([]);
  const [WeekendsPrice, SetWeekendsPrice]                                           = useState([]);
  const [WeeklyPrice, SetWeeklyPrice]                                               = useState([]);
  const [MonthlyPrice, SetMonthlyPrice]                                             = useState([]);
  const [CleaningFee, SetCleaningFee]                                               = useState([]);
  const [AdditionGuestPrice, SetAdditionGuestPrice]                                 = useState([]);
  const [AdditionGuestAllowed, SetAdditionGuestAllowed]                             = useState([]);
  const [weekdaysname, SetWeekdaysname]                                             = useState([]);
  const [amenities, SetAmenities]                                                   = useState<any[]>([]);
  const [totalAmenities, SettotalAmenities]                                         = useState<any[]>([]);
  const [totalReviews, SettotalReviews]                                             = useState<any[]>([]);
  const [allowsChildren, SetallowsChildren]                                         = useState<any[]>([]);
  const [allowsInfants, SetallowsInfants]                                           = useState<any[]>([]);
  const [allowsSmoking, SetallowsSmoking]                                           = useState<any[]>([]);
  const [allowsEvents, SetallowsEvents]                                             = useState<any[]>([]);
  const [allowsPets, SetallowsPets]                                                 = useState<any[]>([]);
  const [addition_information, Setaddition_information]                             = useState<any[]>([]);
  const [mobile_reception, Setmobile_reception]                                     = useState<any[]>([]);
  const [outdoor_fire_pit, Setoutdoor_fire_pit]                                     = useState<any[]>([]);
  const [firewood_provided_free, Setfirewood_provided_free]                         = useState<any[]>([]);
  const [firewood_at_extra_cost, Setfirewood_at_extra_cost]                         =  useState("");
  const [acctivities_and_potential_wildlife, Setacctivities_and_potential_wildlife] = useState("");
  const [how_isolated_is_the_tiny_house, Sethow_isolated_is_the_tiny_house]         =  useState("");
  const [cancellation_policy, Setcancellation_policy]                               = useState<any[]>([]);
  const [Pet_frindly, SetPet_frindly]                                               = useState<any[]>([]);
  const [guestyListingId, setguestyListingId]                                       = useState("");
  const [calendarMessage, setCalendarMessage]                                       = useState("");
  const [successMsg, setSuccessMsg]                                                 = useState("");
  const [priceBreakDown, setPriceBreakDown]                                         = useState("");
  const [adults, setAdults]                                                         = useState(2);
  const [children, setChildren]                                                     = useState(0);
  const [infants, setInfants]                                                       = useState(0);
  const [availableDates, setavailableDates]                                         = useState("");
  const [checkoutOnlyDates, CheckoutOnlyDates]                                      = useState("");
  const [selectedDateForyear, setSelectedDateForyear]                               = useState<DateRage>({
    startDate: moment(),
    endDate: moment().add(12, "month"),
  });
  const [details,setDetails]                                                        = useState({email:"",password:""});
  const [showPopover, setShowPopover]                                               = useState(autoFocus);
  const [error,setError]                                                            = useState('');
  const [login, setLogin]                                                           = useState(false);
  const firstNameRef                                                                = useRef<HTMLInputElement>(null);
  const lastNameRef                                                                 = useRef<HTMLInputElement>(null);
  const emailRef                                                                    = useRef<HTMLInputElement>(null);
  const passwordRef                                                                 = useRef<HTMLInputElement>(null);
  const [errorMsg, setErrorMsg]                                                     = useState('');
  const [success,setsuccess]                                                        = useState('');
  const [user_id,setUserId]                                                         = useState(DefaultUserID);
  const [pageLink,setPageLink]                                                      = useState("");
  const [cityName,setCityName]                                                      = useState("");
  const [stateName,setStateName]                                                    = useState("");
  const [countryName,setCountryName]                                                = useState("");
  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate]             = useState<FocusedInputShape>("startDate");
  const [isOpenModalAmenities, setIsOpenModalAmenities]                             = useState(false);
  const [isOpenModalLoginSignUp, setIsOpenModalLoginSignUp]                         = useState(false);
  const [isModalHostContactUs, setIsModalHostContactUs]                             = useState(false);
  const [isOpenModalReviews, setisOpenModalReviews]                                 = useState(false);
  const [isOpenModalMobileBookingForm, setIsOpenModalMobileBookingForm]             = useState("hidden");
  const windowSize                                                                  = useWindowSize();
  const [detailsHostContact,setDetailsHostContact]                                  = useState({host_contact_name:"",host_contact_email:"",host_contact_phone:"",host_contact_message:"",google_captcha: ""});
  const captchaRef                                                                  = useRef<ReCAPTCHA>(null);
  const [checkInDetails,setCheckInDetails]                                          = useState({checkInCheckOutDate:{startDate:selectedDate.startDate,endDate:selectedDate.endDate},listing_id:guestyListingId,adults:adults,children:children,infants:infants});
  const [isOpenModalLikeShare, setIsOpenModalLikeShare]                             = useState(false);
  const [copied, copy, setCopied]                                                   = useCopy(pageLink);
  const [featuredImage, setFeaturedImage]                                           = useState("");

  const [errorcontact, setErrorContact]                                             = useState('');
  const [successcontact,setSuccessContact]                                          = useState('');
  const clientId                                                                    = process.env.REACT_APP_GMAIL_CLIENT_ID;

  
  

  useEffect(() => {
    setUserId(localStorage.getItem("user_id"));
  }, [DefaultUserID]);//

  useEffect(() => {
    setIsdataLoaded(false);
  }, [history.location.pathname]);//
  const loginSocials = [
    // {
    //   name: "Continue with Twitter",
    //   href: "#",
    //   icon: twitterSvg,
    // },
    {
      name: "Continue with Google",
      href: "#",
      icon: googleSvg,
    },
  ];
  const isOutsideRange = (day:any) => {
    return day.isBefore(moment().subtract(1, "days"));
  }
  useEffect(() => {
      if(selectedDate?.startDate!=null && selectedDate?.endDate!=null){
        axios.get(process.env.REACT_APP_BASE_URL+'check_booking_availability_on_date?listing_id='+guestyListingId+'&checkin='+selectedDate.startDate.format("YYYY-MM-DD")+'&checkout='+selectedDate.endDate.format("YYYY-MM-DD")+"&adults="+adults+"&children="+children+"&infants="+infants)
 	 .then(res => {
            if(res.data.success==true){
              setPriceBreakDown(res.data.response);
              setPrice(res.data.price_per_night);
              setCalendarMessage("");
              setSuccessMsg(res.data.message);
            }else{
              setCalendarMessage(res.data.message);
              setSuccessMsg("");
            }
        })
      }
  }, [selectedDate,adults,children]);
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL+'GetListingDetails?id='+slug)
 	 .then(res => {
          setAatall(res.data);
          setPageLink(res.data.href);
          setAuthorname(res.data.author_name);
          setHostinfo(res.data.hostinfo);
          setTotalhosted(res.data.total_hosted);
          SetAmenities(res.data.amenities);
          SetNightlyPrice(res.data.NightlyPrice);
          SetWeekendsPrice(res.data.WeekendsPrice);
          SetWeeklyPrice(res.data.WeeklyPrice);
          SetMonthlyPrice(res.data.MonthlyPrice);
          SetCleaningFee(res.data.CleaningFee);
          SetAdditionGuestPrice(res.data.AdditionGuestPrice);
          SetAdditionGuestAllowed(res.data.AdditionGuestAllowed);
          SettotalAmenities(res.data.totalAmenities);
          SettotalReviews(res.data.totalReviews);
          setimgUrl(res.data.author_pic);
          setTitle(res.data.title);
          setBeds(res.data.beds);
          setBedrooms(res.data.bedrooms);
          setBath(res.data.bathrooms);
          setMaxguest(res.data.maxGuests);
          setPHOTOS(res.data.galleryImgs);
          listingAddress(res.data.address);
          setPrice(res.data.price);
          setDescriptionsummary(res.data.publicDescription_summary);
          setDescriptionspace(res.data.publicDescription_space);
          setDescriptionaccess(res.data.publicDescription_access);
          setDescriptionnotes(res.data.publicDescription_notes);
          setDescriptionneighborhood(res.data.publicDescription_neighborhood);
          setReviewStart(res.data.reviewStart);
          setReviewCount(res.data.reviewCount);
          setMapcoordinates(res.data.map);
          Setcurrency_code(res.data.currency_code);
          setMaplat(res.data.map.lat);
          setMaplng(res.data.map.lng);
          setMapzoom(14);
          SetAllreviews(res.data.all_reviews);
          SetMetatitle(res.data.meta_title);
          SetMetaData(res.data.meta_data);
          SetpropertyArea(res.data.property_area);
          SetlistingType(res.data.listing_type);
          SetDefaultCheckInTime(res.data.defaultCheckInTime);
          SetDefaultCheckOutTime(res.data.defaultCheckOutTime);
          SetWeekdaysname(res.data.weekdaysname);
          SetallowsChildren(res.data.guestControls_allowsChildren);
          SetallowsInfants(res.data.guestControls_allowsInfants);
          SetallowsSmoking(res.data.guestControls_allowsSmoking);
          SetallowsEvents(res.data.guestControls_allowsEvents);
          SetallowsPets(res.data.guestControls_allowsPets);
          Setaddition_information(res.data.addition_information);
          Setmobile_reception(res.data.mobile_reception);
          Setoutdoor_fire_pit(res.data.outdoor_fire_pit);
          Setfirewood_provided_free(res.data.firewood_provided_free);
          Setfirewood_at_extra_cost(res.data.firewood_at_extra_cost);
          Setacctivities_and_potential_wildlife(res.data.acctivities_and_potential_wildlife);
          Sethow_isolated_is_the_tiny_house(res.data.how_isolated_is_the_tiny_house);
          Setcancellation_policy(res.data.cancellation_policy);
          SetPet_frindly (res.data.Pet_frindly);
          setMaplatSimilar(res.data.map.lat);
          setMaplngSimilar(res.data.map.lng);
          setguestyListingId(res.data.id);
          // setCheckInDetails({...checkInDetails,listing_id:res.data.id});
          setavailableDates(res.data.availability);
          setCityName(res.data.city);
          setStateName(res.data.state);
          setCountryName(res.data.country);
          setIsdataLoaded(true);
          setFeaturedImage(res.data.featuredImage);
          setMaxAdults(res.data.maxAdults);
          setMaxChilds(res.data.maxChilds);
    });   
  }, [history.location.pathname]);
  useEffect(() => {
    if(guestyListingId!='' && selectedDateForyear?.startDate!=null && selectedDateForyear?.endDate!=null){
      axios.get(process.env.REACT_APP_BASE_URL+'getBookedDates?listing_id='+guestyListingId+'&checkin='+selectedDateForyear?.startDate.format("YYYY-MM-DD")+'&checkout='+selectedDateForyear?.endDate.format("YYYY-MM-DD"))
      .then(res => {
            //setSuccessMsg("Date is available");
            //setPriceBreakDown("Date is available");
              console.log(res.data);
              setavailableDates(res.data.response);
              CheckoutOnlyDates(res.data.checkout_only_date)
          })
    }
  }, [guestyListingId]);

  const isBlocked = (day:any) => {
      return availableDates?.includes(day.format('YYYY-MM-DD'));
  }
  const responseFacebook = (response_fb:any) => {
    //console.log(response_fb);
    //setData(response_fb);
    if (response_fb.accessToken) {
      axios.post(process.env.REACT_APP_BASE_URL+'/UserRegistrationbySocial', {
        name        :response_fb.name,
        first_name  :response_fb.first_name,
        last_name   :response_fb.last_name,
        accessToken :response_fb.accessToken,
        email       :response_fb.email,
        userID      :response_fb.userID,
        picture     :response_fb.picture.data.url,
      })
    .then(function (response) {
      console.log(response.data.response);
      if(response.data.status==200){
        setsuccess('Successfully register');
        //setSuccessMsg('Successfully register');
        //setDetails({first_name:"",last_name:"",email:"",password:""});
        localStorage.setItem("user_id",response.data.user_details.user_id);
        history.push("/");
      }else{
        setError(response.data.response);
        setErrorMsg(response.data.response);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
      setLogin(true);
    } else {
      setLogin(false);
    }
  }
  const responseGoogle = (response_gmail:any) => {
    //console.log(response_gmail);
    if (response_gmail.accessToken) {
      axios.post(process.env.REACT_APP_BASE_URL+'/UserRegistrationbySocial', {
        name        :response_gmail.profileObj.name,
        first_name  :response_gmail.profileObj.givenName,
        last_name   :response_gmail.profileObj.familyName,
        accessToken :response_gmail.accessToken,
        email       :response_gmail.profileObj.email,
        userID      :response_gmail.profileObj.googleId,
        picture     :response_gmail.profileObj.imageUrl,
        platform    :"gmail"
      })
    .then(function (response) {
      console.log(response.data.response);
      if(response.data.status==200){
        setsuccess('Successfully register');
        setSuccessMsg('Successfully register');
        //setDetails({first_name:"",last_name:"",email:"",password:""});
        localStorage.setItem("user_id",response.data.user_details.user_id);
        history.push("/");
      }else{
        setError(response.data.response);
        setErrorMsg(response.data.response);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
      setLogin(true);
    } else {
      setLogin(false);
    }
  }
  const submitHandlerSignUp = (event: React.FormEvent<HTMLFormElement>) =>{
    event.preventDefault();
    console.log(details);
    const isInvalid = validateInput();
    if (!isInvalid) {
      axios.post(process.env.REACT_APP_BASE_URL+'UserLogin', details)
    .then(function (response) {
      console.log(response.data);
      if(response.data.status==200){
        localStorage.setItem("user_id",response.data.user_details.user_id);
        localStorage.setItem("display_name",response.data.user_details.display_name);
        localStorage.setItem("display_user_email",response.data.user_details.user_email);
        setsuccess(response.data.response);
        setDetails({email:"",password:""});
        //history.push("/");
        setIsOpenModalLoginSignUp(false);
        window.location.reload();
      }else{
        setError(response.data.response);
        setErrorMsg(response.data.response);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
    } else {
      setSuccessMsg('');
    }
  };
  const validateInput = () => {
    const fields = [
      {
        name: 'email',
        value: details.email,
        message: 'Email should not be blank.'
      },
      {
        name: 'password',
        value: details.password,
        message: 'Password should not be blank.'
      }
    ];
    const isNotFilled = fields.some(field => {
      if (field.value.trim() === '') {
        setErrorMsg(field.message);
        //field.name === 'email' ? emailRef.current.focus() : passwordRef.current.focus();
        return true;
      }
      setErrorMsg('');
      return false;
    });
    return isNotFilled;
  };
  const submitHandlerHostContact = (event: React.FormEvent<HTMLFormElement>) =>{
    event.preventDefault();
    const isInvalid = validateHostContactInput();
    if (!isInvalid) {
      axios.post(process.env.REACT_APP_BASE_URL+'guest_enquiries?listing_id='+guestyListingId, detailsHostContact)
    .then(function (response) {
      if (response.data.status == 200) {
        setSuccessContact(response.data.response);
        setDetailsHostContact({
          host_contact_name: "",
          host_contact_email: "",
          host_contact_phone: "",
          host_contact_message: "",
          google_captcha: ""
        });
      } else {
        setErrorContact(response.data.response);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
    } else {
      setSuccessMsg('');
    }
  }
  const validateHostContactInput = () => {
    const fields = [
      {
        name: 'host_contact_name',
        value: detailsHostContact.host_contact_name,
        message: 'Name should not be blank.'
      },
      {
        name: 'host_contact_email',
        value: detailsHostContact.host_contact_email,
        message: 'Email should not be blank.'
      },
      {
        name: 'host_contact_phone',
        value: detailsHostContact.host_contact_phone,
        message: 'Phone should not be blank.'
      },
      {
        name: 'host_contact_message',
        value: detailsHostContact.host_contact_phone,
        message: 'Phone should not be blank.'
      },
      {
        id: 'google_captcha',
        value: detailsHostContact.google_captcha,
        message: 'Captcha should not be blank.'
      }
    ];
    const isNotFilled = fields.some(field => {
      if (field.value.trim() === '') {
        setErrorContact(field.message);
        //field.name === 'email' ? emailRef.current.focus() : passwordRef.current.focus();
        return true;
      }
      setErrorContact('');
      return false;
    });
    return isNotFilled;
  };

  function SetRecaptchaVal(value: any) {
    setDetailsHostContact({ ...detailsHostContact, google_captcha:value});
  }

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) =>{
    event.preventDefault();



    
    if(selectedDate?.startDate!=null && selectedDate?.endDate!=null){
      if(guestyListingId!=''){
        console.log({checkInDetails, selectedDate});
        // setCheckInDetails({...checkInDetails,checkInCheckOutDate:selectedDate});
        axios.post(process.env.REACT_APP_BASE_URL+'addToCart', {
          checkInCheckOutDate: {startDate:selectedDate?.startDate.format("YYYY-MM-DD"),endDate:selectedDate?.endDate.format("YYYY-MM-DD")},
          listing_id: guestyListingId,
          adults:adults,
          children:children,
          infants:infants,
          user_id:DefaultUserID
        }).then(res => {
          if(res.data.status==401){
            setCalendarMessage(res.data.response);
          }
          if(res.data.status==200){
            history.push("/checkout");
            history.go(0);
          }
        });
      }
    }else{
      setCalendarMessage("Start and end date required");
    }
  }

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 48;
    }
    if (windowSize.width <= 1380) {
      return 40;
    }
    return 48;
  };
  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }
  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }
  function closeModalReviews() {
    setisOpenModalReviews(false);
  }
  function openModalReviews() {
    setisOpenModalReviews(true);
  }
  function OpenModalLoginSignUp() {
    setIsOpenModalLoginSignUp(true);
    setIsOpenModalMobileBookingForm("hidden");
  }
  function closeModalLoginSignUp() {
    setIsOpenModalLoginSignUp(false);
  }
  function OpenModalHostContactUs() {
    setIsModalHostContactUs(true);
  }
  function closeModalHostContactUs() {
    setIsModalHostContactUs(false);
  }
  function openModalBookingForm() {
    setIsOpenModalMobileBookingForm("show");
  }

  function closeModalBookingForm() {
    setIsOpenModalMobileBookingForm("hidden");
  }
  
  function OpenModalLikeSave() {
    setIsOpenModalLikeShare(true);
  }
  function CloseModalLikeSave() {
    setIsOpenModalLikeShare(false);
  }
  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };
  const handleCloseModal = () => setIsOpen(false);
  const renderSection1 = () => {
    return (
      
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        
        {/* 3 */}
        <div className="flex justify-between">
          <div>
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400 font-20-bold">
             
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
            Tiny House Hosted by {authorname}
            </span>
          </span>

          {/* <StartRating /> */}
           
          {/* <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {listingaddress}</span>
          </span> */}
          </div>
          <div className="flex items-center justify-end">
          <Avatar hasChecked sizeClass="h-15 w-15" radius="rounded-full"  avatarImage={imgUrl1.toString()} />
        </div>
        </div>
        {/* 4 */}
        

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              {maxguest} <span className="hidden sm:inline-block">guest</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              {beds} <span className="hidden sm:inline-block">bed</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              {bath} <span className="hidden sm:inline-block">bath</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              {bedrooms} <span className="hidden sm:inline-block">bedroom</span>
            </span>
          </div>
        </div>
      </div>
    );
  };
  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">About this listing</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          {descriptionsummary}
          <br />
          <br />
          <h1><b>The space</b></h1>
          <br />
          <span>{descriptionspace}</span>
          <br />
          <br />
          <h1><b>Guest access</b></h1>
          <br />
          <span>{descriptionaccess}</span>
          <br />
          <br />
          <h1><b>Other things to note</b></h1>
          <br />
          <span>{descriptionnotes}</span>
          <br />
          <br />
          <h1><b>Neighborhood</b></h1>
          <br />
          <span>{descriptionneighborhood}</span>
        </div>
      </div>
    );
  };
  const renderSectionTinyStories = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Tiny House Stories </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          Hear it from our guests. Here is a list of stories from people that have experienced a stay on this property
          </span>
        </div>
        <SectionGridSimilarStories listing_id={guestyListingId} />
      </div>
    );
  };

  const renderSectionProperDetails = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Details </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            About the property's details
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-2 xl:grid-cols-2 gap-8 text-sm text-neutral-700 dark:text-neutral-300  max-win-320">
          <div>
            <div  className="flex items-center space-x-3 mb-2">
              <i className={`text-2xl las la-user text-2xl `}></i>
              <span className="d-flex-box "><span className="span-250">Guest</span> <b>{maxguest}</b></span>
            </div>
            <div  className="flex items-center space-x-3 mb-2">
            <i className={`text-2xl las la-door-open`}></i>
              <span className="d-flex-box "><span className="span-250">Bedroom</span>  <b>{bedrooms}</b></span>
            </div>
          <div  className="flex items-center space-x-3 mb-2">
          <i className={`text-2xl las la-bed `}></i>
            <span className="d-flex-box "><span className="span-250">Bed</span>  <b>{beds}</b></span>
          </div>
          </div>
          <div>
            <div  className="flex items-center space-x-3  mb-2">
            <i className={`text-3xl all-cons Check-in`}></i>
              <span className=" d-flex-box"><span className="span-250">Check-in After</span>  <b>{defaultCheckInTime} </b></span>
            </div>
            <div  className="flex items-center space-x-3  mb-2">
            <i className={`text-3xl all-cons Check-out`}></i>
              <span className="d-flex-box "><span className="span-250">Check-out Before</span> <b>{defaultCheckOutTime}</b></span>
            </div>
            <div  className="flex items-center space-x-3  mb-2">
            <i className={`text-3xl all-cons speedometer`}></i>
              <span className="d-flex-box "><span className="span-250">Type</span> <b>{listingType}</b></span>
            </div>
            <div  className="flex items-center space-x-3  mb-2">
            <i className={`text-3xl all-cons property`}></i>
              <span className=" d-flex-box"><span className="span-250">Size</span> <b>{propertyArea}</b></span>
            </div>
            </div>
        </div>
      </div>
    );
  };
  const renderSectionlistingCommonQuestions = () => {
    return (
      <div className="listingSection__wrap icon-arrows-listing-detals">
        <div>
          <h2 className="text-2xl font-semibold">Common Questions </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            About the property's common questions
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-2 xl:grid-cols-2 gap-8 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
            <div  className="flex items-center space-x-3">
              <i className={`text-3xl las la la-location-arrow transform-icon`}></i>
              <span className="d-flex-box "><span className="span-250">Pets allowed</span><b>{allowsPets}</b></span>
            </div>
            <div  className="flex items-center space-x-3">
            <i className={`text-3xl las la la-location-arrow transform-icon`}></i>
              <span className="d-flex-box "><span className="span-250"> Children allowed</span><b>{allowsChildren}</b></span>
            </div>
            <div  className="flex items-center space-x-3">
            <i className={`text-3xl las la la-location-arrow transform-icon`}></i>
              <span className="d-flex-box "><span className="span-250"> Mobile reception</span><b>{mobile_reception}</b></span>
            </div>
            <div  className="flex items-center space-x-3">
            <i className={`text-3xl las la la-location-arrow transform-icon`}></i>
              <span className="d-flex-box "><span className="span-250">Outdoor fire pit</span><b>{outdoor_fire_pit}</b></span>
            </div>
            <div  className="flex items-center space-x-3">
            <i className={`text-3xl las la la-location-arrow transform-icon`}></i>
              <span className="d-flex-box "><span className="span-250">Firewood provided free</span><b>{firewood_provided_free}</b></span>
            </div>
        </div>
        {firewood_at_extra_cost!='' ? 
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-0  text-sm text-neutral-700 dark:text-neutral-300 ">
           <b>Firewood at extra cost</b>
           <p>{firewood_at_extra_cost}</p>
        </div>
        :""}
        {acctivities_and_potential_wildlife!=''? 
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-0 mb-20 text-sm text-neutral-700 dark:text-neutral-300 ">
          <b>Activities and potential wildlife</b>
          <p>{acctivities_and_potential_wildlife}</p>
        </div>
        :""}
        {how_isolated_is_the_tiny_house!='' ? 
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-0 mb-20 text-sm text-neutral-700 dark:text-neutral-300 ">
          <b>How isolated is the tiny house?</b>
          <p>{how_isolated_is_the_tiny_house}</p>
        </div>
        :""}
      </div>
    );
  };
  const renderSectionlistingTerms = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Terms & rules</h2>
          { <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            About the property's terms and rules
          </span>}
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 text-sm text-neutral-700 dark:text-neutral-300 ">
            <div  className="flex items-center space-x-3">
              <i className={`text-3xl las la la-location-arrow transform-icon`}></i>
              <span className="d-flex-box "><span className="span-250">Smoking allowed</span><b>{allowsSmoking}</b></span>
            </div>

            <div  className="flex items-center space-x-3">
            <i className={`text-3xl las la la-location-arrow transform-icon`}></i>
              <span className="d-flex-box "><span className="span-250">Party allowed</span><b>{allowsEvents}</b></span>
            </div>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-0  text-sm text-neutral-700 dark:text-neutral-300 ">
           <b>Cancellation Policy</b>
           <p>{cancellation_policy}</p>
        </div>
      </div>
    );
  };
  const renderSectionlistingPrice = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Prices </h2>
          { <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            About the property’s rates
          </span>}
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-2 xl:grid-cols-2 gap-8 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div  className="flex items-center space-x-3">
              <i className={`text-3xl las la la-location-arrow transform-icon`}></i>
              <span className="d-flex-box"><span className="span-250"> Nightly</span> <b>{NightlyPrice}</b></span>
            </div>
            <div  className="flex items-center space-x-3">
            <i className={`text-3xl las la la-location-arrow transform-icon`}></i>
              <span className="d-flex-box"><span className="span-250"> Weekends ({weekdaysname})</span>  <b>{WeekendsPrice}</b></span>
            </div>
            <div  className="flex items-center space-x-3">
            <i className={`text-3xl las la la-location-arrow transform-icon`}></i>
              <span className="d-flex-box"><span className="span-250"> Weekly (7d+)</span>  <b>{WeeklyPrice}</b></span>
            </div>
            <div  className="flex items-center space-x-3">
            <i className={`text-3xl las la la-location-arrow transform-icon`}></i>
              <span className=" d-flex-box"><span className="span-250">Monthly (30d+)</span>  <b>{MonthlyPrice}</b></span>
            </div>
    </div>
        <div>
            <div  className="flex items-center space-x-3">
            <i className={`text-3xl las la la-location-arrow transform-icon`}></i>
              <span className=" d-flex-box"><span className="span-250">Additional Guests</span> <b>{AdditionGuestPrice}</b></span>
            </div>
            <div  className="flex items-center space-x-3">
              <i className={`text-3xl las la la-location-arrow transform-icon`}></i>
              <span className=" d-flex-box"><span className="span-250">Allow Additional Guests</span> <b>{AdditionGuestAllowed}</b></span>
            </div>
            <div  className="flex items-center space-x-3">
              <i className={`text-3xl las la la-location-arrow transform-icon`}></i>
              <span className="d-flex-box"><span className="span-250">Cleaning Fee</span> <b>{CleaningFee}</b></span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderAmenities = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            About the property's amenities and services
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-2 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {amenities.filter((_, i) => i < 12).map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl all-cons ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            View all {totalAmenities} amenities
          </ButtonSecondary>
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };
  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {amenities.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-6 space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 all-cons ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const renderMotalReviews = () => {
    return (
      <Transition appear show={isOpenModalReviews} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalReviews}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Reviews
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalReviews} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                  {allReviews.filter((_, i) => i < 200).map((stay,index) => (
                    <CommentListing key={index} className="py-8" data={stay}/>
                  ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Room Rates </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday - Thursday</span>
              <span>$199</span>
            </div>
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday - Thursday</span>
              <span>$199</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Friday - Sunday</span>
              <span>$219</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Rent by month</span>
              <span>-8.34 %</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Minimum number of nights</span>
              <span>1 night</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Max number of nights</span>
              <span>90 nights</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Availability</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        <div className="listingSection__wrap__DayPickerRangeController flow-root z-0 CalendarMonthGrid_month_date">
          <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
            <DayPickerRangeController
              startDate={selectedDate.startDate}
              endDate={selectedDate.endDate}
              onDatesChange={(date) => ""}
              focusedInput={focusedInputSectionCheckDate}
              onFocusChange={(focusedInput) =>
                setFocusedInputSectionCheckDate(focusedInput || "startDate")
              }
              initialVisibleMonth={null}
              numberOfMonths={windowSize.width < 1300 ? 1 : 2}
              daySize={getDaySize()}
              hideKeyboardShortcutsPanel={false}
              isOutsideRange={isOutsideRange}
              isDayBlocked={isBlocked}
            />
          </div>
        </div>
      </div>
    );
  };
  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
            avatarImage={imgUrl1.toString()}
          />
          <div>
            <a className="block text-xl font-medium" href={void(0)}>
              {authorname}
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating reviewCount={totalhosted} point={5} isHost={true}  />
              <span className="mx-2">·</span>
              {/* <span> 12 places</span> */}
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
        {hostinfo}
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          {/*<div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>*/}
          {/*<div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>*/}
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
         {/* <ButtonSecondary href="##">See host profile</ButtonSecondary>*/}
        </div>
      </div>
    );
  };
  const renderReviews = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews ({reviewCount} reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800 grid grid-cols-2 gap-4 md:gap-4 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
        {allReviews.filter((_, i) => i < 5).map((stay,index) => (
          <CommentListing key={index} className="py-8" data={stay}/>
        ))}
          
          
          {renderMotalReviews()}
        </div>
        <div className="pt-4 text-center">
            <ButtonSecondary onClick={openModalReviews}>View all {totalReviews} reviews</ButtonSecondary>
          </div>
      </div>
    );
  };
  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap" id="scroll_to_location">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {listingaddress}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        
        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <div className="rounded-xl overflow-hidden">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyCxywmWV_Bz1FH-NgAgc-wwcVcD0-c8XYQ',
              }}
              defaultZoom={mapzoom}
              yesIWantToUseGoogleMapApiInternals
              defaultCenter={mapcoordinates}
              center={mapcoordinates}
              zoom={mapzoom}
            >
              <LocationMarker lat={maplat} lng={maplng}  />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    );
  };
  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Refund 50% of the booking value when customers cancel the room
            within 48 hours after successful booking and 14 days before the
            check-in time. <br />
            Then, cancel the room 14 days before the check-in time, get a 50%
            refund of the total amount paid (minus the service fee).
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>08:00 am - 12:00 am</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>02:00 pm - 04:00 pm</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and
                environment green and clean by not littering, not using
                stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };
  const renderSidebar = () => {
    return (
      <div className="listingSection__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
           <sup>{currency_code}</sup>{price}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /night
            </span>
          </span>
          <StartRating reviewCount={reviewCount} point={reviewStart} isHost={false}  />
        </div> 

        {/* FORM */}
        <form method="get" className="mt-15"  onSubmit={submitHandler}>
          <div className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl">
          <StayDatesRangeInput
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
            onChange={(date) => setSelectedDate(date)}
            numberOfMonths={2}
            fieldClassName="p-2"
            defaultValue={selectedDate}
            anchorDirection={windowSize.width > 1400 ? "left" : "right"}
            guetyListingId={guestyListingId}
            defaultFocus={null}
            availableDates={availableDates}
            highlightedDates={checkoutOnlyDates}
            
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            fieldClassName="p-2"
            defaultValue={{
              guestAdults: adults,
              guestChildren: children,
              guestInfants: infants,
            }}
            onChange={data => {
              setAdults(data.guestAdults ?? 0);
              setChildren(data.guestChildren ?? 0);
              setInfants(typeof data.guestInfants === 'undefined' ? 0 : data.guestInfants);
            }}
            MaxGuests={maxguest}
            MaxAdults={maxAdults}
            MaxChilds={maxChilds}
          />
            {/* <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="discount-div-part">
                <label className="block">
                  <Input
                    placeholder="Discount Code"
                    type="text"
                    className="mt-1"
                  />
                </label>
                <div className="guest-apply-btn">
                <ButtonPrimary>Apply</ButtonPrimary> 
                </div>
		        </div> */}
        {/* SUM */}
       
        {/* SUBMIT */}
        </div>
        <div className="d-flex d-flex-contact justify-center mt-1.5 w-100 btn-full mt-5">
        {DefaultUserID ?
        <ButtonPrimary type="submit">Add to cart</ButtonPrimary>
        :
        <ButtonPrimary onClick={OpenModalLoginSignUp} type="button">Add to cart</ButtonPrimary>
        }
        
        </div>
        </form>
        {/*the message div will be here*/}
        {calendarMessage && 
        <div className="alert alert-danger" role="alert">  <span className="las la-exclamation-circle"></span>{calendarMessage}</div>
        }
        {successMsg && 
          <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
          {successMsg}
          </div>}
          <div className="flex flex-col space-y-4 payment-detail-part">
          <div dangerouslySetInnerHTML={ {__html: priceBreakDown} } />

          {/* <div className="show-hide-payment-form">
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span><a href="">$ 170 x 2 nights</a></span>
                <span>$340.00</span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span><a href="">Cleaning fee</a></span>
                <span>$50.00</span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span><a href="">Service fees</a></span>
                <span>$19.50</span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span><a href="">Taxes 10%</a></span>
                <span>$40.95</span>
              </div>
            </div>
            <div className="border-b border-neutral-200 dark:border-neutral-700 mt-1"></div>
           
            <div className="flex justify-between font-semibold">
              <span className="font-size-16">Total</span>
              <span className="font-size-16">$199</span>
            </div> */}

          </div>
          {/* COMMENTED BELOW SECTION AS PER REQUEST OF INC-376 */}
        {/* <div className="grid mt-0 mb-0 top-none-main">
        <div className="page-tag-contact btn-full">
          <ButtonSecondary onClick={OpenModalHostContactUs}>
          Contact Tiny Away
          </ButtonSecondary> </div>
          
            <div className="grid md:grid-cols-6 gap-6 lg:grid-cols-6 xl:gap-8 dattal-social-icons"> 
              <div>
                <FacebookShareButton
                  title={title}
                  url={pageLink}
                  quote={"Testing"}
                  hashtag={"#tinyaway"}
                  className="">
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </div>
              <div>
              <TwitterShareButton
                title={title}
                url={pageLink}
                hashtags={["tinyaway", "discovernature","stayincomfort","tinyhouses","bigtiny"]}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              </div>
              <div>
              <WhatsappShareButton
                title={title}
                url={pageLink}
                separator={`\n${cityName}, ${stateName}, ${countryName}\n`}
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              </div>
              <div>
              <PinterestShareButton
                description={title}
                url={pageLink}
                media={pageLink}
              >
                <PinterestIcon size={32} round />
              </PinterestShareButton>
              </div>
              <div>
              <LinkedinShareButton
                title={title}
                url={pageLink}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              </div>
              <div>
              <EmailShareButton
                url={pageLink}
                subject="Sharing with you Tiny Away"
                body={"Hi!,"+`\n\n`+
                      "I know you always enjoy a good getaway. Please check out Tiny Away."+`\n\n\n`+
                      `${title}\n`+
                      `${listingaddress}\n\n`}>
                <EmailIcon size={32} round />
              </EmailShareButton>
              </div> 
            </div>
          </div> */}
          
      </div>
    );
  };
  const renderMotalLoginSignUp = () => {
    return (
      <Transition appear show={isOpenModalLoginSignUp} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto model-sm-min"
          onClose={closeModalLoginSignUp}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Login or Signup
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalLoginSignUp} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                  <div className="container mt-5">
                    <div className="max-w-md mx-auto space-y-6">
                      <div className="grid gap-3">
                      <FacebookLogin
                          appId="752989346684469"
                          autoLoad={false}
                          fields="email,name,picture,first_name,last_name"
                          scope="public_profile,email"
                          callback={responseFacebook}
                          version="3.1"
                          cookie={false}
                          icon="fa-facebook"
                          render={renderProps => (
                          <a href={void(0)} className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                            onClick={renderProps.onClick}
                          >
                              <img
                              className="flex-shrink-0"
                              src={facebookSvg}
                              alt="Continue with Facebook"
                            />
                            <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                              Continue with Facebook
                            </h3>

                          </a>
                          )}
                          />
                          <GoogleLogin
                              clientId={clientId}
                              render={renderProps => (
                                <button 
                                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                                onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                <img
                                  className="flex-shrink-0"
                                  src={googleSvg}
                                  alt="Continue with Google"
                                />
                                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                                  Continue with Google
                                </h3>
                                </button>
                              )}
                              buttonText="Login"
                              onSuccess={responseGoogle}
                              onFailure={responseGoogle}
                              cookiePolicy={'single_host_origin'}
                            />
                      </div>
                      {/* OR */}
                      <div className="relative text-center">
                        <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                          OR
                        </span>
                        <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
                      </div>
                      {success && 
                      <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
                      {success}
                      </div>}
                      {errorMsg && 
                      <div className="alert alert-warning" role="warning alert"><span className="las la-exclamation-circle"></span>
                      {errorMsg}</div>}
                      {/* FORM */}
                      <form className="grid grid-cols-1 gap-6"  onSubmit={submitHandlerSignUp}>
                        <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                            Email address
                          </span>
                          <Input
                            type="email"
                            placeholder=""
                            className="mt-1"
                            name="email"
                            id="email"
                            onChange={event=>setDetails({...details,email:event.target.value})}
                            ref={emailRef}
                            autoComplete="off"
                            autoFocus={showPopover}
                            value={details.email}
                          />
                        </label>
                        <label className="block">
                          <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                            Password
                            <Link to="/forgot-pass" className="text-sm">
                              Forgot password?
                            </Link>
                          </span>
                          <Input 
                            type="password" 
                            className="mt-1"
                            name="password"
                            id="password"
                            value={details.password}
                            onChange={event=>setDetails({...details,password:event.target.value})}
                            ref={passwordRef}
                            autoComplete="off"
                            autoFocus={showPopover}
                          />
                        </label>
                        <ButtonPrimary type="submit">Continue</ButtonPrimary>
                      </form>

                      {/* ==== */}
                      <span className="block text-center text-neutral-700 dark:text-neutral-300">
                        New user? {` `}
                        <Link to="/signup">Create an account</Link>
                      </span>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  }
  const renderModalHostContactUs = () => {
    return (
      <Transition appear show={isModalHostContactUs} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto model-sm-min"
          onClose={closeModalHostContactUs}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Contact Tiny Away
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalHostContactUs} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                  <div className="container mt-5">
                    <div className="max-w-md mx-auto space-y-6">
                      {/* <div className="grid gap-3">
                      </div> */}
                      {successcontact && 
                      <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
                      {successcontact}
                      </div>}
                      {errorcontact && 
                      <div className="alert alert-warning" role="warning alert"><span className="las la-exclamation-circle"></span>
                      {errorcontact}</div>}
                      {/* FORM */}
                      <form className="grid grid-cols-1 gap-6"  onSubmit={submitHandlerHostContact}>
                      <label className="block">
                          {/* <span className="text-neutral-800 dark:text-neutral-200">
                            Name
                          </span> */}
                          <Input
                            type="text"
                            placeholder="Name"
                            className="mt-1"
                            name="host_contact_name"
                            id="host_contact_name"
                            onChange={event=>setDetailsHostContact({...detailsHostContact,host_contact_name:event.target.value})}
                            ref={emailRef}
                            autoComplete="off"
                            autoFocus={showPopover}
                            value={detailsHostContact.host_contact_name}
                          />
                        </label>
                        <label className="block">
                          {/* <span className="text-neutral-800 dark:text-neutral-200">
                            Email address
                          </span> */}
                          <Input
                            type="email"
                            placeholder="Email"
                            className="mt-1"
                            name="host_contact_email"
                            id="host_contact_email"
                            onChange={event=>setDetailsHostContact({...detailsHostContact,host_contact_email:event.target.value})}
                            ref={emailRef}
                            autoComplete="off"
                            autoFocus={showPopover}
                            value={detailsHostContact.host_contact_email}
                          />
                        </label>
                        <label className="block">
                          {/* <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                            Phone
                            <Link to="/forgot-pass" className="text-sm">
                              Forgot password?
                            </Link>
                          </span> */}
                          <Input 
                            type="text" 
                            placeholder="Phone"
                            className="mt-1"
                            name="host_contact_phone"
                            id="host_contact_phone"
                            value={detailsHostContact.host_contact_phone}
                            onChange={event=>setDetailsHostContact({...detailsHostContact,host_contact_phone:event.target.value})}
                            ref={passwordRef}
                            autoComplete="off"
                            autoFocus={showPopover}
                          />
                        </label>
                        <label className="block">
                          {/* <span className="text-neutral-800 dark:text-neutral-200">
                            Message
                          </span> */}
                          <Textarea
                            placeholder="Message"
                            className="mt-1"
                            name="host_contact_message"
                            id="host_contact_message"
                            onChange={event=>setDetailsHostContact({...detailsHostContact,host_contact_message:event.target.value})}
                            autoComplete="off"
                            autoFocus={showPopover}
                            value={detailsHostContact.host_contact_message}
                            rows={4}
                          />
                        </label>
                        <ReCAPTCHA
                          // size="invisible"
                          sitekey="6LeBUm4jAAAAANOeGumtzoKLckycxi0aSp6Cm0Mk"
                          ref={captchaRef}
                          id="google_captcha"
                          onChange={SetRecaptchaVal}
                        />
                        <ButtonPrimary type="submit">Continue</ButtonPrimary>
                      </form>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const renderskeletonLoader = () => {
    return (
      <div>
      <div className="banner-loader-detal-page relative  gap-1 sm-full-1-img custom-loader mb-5 mt-5">
        <div className="p-3 bg-grey skeleton relative overflow-hidden w-1/2 mb-2"></div>
        <div className="bg-grey skeleton relative overflow-hidden w-1/100 h-5"></div>
      </div>
      <div className="banner-loader-detal-page relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2 sm-full-1-img custom-loader">
        <div className="bg-grey col-span-2 cursor-pointer h-400 overflow-hidden p-3 relative rounded-md row-span-3 skeleton sm:rounded-xl sm:row-span-2"></div>
        <div className="p-3 bg-grey skeleton relative rounded-md sm:rounded-xl overflow-hidden "></div>
        <div className="p-3 bg-grey skeleton relative rounded-md sm:rounded-xl overflow-hidden "></div>
        <div className="p-3 bg-grey skeleton relative rounded-md sm:rounded-xl overflow-hidden "></div>
        <div className="p-3 bg-grey skeleton relative rounded-md sm:rounded-xl overflow-hidden "></div>
      </div>
      <div className="banner-loader-detal-page custom-loader lg:flex-row gap-3 mt-5 mb-5 flex">
        <div className="w-70-loader  lg:w-3/5 xl:w-2/3 border-neutral-200  sm:rounded-2xl sm:border">
        <div className="card m-0">
        <div className="card-body">
        <div className="row mb-5">
        <div className="col-12">
        <div className="p-3 bg-grey skeleton"></div>
        <div className="col-3 mt-1"><div className="p-2 bg-grey skeleton"></div></div></div></div></div></div></div>
        <div className="w-30-loader hidden lg:block flex-grow border-neutral-200 sm:rounded-2xl sm:border">
        <div className="card m-0">
        <div className="card-body">
        <div className="row mb-1"></div>
        <div className="row mb-4"><div className="col-12">
        <div className="p-3 bg-grey skeleton"></div></div></div>
        <div className="mb-4">
        <div className="row mb-3 justify-content-between">
        <div className="col-4">
        <div className="p-2 bg-grey skeleton"></div></div>
        <div className="col-3">
        <div className="p-2 bg-grey skeleton"></div></div></div>
        <div className="row mb-3 justify-content-between">
        <div className="col-4">
        <div className="p-2 bg-grey skeleton"></div></div>
        <div className="col-3 justify-content-between">
        <div className="p-2 bg-grey skeleton"></div></div></div></div></div></div></div>
      </div>
      </div>
    );
  };
  const renderModalLikeSave = () => {
    return (
      <Transition appear show={isOpenModalLikeShare} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto model-sm-min" onClose={CloseModalLikeSave}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>
  
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">Share this place</h3>
                    <span className="absolute left-3 top-3"><ButtonClose onClick={CloseModalLikeSave} /></span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                  <div className="container mt-5">
                    <div className="mx-auto space-y-0">
                     <div className="share-img-name">
                      <div><img src={featuredImage} /></div>
                      <p className="font-medium">{title} · ★{reviewStart} · {bedrooms} bedroom · {beds} bed · {bath} bathroom</p>
                     </div>
  
                     <div className="flex-brap-box">
                      <a href={void(0)} onClick={copyText}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false"><path d="M25 5a4 4 0 0 1 4 4v17a5 5 0 0 1-5 5H12a5 5 0 0 1-5-5V10a5 5 0 0 1 5-5h13zm0 2H12a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V9a2 2 0 0 0-2-2zm-3-6v2H11a6 6 0 0 0-5.996 5.775L5 9v13H3V9a8 8 0 0 1 7.75-7.996L11 1h11z"></path></svg>
                        <span>Copy Link</span>
                      </a>
                      <a href={void(0)}>
                      <EmailShareButton title={title} url={pageLink}>
                      <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M213.691 222.66H42.025C19.6265 222.66 1.40649 204.44 1.40649 182.042V73.6686C1.40649 51.2729 19.6293 33.05 42.025 33.05H213.691C236.086 33.05 254.306 51.2729 254.306 73.6686V182.042C254.306 204.44 236.086 222.66 213.691 222.66ZM42.025 44.9279C26.1766 44.9279 13.2844 57.8202 13.2844 73.6686V182.042C13.2844 197.89 26.1766 210.785 42.025 210.785H213.691C229.539 210.785 242.431 197.89 242.431 182.042V73.6686C242.431 57.8202 229.539 44.9279 213.691 44.9279H42.025Z" fill="#1D1D1B"/>
                              <path d="M32.44 74.2334C62.7993 103.817 93.1585 133.398 123.518 162.982C129.177 168.495 137.863 159.829 132.195 154.304C101.836 124.723 71.4766 95.1398 41.1173 65.5589C35.458 60.0429 26.7723 68.7117 32.44 74.2334Z" fill="#1D1D1B"/>
                              <path d="M132.195 162.982C162.554 133.401 192.914 103.817 223.273 74.2362C228.938 68.7145 220.258 60.0429 214.596 65.5589C184.236 95.1398 153.877 124.723 123.518 154.304C117.853 159.826 126.533 168.498 132.195 162.982Z" fill="#1D1D1B"/>
                              <path d="M222.938 183.922C202.737 164.763 182.533 145.607 162.332 126.449C156.603 121.017 147.911 129.678 153.655 135.126C173.856 154.285 194.06 173.44 214.261 192.599C219.991 198.031 228.682 189.368 222.938 183.922Z" fill="#1D1D1B"/>
                              <path d="M41.9997 192.596C62.2008 173.438 82.4047 154.282 102.606 135.123C108.347 129.677 99.6609 121.011 93.9285 126.446C73.7274 145.605 53.5235 164.76 33.3224 183.919C27.5816 189.365 36.2673 198.034 41.9997 192.596Z" fill="#1D1D1B"/>
                              </svg>
                              <span>Email</span>
                        </EmailShareButton>
                      </a>
                      <a href={void(0)}>
                      <FacebookShareButton
                        title={title}
                        url={pageLink}
                        quote={"Testing"}
                        hashtag={"#tinyaway"}
                        className="">
                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false"><path fill="#1877F2" d="M32 0v32H0V0z"></path><path d="M22.938 16H18.5v-3.001c0-1.266.62-2.499 2.607-2.499h2.018V6.562s-1.831-.312-3.582-.312c-3.654 0-6.043 2.215-6.043 6.225V16H9.436v4.625H13.5V32h5V20.625h3.727l.71-4.625z" fill="#FFF"></path></svg>
                        <span>Facebook</span>
                      </FacebookShareButton>
                        
                      </a>
                      <a href={void(0)}>
                      <WhatsappShareButton
                        title={title}
                        url={pageLink}
                        separator={`\n${cityName}, ${stateName}, ${countryName}\n`}>
                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false"><path d="m32 0v32h-32v-32z" fill="#25d366"></path><path d="m4 28 1.695-6.163a11.824 11.824 0 0 1 -1.595-5.946c.003-6.556 5.364-11.891 11.95-11.891a11.903 11.903 0 0 1 8.453 3.488 11.794 11.794 0 0 1 3.497 8.414c-.003 6.557-5.363 11.892-11.95 11.892-2 0-3.97-.5-5.715-1.448zm6.628-3.807c1.684.995 3.292 1.591 5.418 1.592 5.474 0 9.933-4.434 9.936-9.885.002-5.462-4.436-9.89-9.928-9.892-5.478 0-9.934 4.434-9.936 9.884 0 2.225.654 3.891 1.754 5.634l-1.002 3.648 3.76-.98h-.002zm11.364-5.518c-.074-.123-.272-.196-.57-.344-.296-.148-1.754-.863-2.027-.96-.271-.1-.469-.149-.667.147-.198.295-.767.96-.94 1.157s-.346.222-.643.074c-.296-.148-1.253-.46-2.386-1.466-.881-.783-1.477-1.75-1.65-2.045s-.018-.455.13-.602c.134-.133.296-.345.445-.518.15-.17.2-.294.3-.492.098-.197.05-.37-.025-.518-.075-.147-.668-1.6-.915-2.19-.241-.577-.486-.499-.668-.508l-.569-.01a1.09 1.09 0 0 0 -.79.37c-.272.296-1.039 1.01-1.039 2.463s1.064 2.857 1.211 3.054c.15.197 2.092 3.18 5.068 4.458.708.304 1.26.486 1.69.622.712.224 1.359.193 1.87.117.57-.084 1.755-.714 2.002-1.404.248-.69.248-1.28.173-1.405z" fill="#FFF"></path></svg>
                        <span>WhatsApp</span>
                      </WhatsappShareButton>
                      </a>
                      <a href={void(0)}>
                      <FacebookMessengerShareButton
                        title={title}
                        url={pageLink}
                        appId = "752989346684469" 
                        className="">
                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"  aria-hidden="true" role="presentation" focusable="false"><radialGradient id="a" cx="19.25%" cy="99.446619%" r="108.96%"><stop offset="0" stop-color="#09f"></stop><stop offset=".6098" stop-color="#a033ff"></stop><stop offset=".9348" stop-color="#ff5280"></stop><stop offset="1" stop-color="#ff7061"></stop></radialGradient><g fill="none" transform="translate(4 4)"><path d="m12 0c-6.759 0-12 4.95256076-12 11.6389677 0 3.4976898 1.434 6.5214217 3.768 8.6092365.195.1739846.315.4199627.321.6839393l.066 2.1358106c.021.6809396.723 1.1249002 1.347.8489247l2.382-1.0499069c.201-.089992.429-.1049907.642-.0479957 1.095.2999734 2.259.461959 3.474.461959 6.759 0 12-4.9525607 12-11.6389677 0-6.68640701-5.241-11.6419675-12-11.6419675z" fill="url(#a)"></path><path d="m4.794 15.0436658 3.525-5.59150411c.561-.89092099 1.761-1.10990157 2.604-.47995744l2.805 2.10281355c.258.1919829.612.1919829.867-.0029998l3.786-2.87374511c.504-.38396594 1.164.22198032.828.75893269l-3.528 5.58850432c-.561.890921-1.761 1.1099016-2.604.4799575l-2.805-2.1028135c-.258-.191983-.612-.191983-.867.0029997l-3.786 2.8737451c-.504.383966-1.164-.2189805-.825-.7559329z" fill="#fff"></path></g></svg>
                        <span>Messenger</span>
                      </FacebookMessengerShareButton>
                      </a>
                      <a href={void(0)}>
                      <TwitterShareButton
                        title={title}
                        url={pageLink}
                        hashtags={["tinyaway", "discovernature","stayincomfort","tinyhouses","bigtiny"]}
                      >
                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false"><path fill="#1DA1F2" d="M0 0H32V32H0z"></path><path d="M18.664 7.985a4.5 4.5 0 0 0-2.289 4.89c-3.5-.188-6.875-1.813-9.063-4.625a4.25 4.25 0 0 0 1.375 5.875c-.687 0-1.374-.125-2-.438.063 2.063 1.5 3.876 3.5 4.313-.624.188-1.312.188-2 .063.626 1.812 2.313 3.062 4.188 3.125-1.813 1.5-4.25 2.187-6.563 1.812a12.438 12.438 0 0 0 19.313-11.188c.875-.624 1.625-1.374 2.188-2.312-.75.375-1.625.625-2.5.75.937-.563 1.625-1.438 2-2.5-.875.5-1.813.875-2.813 1.063a4.5 4.5 0 0 0-5.336-.828z" fill="#FFF"></path></svg>
                        <span>Twitter</span>
                      </TwitterShareButton>
                      </a>
                      {/* <a href="">
                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false"><path d="M18.346 4.19l1.923.55-6.615 23.07-1.923-.55zm4.215 1.956l8.293 8.293.116.128a2 2 0 0 1-.116 2.7l-8.293 8.294-1.415-1.415 8.293-8.292-8.293-8.293zm-13.122 0l1.415 1.415-8.293 8.293 8.293 8.292-1.415 1.415-8.293-8.293a2 2 0 0 1-.116-2.701l.116-.128L9.44 6.146z"></path></svg>
                        <span>Embed</span>
                      </a> */}
                     </div>
                    </div>
                    {copied ?
                    <div className="_8c85wg">
                      <div className="t5jos5n f1i7e2pi dir dir-ltr">
                        <div role="group" className="c1dp68o4 dir dir-ltr">
                          <div className="cew8536 dir dir-ltr">
                            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-label="Success" role="img" focusable="false" ><path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z"></path></svg>
                          </div>
                          <div className="m19ms3ma dir dir-ltr">
                            <div tabIndex={0} className="m32ayvt dir dir-ltr">Link Copied</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    :""}
                  </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const copyText = () => {
    copy();
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };
  return (
    <div className={`nc-ListingStayDetailPage  ${className}`} data-nc-id="ListingStayDetailPage">
      <Helmet title={metatitle}></Helmet>
      <>
        
        {isdataLoaded  ?
          <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
            <div className="relative grid grid-cols-4 sm:grid-cols-1 gap-1 sm:gap-2 mt-15 mb-15 sm-grid-cols-1">
          <h2 className="text-2xl sm:text-2xl lg:text-2xl font-semibold">{title}</h2>
          <div className="flex justify-between items-center">
            <div>
              <span>
                <i className="las la-map-marker-alt"></i>
                <a href="#scroll_to_location" className="ml-1">{listingaddress}</a>
              </span>
            </div>
          <div className="flex justify-between items-center">
          {/* <Badge name="Tiny House" /> */}
            <LikeSaveBtns onChange={() => setIsOpenModalLikeShare(true)} />
            {/* BELOW SAVE BUTTON REMOVED AS PER INC 376 */}
            {/* <span  className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
            <span className="hidden sm:block ml-2.5">Save</span>
          </span> */}
          </div> 
          </div>
          </div>
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2 sm-full-1-img">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={PHOTOS[0]}
                prevImageHorizontal
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 3 ? "hidden sm:block" : ""
                }`}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-6 sm:aspect-w-6 sm:aspect-h-4"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item || ""}
                  prevImageHorizontal
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 1)}
                />
              </div>
            ))}
            <div className="absolute hidden md:flex md:items-center md:justify-center right-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10" onClick={() => handleOpenModal(0)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
          </header>
        :
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
        {renderskeletonLoader()}
        </header>
       }
        {isdataLoaded  ?
        <ModalPhotos
          imgs={PHOTOS}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
        />
        : ""}
      </>
      {isdataLoaded  ?
      <main className="container mt-11 flex ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {renderSectionProperDetails()}
          {renderSectionTinyStories()}
          {renderAmenities()}
          {renderSectionlistingCommonQuestions()}
          {renderSectionlistingTerms()}
          {/* {renderSection4()} */}
          {renderSectionCheckIndate()}
          {renderSectionlistingPrice()}
          {renderReviews()}
          {renderSection7()}
          {renderSection5()}
          {/* {renderSection8()} */}
          {renderMotalLoginSignUp()}
          {renderModalHostContactUs()}
          {renderModalLikeSave()}
        </div>
        {/* SIDEBAR */}
          <div className={`${isOpenModalMobileBookingForm} lg:block flex-grow phone-show-detailpage`}>
            <div className="close-icon-phone">
              <a href={void(0)} onClick={closeModalBookingForm}><svg xmlns="http://www.w3.org/2000/svg" height="512" viewBox="0 0 512 512" width="512"><title/><path d="M289.94,256l95-95A24,24,0,0,0,351,127l-95,95-95-95A24,24,0,0,0,127,161l95,95-95,95A24,24,0,1,0,161,385l95-95,95,95A24,24,0,0,0,385,351Z"/></svg>
              </a>
              </div>
            <div className="sticky top-24 payment-form-ms-size">{renderSidebar()}</div>
          </div>
      </main>
      : ""}
      {/* STICKY FOOTER MOBILE */}
      {!isPreviewMode &&  (
        <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
          <div className="container flex items-center justify-between">
            <span className="text-2xl font-semibold">
            <sup>{currency_code}</sup>{price}
              <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                /night
              </span>
            </span>
            <ButtonPrimary href={void(0)} onClick={openModalBookingForm}>Add to cart</ButtonPrimary>
          </div>
        </div>
      )}
      {/* OTHER SECTION */}
      {!isPreviewMode && (
        <div className="container py-24 lg:py-32">
          {/* SECTION 1 */}
          <div className="relative py-16">
            <BackgroundSection />
            {maplatSimilar!=""?
            <SectionGridSimilarListing lattitude={maplatSimilar} longitude={maplngSimilar} excludeListing={guestyListingId} />
            :''  
          }
            {/*<SectionSliderNewCategories
              heading="Similar Tiny Houses"
              subHeading="Explore houses based on 10 types of stays"
              categoryCardType="card5"
              itemPerRow={5}
              sliderStyle="style2"
      />*/}
          </div>
          {/* SECTION */}
          {/* <SectionSubscribe2 className="pt-24 lg:pt-32" />*/}
        </div>
      )}
       
    </div>
  );
};
export default ListingStayDetailPage;
